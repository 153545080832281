import React, { useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { Card, Badge, Tab, Nav, Spinner } from "react-bootstrap";
import { dashboardData } from "../../../store/dashboard/action";
import { alertsList } from "../../../store/alerts/action";
import { debitsList } from "../../../store/debits/action";

import pic2 from "./../../../images/profile/small/pic2.jpg";
import fileIcon from "./../../../images/Menu/fileIcon.png";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const tabData = [
  {
    name: "week",
    content: "12",
  },
  {
    name: "month",
    content: "25",
  },
];

const Menu = ({
  list,
  alertData,
  debitsData,
  loading,
  dashboardData,
  alertsList,
  debitsList,
}) => {
  // This is load more function
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboard, setDashboard] = useState({});
  const [alertsTimeline, setAlertsTimeline] = useState([]);
  const [debitsTimeline, setDebitsTimeline] = useState([]);
  const [refresh2, setRefresh2] = useState(false);

  useEffect(() => {
    dashboardData().catch((error) => {
      notifyError(error?.payload);
    });
    if (!alertData.length) {
      alertsList().catch((error) => {
        notifyError(error?.payload);
      });
    }
    if (!debitsData.length) {
      debitsList().catch((error) => {
        notifyError(error?.payload);
      });
    }
  }, []);

  const noOfStudentData = [
    {
      name: "week",
      content: [
        dashboard?.codeTypesInWeek || 0,
        dashboard?.conduitTypesInWeek || 0,
      ],
    },
    {
      name: "month",
      content: [
        dashboard?.codeTypesInMonth || 0,
        dashboard?.conduitTypesInMonth || 0,
      ],
    },
  ];

  const currentDate = moment();
  const oneWeekAgo = moment().subtract(7, 'days');
  const lastWeekData = (data) => {
    return data.filter((item) => {
      const createdAt = moment(item?.created_at);
      return createdAt.isBetween(oneWeekAgo, currentDate, null, '[]');
    });
  };

  useEffect(() => {
    if (list) {
      setDashboard(list);
    }
    if (alertData) {
      setAlertsTimeline(lastWeekData(alertData));
    }
    if (debitsData) {
     
      setDebitsTimeline(lastWeekData(debitsData));
    }
    setIsLoading(loading);
  }, [list, loading, alertData,debitsData]);

  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const LoadMore = () => {
    setRefresh(true);
    setTimeout(() => {
      setDebitsTimeline([
        ...debitsTimeline,
        debitsTimeline[Math.floor(Math.random() * Math.floor(debitsTimeline.length - 1))],
      ]);
      setRefresh(false);
    }, 1000);
  };

  const onLoad = () => {
    setRefresh2(true);
    setTimeout(() => {
      setDebitsTimeline([
        ...debitsTimeline,
        debitsTimeline[Math.floor(Math.random() * Math.floor(debitsTimeline.length - 1))],
      ]);
      setRefresh2(false);
    }, 1000);
  };

  return (
    <>
      <ToastContainer />
      <div id="menuSection">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-xs-12">
            <div className="widget-stat card bg-green-part">
              <div className="card-body  p-4">
                <div className="media">
                  <span className="me-3">
                    <i className="la la-users"></i>
                  </span>
                  {isLoading ? (
                    <div
                      className="text-center"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ borderRadius: "50%" }}
                      ></Spinner>
                      <span>Loading...</span>
                    </div>
                  ) : (
                    <div className="media-body text-white">
                      <h3 className="text-white">{list.monitors}</h3>
                      <p className="mb-1">Total Moniteurs</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-12">
            <div className="widget-stat card bg-purple-part">
              <div className="card-body  p-4">
                <div className="media">
                  <span className="me-3">
                    <i className="la la-users"></i>
                  </span>

                  {isLoading ? (
                    <div
                      className="text-center"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ borderRadius: "50%" }}
                      ></Spinner>
                      <span>Loading...</span>
                    </div>
                  ) : (
                    <div className="media-body text-white">
                      <h3 className="text-white">{list.passedStudentInWeek}</h3>
                      <p className="mb-1">Examens réussi cette semaine</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-12">
            <div className="widget-stat card bg-darkBlue-part">
              <div className="card-body  p-4">
                <div className="media">
                  <span className="me-3">
                    <i className="la la-users"></i>
                  </span>
                  {isLoading ? (
                    <div
                      className="text-center"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ borderRadius: "50%" }}
                      ></Spinner>
                      <span>Loading...</span>
                    </div>
                  ) : (
                    <div className="media-body text-white">
                      <h3 className="text-white">{list.newStudents}</h3>
                      <p className="mb-1">Nouveaux élèves</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-8 col-md-12">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h4 className="card-title">Alerts</h4>
              </div>
              <div className="card-body">
                <PerfectScrollbar
                  style={{ height: "500px" }}
                  id="DZ_W_TimeLine"
                  className="widget-timeline dz-scroll height370 ps ps--active-y"
                >
                  <ul className="timeline">
                    {isLoading ? (
                      <div
                        className="text-center"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <Spinner
                          animation="border"
                          role="status"
                          style={{ borderRadius: "50%" }}
                        ></Spinner>
                        <span>Loading...</span>
                      </div>
                    ) : ( alertsTimeline.length>0 ? alertsTimeline.map((val,index) => {
                        return (
                          <li key={index}>
                            <div className="timeline-badge primary"></div>
                            <Link
                              className="timeline-panel text-muted"
                              to="/dashboard"
                            >
                              <span>{val.studentAlerts.firstname}</span>
                              <h6 className="mb-0">{val.type}</h6>
                            </Link>
                          </li>
                        );
                      }) : <li>No Alerts Available</li>
                    )}
                  </ul>
                </PerfectScrollbar>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-12">
            <div className="card">
              <div className="card-header border-0 pb-0 d-block">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h3 className="heading">débits</h3>
                    <span>vivamus ac mi aigue</span>
                  </div>
                </div>
              </div>
              <div className="card-body pt-4 pb-0 height450 dz-scroll">
                {isLoading ? (
                  <div
                    className="text-center"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ borderRadius: "50%" }}
                    ></Spinner>
                    <span>Loading...</span>
                  </div>
                ) : (
                  <div className="contacts-list" id="RecentActivityContent">
                    { debitsTimeline.length > 0 ? debitsTimeline?.map((item, index) => (
                      <div
                        className="d-flex justify-content-between mb-3 mt-3 pb-3"
                        key={index}
                      >
                        <div className="d-flex align-items-center">
                          <img src={item?.studentDebit?.photo_id || pic2} alt="avatar" className="avatar" />
                          <div className="ms-3">
                            <h5 className="mb-1">
                              <Link
                                className="text-secondary"
                                to={"/app-profile"}
                              >
                                {`${item?.studentDebit?.firstname} ${item?.studentDebit?.lastname}`}
                              </Link>
                            </h5>
                            <span className="fs-14 text-muted">
                              Marketing Manager
                            </span>
                          </div>
                        </div>
                        <div className="icon-box icon-box-sm ">
                          <h5>{item?.money_left} € </h5>
                        </div>
                      </div>
                    )) : <p>No Debits Available</p>}
                  </div>
                )}
              </div>
              <div className="card-footer border-0 pt-0">
                <div className="text-center border-0 pt-3">
                  <Link
                    to={"#"}
                    onClick={() => LoadMore()}
                    className="btn btn-block btn-primary dz-load-more"
                    id="RecentActivity"
                    rel="ajax/message.html"
                  >
                    Voir Plus {refresh && <i className="fa fa-refresh"></i>}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body p-5">
                    <div className="students1 d-flex align-items-center justify-content-between flex-wrap">
                      <div className="benefitsBox">
                        <p>Nomre De</p>
                        <p>nouvelles prestations</p>
                        <h2>1256</h2>
                      </div>
                      <div>
                        <div className="d-inline-block position-relative file-icon mb-3">
                          <img
                            src={fileIcon}
                            alt="file icon"
                            className="fileIcon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Card>
                  <Card.Header>
                    <Card.Title>Nombre de nouvelles prestations</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Tab.Container
                      defaultActiveKey={tabData[0].name.toLowerCase()}
                    >
                      <Nav
                        as="ul"
                        className="nav-pills mb-4 justify-content-center"
                      >
                        {tabData.map((data, i) => (
                          <Nav.Item as="li" key={i}>
                            <Nav.Link eventKey={data.name.toLowerCase()}>
                              {i === 0 ? "Semaine" : i === 1 ? "Mois" : ""}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                      <Tab.Content>
                        {tabData.map((data, i) => (
                          <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                            <p className="Content text-center">
                              {data.content}
                            </p>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Tab.Container>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <Card>
              <Card.Header>
                <Card.Title>Nombre d'élèves envoyés aux examens</Card.Title>
              </Card.Header>
              <Card.Body>
                <Tab.Container
                  defaultActiveKey={noOfStudentData[0].name.toLowerCase()}
                >
                  {isLoading ? (
                    <div
                      className="text-center"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ borderRadius: "50%" }}
                      ></Spinner>
                      <span>Loading...</span>
                    </div>
                  ) : (
                    <>
                      <Nav
                        as="ul"
                        className="nav-pills mb-4 justify-content-center"
                      >
                        {noOfStudentData.map((data, i) => (
                          <Nav.Item as="li" key={i}>
                            <Nav.Link eventKey={data.name.toLowerCase()}>
                              {i === 0 ? "Semaine" : i === 1 ? "Mois" : ""}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                      <Tab.Content className="menuTab">
                        {noOfStudentData.map((data, i) => (
                          <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                            <div className="d-flex justify-content-around align-items-center">
                              <p className="Content">{data.content[0]}</p>
                              <div className="bootstrap-badge">
                                <Badge as="a" bg="info rounded-pill badge-xl">
                                  Code
                                </Badge>
                              </div>
                            </div>
                            <div className="d-flex justify-content-around align-items-center">
                              <p className="Content">{data.content[1]}</p>
                              <div className="bootstrap-badge">
                                <Badge
                                  as="p"
                                  bg="warning rounded-pill badge-xl"
                                >
                                  Conduit
                                </Badge>
                              </div>
                            </div>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </>
                  )}
                </Tab.Container>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.dashboard.list,
    loading: state.dashboard.loading,
    alertData: state.alerts.alerts,
    debitsData: state.debits.debits,
  };
};

const mapDispatchToProps = {
  dashboardData,
  alertsList,
  debitsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
