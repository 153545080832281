import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import FilteringTable from "./components/FilteringTable";
import { debitsList, sendEmailNotification } from "../../../store/debits/action";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatePicker from "react-datepicker";
import { themeStyle } from "../../../utils/style";
import Select from "react-select";

const Debits = (props) => {
  let { list, loading, debitsList,
    sendEmailNotification, success, message } = props;
  const [debitsData, setDebitsData] = useState([]);
  const[listLoading, setListLoading] = useState(true);

  useEffect(() => {
    debitsList()
      .then(() => {
        setListLoading(false);
      })
      .catch(error => {
        notifyError(error?.payload);
        setListLoading(false);
      })
  }, []);

  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifySuccess = (msg) => {
    console.log("Notified msg");
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    if (list) {
      let datas = list.filter((debit) => debit.debitAmount > 0);
      setDebitsData(datas)
    }
  }, [list]);


  const handleSendMail = async (data) => {
    try {
      await sendEmailNotification(data);
      if (!loading && success) {
        notifySuccess(message);
      }
    } catch (error) {
      notifyError(error?.payload || error);
    }

  }

  const COLUMNS = [
    {
      Header: "Nom",
      Footer: "Nom",
      accessor: "lastname",
    },
    {
      Header: "Prénom",
      Footer: "Prénom",
      accessor: "firstname",
    },
    {
      Header: "Depuis le",
      Footer: "Depuis le",
      accessor: "date_code",
    },
    {
      Header: "Montant dû",
      Footer: "Montant dû",
      accessor: "debitAmount"
    },
    {
      Header: "Valide",
      Footer: "Valide",
      Cell: ({ row }) => {
        return (
          <div className="d-flex">
            <button
              className="btn btn-transparent btn-xs sharp"
              disabled={!row.original.valid}
              // style={{color: 'white'}}
            >
              <i className="fa-solid fa-triangle-exclamation fa-2xl"></i>
            </button>
          </div>
        )
      }
    },
    {
      Header: "Réclamer",
      Footer: "Réclamer",
      Cell: ({ row }) => {
        return (
          <div className="d-flex">
            <button
              className="btn btn-info sharp me-1"
              onClick={(e) => {
                e.preventDefault();
                handleSendMail({ ...row.original })
              }}
              disabled={loading}>
              Email
            </button>
          </div>
        )
      },
    },
  ];

  const monthSelectionOption = [
    { value: "All", label: "All" },
    { value: "Select month", label: "Select month" },
  ]

  const [selectedMonthSelection, setSelectedMonthSelection] = useState(monthSelectionOption[0])
  const [selectedMonth, setSelectedMonth] = useState(new Date())

  return (
    <>
      <ToastContainer />
      <div>
        <Card className="mt-3">
          <Card.Body>
            <div className="d-flex gap-4 mb-4" >
              <div style={{ minWidth: "25%" }} >
                <Select
                  defaultValue={monthSelectionOption[0]}
                  value={selectedMonthSelection}
                  onChange={setSelectedMonthSelection}
                  options={monthSelectionOption}
                  styles={themeStyle}
                />
              </div>
              {
                selectedMonthSelection?.value === "Select month" &&
                <ReactDatePicker
                  selected={selectedMonth}
                  onChange={(date) => setSelectedMonth(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  className="form-control"
                />
              }
            </div>
            <FilteringTable datas={debitsData} headerColumn={COLUMNS} isLoading={listLoading} />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.debits.debits,
    loading: state.debits.loading,
    success: state.debits.success,
    message: state.debits.message
  };
};
const mapDispatchToProps = {
  debitsList,
  sendEmailNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(Debits);
