import { GET_EXAM_WISHLISTS, GET_EXAM_WISHLISTS_FAIL, GET_EXAM_WISHLISTS_SUCCESS, UPDATE_EXAM_WISHLISTS, UPDATE_EXAM_WISHLISTS_FAIL, UPDATE_EXAM_WISHLISTS_SUCCESS } from "./actionType"

const initialState = {
    examWishlist: [],
    update: {},
    success: false,
    loading: false,
    error: ''
}

export const examWishlistReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EXAM_WISHLISTS: return {
            ...state,
            examWishlist: [],
            loading: true,
            success: false,
            error: ''
        }
        case GET_EXAM_WISHLISTS_SUCCESS : return {
            ...state,
            examWishlist: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case GET_EXAM_WISHLISTS_FAIL : return {
            ...state,
            examWishlist: [],
            loading: false,
            success: false,
            error: action.payload
        }
        case UPDATE_EXAM_WISHLISTS : return {
            ...state,
            update: {},
            loading: true,
            success: false,
            error: ''
        }
        case UPDATE_EXAM_WISHLISTS_SUCCESS : return {
            ...state,
            update: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case UPDATE_EXAM_WISHLISTS_FAIL : return {
            ...state,
            update: {},
            loading: false,
            success: false,
            error: action.payload
        }
        default : return state
    }
}