import React, { useEffect, useState } from "react";
import FilteringTable from "./component/FilteringTable/FilteringTable";

import Select from "react-select";
import { Card, Button, Modal, Spinner } from "react-bootstrap";
import {
  addOffer,
  offersList,
  deleteOffer,
  updateOffer,
} from "../../../../store/offers/action";
import { connect, useSelector } from "react-redux";

import { ColumnFilter } from "./component/FilteringTable/ColumnFilter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { drivingSchoolsList } from "../../../../store/drivingSchools/action";

const initValue = {
  name: "",
  price: "",
  hour: "",
  drivingschool_id: null
};
const options1 = [
  { value: 0, label: "1 Month" },
  { value: 1, label: "3 Month" },
  { value: 2, label: "6 Month" },
  { value: 3, label: "1 Year" },
];

const Offers = (props) => {
  const { list, loading, offersList, addOffer, deleteOffer, updateOffer } =
    props;

  const [isLoading, setIsLoading] = useState(true);
  const [offersData, setOffersData] = useState([]);
  const [modalCentered, setModalCentered] = useState(false);
  const [offers, setOffers] = useState({ ...initValue });
  const [selectedTime, setSelectedTime] = useState(options1[0]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordIdToDelete, setRecordIdToDelete] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [drivingSchoolOptions, setDrivingSchoolOptions] = useState([]);
  const [selectedTypeOfOffer, setSelectedTypeOfOffer] = useState({});
  const [isActiveOffer, setIsActiveOffer] = useState(true);
  const [offerFee, setOfferFee] = useState("");

  const typeOfFormulaOptions = [
    { label: "Forfait", value: "Forfait" },
    { label: "Hors Forfait", value: "Hors Forfait" },
  ];

  // READ / FETCH LOGIC HERE ************************
  useEffect(() => {
    dataLoading();
    if (!props.driviningSchools) {
      props.drivingSchoolsList();
    }
  }, []);
  const dataLoading = async () => {
    try {
      return await offersList();
    } catch (error) {
      notifyError(error?.payload || error);
    }
  };

  useEffect(() => {
    if (props.drivingSchools?.length) {
      setDrivingSchoolOptions(
        props.drivingSchools.map((school) => ({
          label: school?.name,
          value: school?.id,
        }))
      );
    } else {
      drivingSchoolOptions?.length > 0 && setDrivingSchoolOptions([]);
    }
  }, [props.drivingSchools]);

  useEffect(() => {
    setIsLoading(loading);
    list?.length > 0
      ? setOffersData(
          list?.map((val) => {
            return {
              id: val.id,
              time_validity: val.time_validity,
              Name: val.name,
              Rate: val.price,
              "Number of hours": val.hour,
              type: val.type,
              fees: val.fees,
              status: val?.status,
              drivingschool_id: val.drivingschool_id
            };
          })
        )
      : setOffersData([]);
  }, [loading, list]);

  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifySuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // DELETION LOGIC HERE *************************
  const handleDelete = async (id) => {
    try {
      await deleteOffer(id);
      await offersList();
      notifySuccess("Deleted SuccessFully");
    } catch (error) {
      console.log(error, "<== while deleting student error");
      notifyError("Can't Deleted");
    } finally {
    }
  };
  const openDeleteModal = (recordId) => {
    setRecordIdToDelete(recordId);
    setShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setRecordIdToDelete(null);
  };
  const confirmDelete = async () => {
    if (recordIdToDelete) {
      await handleDelete(recordIdToDelete);
      closeDeleteModal();
    }
  };

  const openUpdateModal = (data) => {
    setSelectedTime(options1.find((val) => val.value === data.time_validity));
    setSelectedOffer(data.id);
    setOffers({
      name: data.Name,
      price: data.Rate,
      hour: data["Number of hours"],
      drivingschool_id: data.drivingschool_id
    });
    setSelectedTypeOfOffer({
      label: data.type,
      value: data.type,
    });
    setOfferFee(data.fees)
    setModalCentered(true);
    setIsUpdate(true);
    setIsActiveOffer(data.status);
  };

  const COLUMNS = [
    {
      Header: "Name",
      Footer: "Name",
      accessor: "Name",
      Filter: ColumnFilter,
    },
    {
      Header: "Rate",
      Footer: "Rate",
      accessor: "Rate",
      Filter: ColumnFilter,
    },
    {
      Header: "Number of hours",
      Footer: "Number of hours",
      accessor: "Number of hours",
      Filter: ColumnFilter,
    },
    {
      Header: "Action",
      Footer: "Action",
      accessor: "Action",
      disableFilters: true,
      Cell: ({ row }) => {
        const recordId = row.original.id;
        return (
          <>
            <div className="d-flex">
              <button
                className="btn btn-transparent btn-xs sharp me-1"
                // style={{ color: "white" }}
                onClick={() => openUpdateModal(row.original)}
              >
                <i className="fa-solid fa-pencil fa-2xl"></i>
              </button>
              <button
                className="btn btn-transparent btn-xs sharp me-1"
                // style={{ color: "white" }}
                onClick={() => openDeleteModal(recordId)}
              >
                <i className="fa-solid fa-trash fa-2xl"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ];

  // CREATION OFFERS *******************

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!offers.name) {
        notifyError("please provide valid name");
        return;
      }
      if (!offers.price) {
        notifyError("please provide valid price");
        return;
      }
      if (!offers.hour) {
        notifyError("please provide valid hours");
        return;
      }

      if(!offers.drivingschool_id) {
        notifyError("please select driving School");
        return;
      }
      if (selectedTypeOfOffer?.value === "Forfait" && !offerFee) {
        notifyError("please provide valid Fees for Forfait");
        return;
      }

      let payload = {
        type: selectedTypeOfOffer?.value,
        status: isActiveOffer,
        fees: offerFee || null,
      };
      let requestBody = {
        ...offers,
        time_validity: selectedTime?.value,
        ...payload,
      };
      console.log(requestBody, isUpdate);
      const headers = {
        "Content-Type": "application/json",
      };
      setIsLoading(true);
      if (isUpdate) {
        updateOffer(selectedOffer, requestBody, headers);
        notifySuccess("Updated SuccessFully");
      } else {
        addOffer(requestBody, headers);
        notifySuccess("Created SuccessFully");
      }
      await offersList();
      setOffers({ ...initValue });
      setSelectedTime(options1[0].value);
      setModalCentered(false);
      await dataLoading();
    } catch (error) {
      console.log(error);
      notifyError("Error While Creating/Updating");
    } finally {
      setIsLoading(false);
      setSelectedOffer(null);
      setIsUpdate(false);
      setOfferFee('');
      setSelectedTypeOfOffer({});
    }
  };

  const handleClose = () => {
    setOffers({
      ...initValue,
    });
    setModalCentered(false);
    setSelectedOffer(null);
    setSelectedTime(options1[0].value);
    setIsActiveOffer(true);
    setIsUpdate(false);
  };
  return (
    <>
      <ToastContainer />
      <div id="OffersPage">
        <Button
          className="me-2 mb-2"
          variant="primary"
          width={30}
          onClick={() => setModalCentered(true)}
        >
          Ajouter un offre
        </Button>

        <Card className="mt-3">
          {isLoading ? (
            <div className="text-center">
              <Spinner
                animation="border"
                role="status"
                style={{ borderRadius: "50%" }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <FilteringTable datas={offersData} headerColumn={COLUMNS} />
          )}
        </Card>

        <Modal
          className="fade bd-example-modal-lg "
          show={modalCentered}
          onHide={handleClose}
          size="md"
        >
          <Modal.Header>
            <Button
              variant=""
              className="btn-close"
              onClick={handleClose}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column">
              <div className="mb-3">
                <label className="col-form-label">Driving school</label>
                <div className="">
                  <Select
                    options={drivingSchoolOptions}
                    className="custom-react-select"
                    placeholder="select driving school"
                    isSearchable={false}
                    value={drivingSchoolOptions.find(
                      (option) => option.value === offers["drivingschool_id"]
                    )}
                    isDisabled={isUpdate}
                    onChange={(option) => {
                      setOffers({ ...offers, drivingschool_id: option.value });
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="col-form-label">Nom</label>
                <div className="">
                  <input
                    type="text"
                    className="form-control"
                    value={offers.name}
                    onChange={(e) => {
                      setOffers({ ...offers, name: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="col-form-label">Montant</label>
                <div className="">
                  <input
                    type="number"
                    className="form-control"
                    value={offers.price}
                    onChange={(e) => {
                      setOffers({ ...offers, price: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="col-form-label">nombre d'heures</label>
                <div className="">
                  <input
                    type="number"
                    className="form-control"
                    value={offers.hour}
                    onChange={(e) => {
                      setOffers({ ...offers, hour: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <Select
                  options={options1}
                  className="custom-react-select"
                  placeholder={"Select Time Validity"}
                  isSearchable={false}
                  value={selectedTime}
                  onChange={setSelectedTime}
                />
              </div>
              <div className="mb-3">
                <label className="col-form-label">type d'offre</label>
                <div className="">
                  <Select
                    options={typeOfFormulaOptions}
                    className="custom-react-select"
                    placeholder="sélectionner type d'offre"
                    isSearchable={false}
                    value={selectedTypeOfOffer}
                    onChange={setSelectedTypeOfOffer}
                  />
                </div>
              </div>
              {selectedTypeOfOffer.value === "Forfait" && (
                <div className="mb-4">
                  <label className="col-form-label">Fees</label>
                  <div className="">
                    <input
                      type="number"
                      className="form-control"
                      value={offerFee}
                      min={1}
                      max={offers.price}
                      onChange={(e) => {
                        if (parseInt(offers.price) > parseInt(e.target.value)) {
                          setOfferFee(e.target.value);
                        } else if (e.target.value === "") {
                          setOfferFee("");
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="mb-3">
                <div
                  className="form-check custom-checkbox checkbox-success check-lg"
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    paddingTop: "20px",
                    gap: "10px",
                  }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="enabled"
                    name="enabled"
                    checked={isActiveOffer}
                    onChange={(e) => {
                      setIsActiveOffer(e.target.checked);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="enabled"
                    style={{ margin: 0 }}
                  >
                    Actif
                  </label>
                </div>
              </div>
              <div className="me-auto ms-auto">
                {isLoading ? (
                  <div className="text-center">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ borderRadius: "50%" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <Button
                    type="submit"
                    className="text-center mt-5 "
                    variant="info"
                    onClick={handleSubmit}
                    width={20}
                  >
                    {isUpdate ? " Mettre à jour une offre" : "créer une offre"}
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
          <Modal.Header>
            <Modal.Title>Confirmation de la suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>Voulez-vous vraiment supprimer cet étudiant ?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Annuler
            </Button>
            <Button variant="danger" onClick={confirmDelete}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.offers.offers,
    loading: state.offers.loading,
    success: state.offers.success,
    drivingSchools: state.drivingSchool.drivingschools,
  };
};

const mapDispatchToProps = {
  offersList,
  addOffer,
  deleteOffer,
  updateOffer,
  drivingSchoolsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
