import { ROLES, ROLES_FAILED, ROLES_SUCCESS } from "./actionTypes";


const initialState = {
    roles: [],
    loading: false,
    success: false,
    error: ''
}

export const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ROLES: return {
            ...state,
            roles: [],
            loading: true,
            success: false,
            error: ''
        }
        case ROLES_SUCCESS: return {
            ...state,
            roles: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case ROLES_FAILED: return {
            ...state,
            roles: [],
            success: false,
            error: action.payload,
            loading: false
        }
        default:
            return state
    }
}