import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { receiptList } from '../../../store/receipts/action';
import { Button, Card, Dropdown } from "react-bootstrap";
import FilterTable from './component/table/FilteringTable'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDaysInMonth, getYearsArray } from '../../../utils/constants';
import moment from 'moment';

const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const ColumnFilter = ({ column }) => {

    const { filterValue, setFilter } = column
    return (
        <div>

            <input className="form-control input-search"
                value={filterValue || ''} onChange={e => setFilter(e.target.value)} />
        </div>
    )
}

const Receipts = ({ list, loading, receiptList }) => {
    const [receiptsData, setReceiptsData] = useState([]);
    const [monthOptions, setMonthOptions] = useState(Array.from({ length: 12 }, (_, index) => index + 1))
    const [selectedMonth, setSelectedMonth] = useState(monthOptions[0])
    const [yearOptions, setYearOptions] = useState(getYearsArray(2013))
    const [selectedYear, setSelectedYear] = useState(yearOptions[0])
    const [daysOptions, setDaysOptions] = useState(getDaysInMonth(selectedMonth, selectedYear))
    const [selectedDay, setSelectedDay] = useState(daysOptions[0])
    const [typeOptions, setTypeOptions] = useState(["day", "month", "year"])
    const [selectedType, setSelectedType] = useState(typeOptions[0])
    const [forfaitTypeOptions, setForfaitTypeOptions] = useState(["All", "Forfait", "Hors Forfait"])
    const [selectedForfaitType, setSelectedForfaitType] = useState(forfaitTypeOptions[0])

    useEffect(() => {
        setDaysOptions(getDaysInMonth(selectedMonth, selectedYear))
        setSelectedDay(getDaysInMonth(selectedMonth, selectedYear)[0])
    }, [selectedMonth, selectedYear])

    useEffect(() => {
        let startDate = moment(`${selectedDay}/${selectedMonth}/${selectedYear}`, 'DD/MM/YYYY').format('DD/MM/YYYY')
        let endDate = moment(`${selectedDay}/${selectedMonth}/${selectedYear}`, 'DD/MM/YYYY').add(1, 'day').format('DD/MM/YYYY')
        receiptList(startDate, endDate)
            .catch((error) => {
                notifyError(error?.payload);
            })
    }, []);

    useEffect(() => {
        setReceiptsData(list);
    }, [list])

    useEffect(() => {
        if(selectedForfaitType !== "All"){
            setReceiptsData(list?.filter(item => item.type === selectedForfaitType ))
        }else{
            setReceiptsData(list)
        }
    }, [selectedForfaitType])

    const handleApplyFilter = async () => {
        try {
            if (selectedType == "day") {
                let startDate = moment(`${selectedDay}/${selectedMonth}/${selectedYear}`, 'DD/MM/YYYY').format('DD/MM/YYYY')
                let endDate = moment(`${selectedDay}/${selectedMonth}/${selectedYear}`, 'DD/MM/YYYY').add(1, 'day').format('DD/MM/YYYY')
                receiptList(startDate, endDate)
            } else if (selectedType == "month") {
                let startDate = moment(`${selectedDay}/${selectedMonth}/${selectedYear}`, 'DD/MM/YYYY')
                let endDate = moment(`${1}/${selectedMonth}/${selectedYear}`, 'DD/MM/YYYY').add(1, "month")
                receiptList(startDate.format('DD/MM/YYYY'), endDate.format('DD/MM/YYYY'))
            } else if (selectedType == "year") {
                let startDate = moment(`${1}/${1}/${selectedYear}`, 'DD/MM/YYYY')
                let endDate = moment(`${1}/${1}/${selectedYear}`, 'DD/MM/YYYY').add(1, 'year')
                receiptList(startDate.format('DD/MM/YYYY'), endDate.format('DD/MM/YYYY'))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const columns = [
        {
            Header: 'Date',
            Footer: 'Date',
            accessor: 'date',
            Filter: ColumnFilter
        },
        {
            Header: 'Nom Prénom',
            Footer: 'Nom Prénom',
            accessor: 'fullname',
            Filter: ColumnFilter
        },
        {
            Header: 'Règlement',
            Footer: 'Règlement',
            accessor: 'rules',
            Filter: ColumnFilter
        },
        {
            Header: 'Montant',
            Footer: 'Montant',
            accessor: 'amount',
            Filter: ColumnFilter
        },
        {
            Header: 'Mode de payement',
            Footer: 'Mode de payement',
            accessor: 'mode',
            Filter: ColumnFilter
        },
        {
            Header: 'Numéro de chèque',
            Footer: 'Numéro de chèque',
            accessor: 'checknumber',
            Filter: ColumnFilter
        }
    ]
    return (
        <div>
            <ToastContainer />
            <h2>Recettes</h2>
            <div className='receipt-page'>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <Card>
                            <Card.Body>
                                <div className='drop-down-button-part d-flex justify-content-between'>
                                    <div className='drop-down-part'>
                                        <Dropdown onSelect={setSelectedType} >
                                            <Dropdown.Toggle variant="primary" className='btn-rounded'>
                                                {selectedType}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {
                                                    typeOptions?.map(item => (
                                                        <Dropdown.Item eventKey={item} >{item}</Dropdown.Item>
                                                    ))
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    {
                                        selectedType == 'day' &&
                                        <div className='drop-down-part'>
                                            <Dropdown onSelect={setSelectedDay} >
                                                <Dropdown.Toggle variant="primary" className='btn-rounded'>
                                                    {selectedDay}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {
                                                        daysOptions?.map(item => (
                                                            <Dropdown.Item eventKey={item} >{item}</Dropdown.Item>
                                                        ))
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    }
                                    {
                                        (selectedType == 'day' || selectedType == 'month') &&
                                        <div className='drop-down-part'>
                                            <Dropdown onSelect={setSelectedMonth} >
                                                <Dropdown.Toggle variant="primary" className='btn-rounded'>
                                                    {selectedMonth}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {
                                                        monthOptions?.map(item => (
                                                            <Dropdown.Item eventKey={item} >{item}</Dropdown.Item>
                                                        ))
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    }
                                    <div className='drop-down-part'>
                                        <Dropdown onSelect={setSelectedYear} >
                                            <Dropdown.Toggle className='btn-rounded'>
                                                {selectedYear}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {
                                                    yearOptions?.map(item => (
                                                        <Dropdown.Item eventKey={item} >{item}</Dropdown.Item>
                                                    ))
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className='drop-down-part' >
                                        <Button onClick={handleApplyFilter} >Apply</Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-12 col-md-6'>
                        <Card>
                            <Card.Body>
                                <div className='drop-down-button-part d-flex justify-content-end'>
                                    <div className='drop-down-part' style={{ display: 'flex', alignItems:"center", gap: "8px" }} >
                                        <div>Type</div>
                                        <Dropdown onSelect={setSelectedForfaitType} >
                                            <Dropdown.Toggle variant="primary" className='btn-rounded'>
                                                {selectedForfaitType}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {
                                                    forfaitTypeOptions?.map(item => (
                                                        <Dropdown.Item eventKey={item} >{item}</Dropdown.Item>
                                                    ))
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className='table-part mt-3'>
                    <Card>
                        <Card.Body>
                            <FilterTable headerColumn={columns} datas={receiptsData} isLoading={loading} />
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        list: state.receipts.receipts,
        loading: state.receipts.loading
    }
}
const mapDispatchToProps = {
    receiptList
}
export default connect(mapStateToProps, mapDispatchToProps)(Receipts)
