import React from 'react'
import { useTable, useFilters, useExpanded } from 'react-table';
import "../filtering.css";
import { Spinner } from 'react-bootstrap';

const HoursWorkedTable = ({
    datas,
    headerColumn,
    isLoading,
    studentFormulaTotalUsedHours,
    studentFormulaTotalHours,
}) => {
    const columns = headerColumn;
    const data = datas;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { expanded },
    } = useTable(
        {
            columns,
            data,
        },
        useExpanded,
        useFilters
    )

    return (
        <>
            <div className="table-responsive" style={{ marginTop: "3rem" }} >
                <h2>Heures effectuées</h2>
                <table {...getTableProps()} className="table display">
                    <thead>
                        {headerGroups?.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers?.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                        {column.canFilter ? column.render('Filter') : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="" >
                        {isLoading ? (
                            <tr>
                                <td colSpan={headerColumn?.length + 5}>
                                    <div className="text-center" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '15px' }}>
                                        <Spinner animation="border" role="status" style={{ borderRadius: '50%' }}>
                                        </Spinner>
                                        <span>Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        ) :
                            !rows?.length > 0 ? (
                                <tr>
                                    <td colSpan={headerColumn?.length + 1} style={{ textAlign: 'center' }}>No Records</td>
                                </tr>
                            ) :
                                (
                                    <>
                                        <tr style={{ background: '#4CAF50' }}>
                                            <td colSpan={headerColumn?.length - 1}>
                                                <div className="d-flex b-text-align-left text-white">Il reste {studentFormulaTotalHours - studentFormulaTotalUsedHours}heures à placer pour cet éléve</div>
                                            </td>
                                            <td>
                                                <div className="d-flex w-100 justify-content-center align-items-center text-white">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ background: '#006064' }}>
                                            <td colSpan={headerColumn?.length - 1}>
                                                <div className="d-flex b-text-align-left text-white">L'éléve a déjà effectué {studentFormulaTotalUsedHours} heures.</div>
                                            </td>
                                            <td>
                                                <div className="d-flex w-100 justify-content-center align-items-center text-white">
                                                </div>
                                            </td>
                                        </tr>
                                        {rows?.map((row) => {
                                            prepareRow(row);
                                            const totalAmount = row.original.amount
                                            const type = row.original.type;
                                            return (
                                                <React.Fragment key={row.id}>
                                                    <tr {...row.getRowProps()} style={type === "Forfait" ? { backgroundColor: "#ADD8E6" } : type === "Hors Forfait" ? { backgroundColor: "#FFB6C1" } : { backgroundColor: "#fff" }}>
                                                        {row.cells?.map((cell, index) => {
                                                            return (
                                                                <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
                                                            )
                                                        })}
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        })}

                                    </>
                                )}
                    </tbody>
                </table>
            </div >
        </>
    )
}

export default HoursWorkedTable
