export const DRIVINGSCHOOLS= 'DRIVINGSCHOOLS';
export const DRIVINGSCHOOLS_SUCCESS = 'DRIVINGSCHOOLS_SUCCESS';
export const DRIVINGSCHOOLS_FAILED = 'DRIVINGSCHOOLS_FAILED';
export const ADDDRIVINGSCHOOL = 'DRIVINGSCHOOL';
export const ADDDRIVINGSCHOOL_SUCCESS = 'ADDDRIVINGSCHOOL_SUCCESS';
export const ADDDRIVINGSCHOOL_FAILED = 'ADDDRIVINGSCHOOL_FAILED';
export const UPDATEDRIVINGSCHOOL = 'UPDATEDRIVINGSCHOOL';
export const UPDATEDRIVINGSCHOOL_SUCCESS = 'UPDATEDRIVINGSCHOOL_SUCCESS';
export const UPDATEDRIVINGSCHOOL_FAILED = 'UPDATEDRIVINGSCHOOL_FAILED'; 
export const DELETEDRIVINGSCHOOL = 'DELETEDRIVINGSCHOOL'
export const DELETEDRIVINGSCHOOL_SUCCESS = "DELETEDRIVINGSCHOOL_SUCCESS";
export const DELETEDRIVINGSCHOOL_FAILED = 'DELETEDRIVINGSCHOOL_FAILED';
export const SINGLEDRIVINGSCHOOL = 'SINGLEDRIVINGSCHOOL';
export const SINGLEDRIVINGSCHOOL_SUCCESS = 'SINGLEDRIVINGSCHOOL_SUCCESS';
export const SINGLEDRIVINGSCHOOL_FAILED = 'SINGLESTUDENT_FAILED';