import { CREATE_EXAM, CREATE_EXAM_FAIL, CREATE_EXAM_SUCCESS, DELETE_EXAM, DELETE_EXAM_FAIL, DELETE_EXAM_SUCCESS, GET_EXAMS, GET_EXAMS_FAIL, GET_EXAMS_SUCCESS, UPDATE_EXAM, UPDATE_EXAM_FAIL, UPDATE_EXAM_SUCCESS } from "./actionType"


let initialState = {
    examsList: [],
    loading: true,
    success: false,
    update: {},
    create: {},
    delete: {},
    error: ''
}

export const examsReducer = (state=initialState, action) => {
    switch(action.type){
        case GET_EXAMS : return {
            ...state,
            examsList: [],
            loading: true,
            success: false,
            error: ''
        }
        case GET_EXAMS_SUCCESS : return {
            ...state,
            examsList: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case GET_EXAMS_FAIL : return {
            ...state,
            examsList:[],
            loading: false,
            success: false,
            error: action.payload
        }
        case UPDATE_EXAM : return {
            ...state,
            update: {},
            loading: true,
            success: false,
            error: ''
        }
        case UPDATE_EXAM_SUCCESS : return {
            ...state,
            update: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case UPDATE_EXAM_FAIL : return {
            ...state,
            update: {},
            loading: false,
            success: false,
            error: action.payload
        }
        case CREATE_EXAM : return {
            ...state,
            create: {},
            loading: true,
            success: false,
            error: ''
        }
        case CREATE_EXAM_SUCCESS : return {
            ...state,
            create: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case CREATE_EXAM_FAIL : return {
            ...state,
            create: {},
            loading: false,
            success: false,
            error: action.payload
        }
        case DELETE_EXAM : return {
            ...state,
            delete: {},
            loading: true,
            success: false,
            error: ''
        }
        case DELETE_EXAM_SUCCESS : return {
            ...state,
            delete: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case DELETE_EXAM_FAIL : return {
            ...state,
            delete: {},
            loading: false,
            success: false,
            error: action.payload
        }
        default : return state
    }
}