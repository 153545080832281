import { VEHICLES, VEHICLES_FAILED, VEHICLES_SUCCESS, ADDVEHICLE, ADDVEHICLE_SUCCESS, ADDVEHICLE_FAILED, UPDATEVEHICLE, UPDATEVEHICLE_SUCCESS, UPDATEVEHICLE_FAILED, DELETEVEHICLE, DELETEVEHICLE_SUCCESS, DELETEVEHICLE_FAILED, SINGLEVEHICLE, SINGLEVEHICLE_SUCCESS, SINGLEVEHICLE_FAILED, VEHICLESTYPE, VEHICLESTYPE_SUCCESS, VEHICLESTYPE_FAILED } from "./actionType";

let initialState = {
    vehicles: [],
    singlevehicle: {},
    types:[],
    addVehicle: '',
    updateVehicle: '',
    loading: false,
    success: false,
    error: '',
}

export function VehicleReducer (state=initialState, action) {
    switch (action.type) {
        case VEHICLES: return {
            ...state,
            vehicles: [],
            loading: true,
            success: false,
            error: '',
        }
        case VEHICLES_SUCCESS: return {
            ...state,
            vehicles: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case VEHICLES_FAILED: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case SINGLEVEHICLE : return {
            ...state,
            loading: true,
            success: false,
            error: ''
        }
        case SINGLEVEHICLE_SUCCESS : return {
            ...state,
            singlevehicle: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case SINGLEVEHICLE_FAILED : return {
            ...state,
            singlevehicle: {},
            loading: false,
            success: false,
            error: action.payload
        }
        case ADDVEHICLE: return {
            ...state,
            addVehicle: '',
            loading: true,
            success: false,
            error: '',
        }
        case ADDVEHICLE_SUCCESS: return {
            ...state,
            addVehicle: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case ADDVEHICLE_FAILED: return {
            ...state,
            addVehicle: '',
            loading: false,
            success: false,
            error: action.payload
        }
        case UPDATEVEHICLE: return {
            ...state,
            updateVehicle: '',
            loading: true,
            success: false,
            error: '',
        }
        case UPDATEVEHICLE_SUCCESS: return {
            ...state,
            updateVehicle: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case UPDATEVEHICLE_FAILED: return {
            ...state,
            updateVehicle: '',
            loading: false,
            success: false,
            error: action.payload
        }
        case DELETEVEHICLE : return {
            ...state,
            loading: true,
            success: false,
            error:''
        }
        case DELETEVEHICLE_SUCCESS : return {
            ...state,
            loading: false,
            success: true,
            error: '',
        }
        case DELETEVEHICLE_FAILED : return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        
        case VEHICLESTYPE : return {
            ...state,
            types:[],
            loading:true,
            success:false,
            error:''
        }
        case VEHICLESTYPE_SUCCESS : return {
            ...state,
            types:action.payload,
            loading:false,
            success:true,
            error:''
        }
        case VEHICLESTYPE_FAILED : return {
            ...state,
            types: [],
            loading: false,
            success: false,
            error: action.payload
        }
        default:
            return state
    }
}