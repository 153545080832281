import React, { useState, useRef, useEffect } from "react";
import { BryntumCalendar, BryntumProjectModel } from "@bryntum/calendar-react";
import { Modal, Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import { themeStyle } from "../../../../../../utils/style";

import { calendarConfig } from "./CalenderConfig";
import { drivingSchoolsList } from "../../../../../../store/drivingSchools/action";
import { studentList } from "../../../../../../store/students/action";
import { usersList } from "../../../../../../store/users/action";
import {
  getPlanningList,
  deletePlanning,
  createPlanning,
  updatePlanning,
} from "../../../../../../store/planning/action";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { utcToLocal } from "../../../../../../utils/constants";
import { vehicleList } from "../../../../../../store/vehicles/action";
import { getStudentPlanning } from "../../../../../../store/students/action";

const initData = {
  id: "",
  type: "",
  comment: "",
  start_horary: null,
  end_horary: null,
  status: "",
  instructor_id: null,
};

function CalendarComponent({
  data,
  studentData,
  studentList,
  drivingList,
  drivingSchoolsList,
  usersData,
  usersList,
  vehicles,
  vehicleList,
  studentId,
  getStudentPlanning
}) {
  const calendar = useRef();
  const project = useRef();

  const drivingOptions = drivingList?.map((val) => {
    return { value: val.id, label: val.name };
  });

  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showCustomEditor, setShowCustomEditor] = useState(false);

  const [planning, setPlanning] = useState({ ...initData });
  const [selectDriving, setSelectDriving] = useState(drivingOptions?.[0] || {});
  const [selectStudent, setSelectStudent] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [studOption, setStudOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [remainingHours, setRemainingHours] = useState(0);
  const [allowCreateUpdate, setAllowCreateUpdate] = useState(false);
  const [selectedMonitor, setSelectedMoniter] = useState({})
  const [selectedVehicle, setSelectedVehicle] = useState({})
  const [vehicleOption, setVehicleOption] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true);
    studentList().catch((error) => {
      notifyError(error?.pauseOnHover);
    });
    drivingSchoolsList().catch((error) => {
      notifyError(error?.payload);
    });
    usersList().catch((error) => {
      notifyError(error?.payload);
    });
    vehicleList().catch((error) => {
      notifyError(error?.payload);
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    let opt = studentData
      ?.filter((val) => val.drivingschool_id)
      ?.map((val) => {
        return {
          value: { id: val.id, dId: val.drivingschool_id },
          label: `${val.firstname} ${val.lastname}`,
        };
      });
    setStudOptions(opt);
  }, [studentData]);

  useEffect(() => {
    if (vehicles?.length && selectDriving?.value && selectedMonitor?.value) {
      setVehicleOption(vehicles?.filter((vehicle) => ((vehicle?.drivingschool_id === selectDriving?.value) && (vehicle?.instructor_id === selectedMonitor?.value)))?.map((vehicle) => ({ label: vehicle?.name, value: vehicle?.id })))
    } else {
      setVehicleOption([])
    }
  }, [vehicles, selectDriving, selectedMonitor])

  useEffect(() => {
    if(studentId){
      setLoading(true)
      getStudentPlanning(studentId).catch((error) => {
        notifyError(error?.payload);
      }).finally(() => {
        setLoading(false)
      });
    }
  }, [studentId]);

  const countHoursFromPayments = (totalAmountPaid, totalHours, basePrice) => {
    let hours = (totalAmountPaid * totalHours) / basePrice
    hours = Math.floor(hours)
    return hours
  }

  useEffect(() => {
    if (selectStudent?.value && selectStudent?.value?.id) {
      let student = studentData.find(
        (student) => student.id === selectStudent?.value?.id
      );
      if (student) {
        let totalHours = 0;
        student.studentFormula?.forEach((formula) => {
          let validHours = 0;
          let totalAmountToBePaid = (formula?.quantity * (formula?.formulaId?.price))
          let totalAmountPaid = 0
          formula?.studentFormulaPayment?.map((item) => totalAmountPaid += item?.amount)

          if (totalAmountPaid === totalAmountToBePaid) {
            validHours += (formula?.quantity * (formula?.formulaId?.hour))
          } else if (totalAmountPaid < totalAmountToBePaid) {
            if (formula?.formulaId?.type === "Forfait") {
              let basePrice = totalAmountPaid - formula?.formulaId?.fees
              if (basePrice > 0) {
                validHours += countHoursFromPayments(basePrice, formula?.formulaId?.hour, formula?.formulaId?.price)
              }
            } else {
              validHours += countHoursFromPayments(totalAmountPaid, formula?.formulaId?.hour, formula?.formulaId?.price)
            }
          }

          totalHours += validHours;
          console.log(totalHours, "<==== Total hours");
        });
        let plannings = data.filter((item) => item.student_id === student.id);
        let totalUsedHours = plannings.reduce((total, current) => {
          let hours = moment(current.end_horary).diff(
            moment(current.start_horary),
            "hours"
          );
          return total + hours;
        }, 0);
        return setRemainingHours(totalHours - totalUsedHours);
      }
    } else {
      setRemainingHours(0);
    }
  }, [selectStudent, planning]);
  useEffect(() => {
    let start;
    if (moment.isMoment(planning.start_horary)) {
      start = planning.start_horary;
    } else {
      start = moment(planning.start_horary);
    }
    let end;
    if (moment.isMoment(planning.end_horary)) {
      end = planning.end_horary;
    } else {
      end = moment(planning.end_horary);
    }
    let currentHours = end.diff(start, "minutes");
    if (isUpdate) {
      let plan = data.find((item) => item.id === planning.id);
      let diffinMin = moment(plan.end_horary).diff(
        moment(plan.start_horary),
        "minutes"
      );
      currentHours -= diffinMin;
    }
    setAllowCreateUpdate(remainingHours * 60 >= currentHours);
  }, [selectStudent, planning, remainingHours]);
  useEffect(() => {
    if (!loading) {
      const res = usersData.reduce((result, val) => {
        if (val?.userGroup === "Moniteurs" && val?.userDrivingschool) {
          result.push({
            id: val.id,
            name: val.firstname || val.lastname || val.email,
          });
        }
        return result;
      }, []);
      setResources(res);

      // Settting Events
      setEvents(
        data.map((val) => {
          return {
            id: val.id,
            startDate: utcToLocal(val.start_horary),
            endDate: utcToLocal(val.end_horary),
            name: val.comment,
            resourceId: val.instructor_id,
            student: val.student_id,
            driving: val.drivingschool_id,
            type: val.type,
            status: val.status,
            vehicle: val.vehicle_id,
            eventColor: val?.studentGenerals?.licenceStudents?.name === "Permis Boite Manuelle" ? "red" : val?.studentGenerals?.licenceStudents?.name === "Permis Boite Auto" ? "blue" : "green"
          };
        })
      );
    }
  }, [loading, data, usersData]);

  
  const statusOption = [
    { value: 1, label: "pending" },
    { value: 2, label: "present" },
    { value: 3, label: "absent" },
  ];

  const handlePopupClose = async () => {
    setShowCustomEditor(false);
    setPlanning({ ...initData });
    setSelectDriving([]);
    setSelectStudent([]);
    setSelectStatus([]);
    setIsUpdate(false);
    setSelectedMoniter({})
    setSelectedVehicle({})
    setEvents(events?.filter(event => Number.isInteger(event?.id)))
  };
  // Notify messages
  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifySuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  calendarConfig.features = {
    ...calendarConfig.features,

    drag: {
      draggable: false,
      resizable: false,
      disabled: true
    },
  };

  calendarConfig.listeners = {
    eventClick: ({ eventRecord }) => {
      setIsUpdate(true);
      setPlanning({
        id: eventRecord.data.id,
        start_horary: eventRecord.data.startDate,
        end_horary: eventRecord.data.endDate,
        type: eventRecord.data.type,
        comment: eventRecord.data.name,
      });
      setSelectStatus(
        statusOption.find((val) => val.label === eventRecord.data.status)
      );
      console.log(
        studOption.filter(
          (val) => val.value.id === eventRecord.data.student
        )[0],
        eventRecord.data,
        " <=== I am stuaent"
      );
      setSelectStudent(
        studOption.filter(
          (val) => val.value.id === eventRecord.data.student
        )[0] || null
      );
      let selectedMonitor = resources.filter(
        (val) => val.id === eventRecord.data.resourceId
      )[0]
      selectedMonitor = { label: selectedMonitor?.name, value: selectedMonitor?.id }
      setSelectedMoniter(selectedMonitor);
      let drivingSchool = drivingOptions.filter(
        (val) => val.value === eventRecord.data.driving
      )[0]
      setSelectDriving(
        drivingSchool
      );
      setSelectedVehicle(vehicles?.filter((vehicle) => ((vehicle?.drivingschool_id === drivingSchool?.value) && (vehicle?.instructor_id === selectedMonitor?.value)))?.map((vehicle) => ({ label: vehicle?.name, value: vehicle?.id }))?.filter(
        (val) => val.value === eventRecord.data.vehicle
      )[0] || null);
      setShowCustomEditor(true); // Show the custom editor
    },
  };

  const handleChange = (e, fieldName) => {
    const value = e.target.value;
    setPlanning((preVal) => ({
      ...preVal,
      [fieldName]: value,
    }));
  };

  const handleDrivingSchoolChange = (selectedOption) => {
    setSelectDriving(selectedOption);
    const filteredStudOptions = studentData?.filter(
      (val) => val.drivingschool_id === selectedOption.value
    );
    const updatedStudOptions = filteredStudOptions.map((val) => ({
      value: { id: val.id, dId: val.drivingschool_id },
      label: `${val.firstname} ${val.lastname}`,
    }));
    setSelectStudent(updatedStudOptions[0]);
    setStudOptions(updatedStudOptions);
  };

  const handleCreateEvent = async ({ eventRecord, view }) => {
    setPlanning({
      id: eventRecord.data.id,
      start_horary: eventRecord.data.startDate,
      end_horary: eventRecord.data.endDate,
      type: eventRecord.data.type,
      comment: eventRecord.data.name,
      instructor_id: view?.initialConfig?.resourceId,
    });
    setEvents([
      ...events,
      {
        id: eventRecord.data.id,
        start_horary: moment(eventRecord.data.startDate).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        end_horary: moment(eventRecord.data.endDate).format("YYYY-MM-DD HH:mm:ss"),
        type: eventRecord.data.type,
        comment: eventRecord.data.name,
        instructor_id: view?.initialConfig?.resourceId,
      }
    ])
    setShowCustomEditor(true);
    let selectedMonitor = resources.filter(
      (val) => val.id === view?.initialConfig?.resourceId
    )[0]
    selectedMonitor = { label: selectedMonitor?.name, value: selectedMonitor?.id }
    setSelectedMoniter(selectedMonitor);
  };

  if (loading || isLoading) {
    return (
      <div
        className="text-center"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Spinner
          animation="border"
          role="status"
          style={{ borderRadius: "50%" }}
        ></Spinner>
        <span>Loading...</span>
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <BryntumProjectModel
        ref={project}
        events={events}
        resources={resources}
      />
      <BryntumCalendar
        ref={calendar}
        project={project}
        {...calendarConfig}
        onDragCreateEnd={handleCreateEvent}
      />

      {/* POPUP MODAL */}
      <Modal show={showCustomEditor} onHide={handlePopupClose}>
        <Modal.Body>
          <div className="basic-form">
            <form>
              <div className="row">
                <div className="form-group mb-3 col-md-12">
                  <label>Driving Schools</label>
                  <Select
                    value={selectDriving}
                    onChange={handleDrivingSchoolChange}
                    options={drivingOptions}
                    styles={themeStyle}
                    isDisabled={true}
                  />
                </div>
                {
                  isUpdate &&
                  <div className="form-group mb-3 col-md-12">
                    <label>Monitor</label>
                    <Select
                      // defaultValue={studOption[0]}
                      value={selectedMonitor}
                      onChange={setSelectedMoniter}
                      options={resources?.map(item => ({ label: item?.name, value: item?.id }))}
                      styles={themeStyle}
                    isDisabled={true}

                    />
                  </div>
                }
                <div className="form-group mb-3 col-md-12">
                  <label>Véhicule</label>
                  <Select
                    value={selectedVehicle}
                    onChange={setSelectedVehicle}
                    options={vehicleOption}
                    styles={themeStyle}
                    isDisabled={true}

                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Elèves</label>
                  <Select
                    // defaultValue={studOption[0]}
                    value={selectStudent}
                    onChange={setSelectStudent}
                    options={studOption}
                    styles={themeStyle}
                    isDisabled={true}

                  />
                </div>
                <p>Remaining Hours: {remainingHours}</p>
                {!allowCreateUpdate && (
                  <p className="text-danger">
                    You can not set hours more than balance
                  </p>
                )}
                <div className="form-group mb-3 col-md-12">
                  <label>Start Time</label>
                  {/* <input
                  type={"datetime-local"}
                  className="form-control"
                  value={planning.start_horary}
                  onChange={(e) => handleChange(e, "start_horary")}
                /> */}
                  <ReactDatePicker
                    showTimeSelect
                    timeIntervals={15}
                    id="date-time-picker"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    selected={
                      planning?.start_horary
                        ? moment(planning.start_horary).toDate()
                        : moment().toDate()
                    }
                    onChange={(e) => {
                      handleChange(
                        { target: { value: moment(e).toDate() } },
                        "start_horary"
                      );
                    }}
                    className="form-control"
                    disabled={true}
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>End Time</label>
                  {/* <input
                  type={"datetime-local"}
                  className="form-control"
                  value={planning.end_horary}
                  onChange={(e) => handleChange(e, "end_horary")}
                /> */}
                  <ReactDatePicker
                    showTimeSelect
                    timeIntervals={15}
                    id="date-time-picker"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    selected={
                      planning?.end_horary
                        ? moment(planning.end_horary).toDate()
                        : moment().toDate()
                    }
                    onChange={(e) => {
                      handleChange(
                        { target: { value: moment(e).toDate() } },
                        "end_horary"
                      );
                    }}
                    className="form-control"
                    disabled={true}
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Status</label>
                  <Select
                    defaultValue={statusOption[0]}
                    value={selectStatus}
                    onChange={setSelectStatus}
                    options={statusOption}
                    styles={themeStyle}
                    isDisabled={true}

                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Commnetaire</label>
                  <textarea
                    type="text"
                    name="comments"
                    className="form-control"
                    rows="3"
                    value={planning.comment}
                    onChange={(e) => handleChange(e, "comment")}
                    required
                    autoComplete="off"
                    style={{ minHeight: "120px" }}
                    disabled={true}
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.student.studentPlanning,
    loading: state?.planning.loading,
    addPlanning: state.planning.addPlanning,
    update: state.planning.update,
    drivingList: state.drivingSchool.drivingschools,
    studentData: state.student.students,
    usersData: state.users.users,
    vehicles: state.vehicle.vehicles
  };
};

const mapDispatchToProps = {
  drivingSchoolsList,
  studentList,
  usersList,
  vehicleList,
  getStudentPlanning
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComponent);
