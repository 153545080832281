import { ALERTS, ALERTS_FAILED, ALERTS_SUCCESS } from "./actionType";

let initialState={
    alerts:[],
    loading:false,
    success:false,
    error:''
}
export const AlertReducer = (state=initialState,action)=>{
    switch (action.type) {
        case ALERTS: return {
            ...state,
            alerts:[],
            loading:true,
            success:false,
            error:''
        }
        case ALERTS_SUCCESS:return {
            ...state,
            alerts:action.payload,
            success:true,
            loading:false,
            error:''
        }
        case ALERTS_FAILED : return {
            ...state,
            error:action.payload,
            loading:false,
            success:false
        }
        default:
            return state;
    }
}