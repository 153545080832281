import React from "react";

const ReportTemplate = ({ downloadData }) => {

  const defaultStyle = {
    color: "black",
    fontSize: "5px",
    margin: 0,
    lineHeight: 1,
    wordSpacing: "normal",
  };
  const tableHead = {
    margin:"15px 0",
    padding: "5px 0",
    backgroundColor: "gray",
    
  };
  return (
    <>
      <div>
        <p
          style={{
            ...defaultStyle,
            whiteSpace: "nowrap",
            margin: "50px 0 0 5px",
          }}
        >
          {downloadData.drivingSchoolStudents.name}
        </p>
        <p
          style={{
            ...defaultStyle,
            marginLeft: "120px",
            whiteSpace: "nowrap",
            fontWeight: "bold",
            fontSize: '5px'
          }}
        >
          Client :
        </p>
        <p
          style={{ ...defaultStyle, marginLeft: "120px", whiteSpace: "nowrap" , marginTop: '2px',fontSize: '4px'}}
        >
          Nom : {downloadData?.firstname + " " + downloadData?.lastname}
        </p>
        <p
          style={{ ...defaultStyle, marginLeft: "120px", whiteSpace: "nowrap", marginTop: '2px',fontSize: '4px' }}
        >
          Téléphone : {downloadData?.mobile}
        </p>
        <p
          style={{ ...defaultStyle, marginLeft: "120px", whiteSpace: "nowrap", marginTop: '2px',fontSize: '4px' }}
        >
          Email : {downloadData?.email}
        </p>
      </div>

      <table cellPadding={10} style={{ width: "200px", margin: "20px 5px" }}>
        <thead>
          <tr>
            {/* <th
              style={{
                ...defaultStyle,
                ...tableHead,
              }}
            >
              Référence
            </th>

            <th
              style={{
                ...defaultStyle,
                ...tableHead,
              }}
            >
              Numéro de facture
            </th> */}

            <th
              style={{
                ...defaultStyle,
                ...tableHead,
                fontSize: '4px',
                paddingLeft: '2px'
              }}
            >
              Date
            </th>

            <th
              style={{
                ...defaultStyle,
                ...tableHead,
                fontSize: '4px'
              }}
            >
              Mode
            </th>

            <th
              style={{
                ...defaultStyle,
                ...tableHead,
                fontSize: '4px'
              }}
            >
              Désignation
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* <td style={{ ...defaultStyle }}>{"29393AAX10672"}</td>
            <td style={{ ...defaultStyle }}>{"29393AA10672"}</td> */}
            <td style={{ ...defaultStyle,fontSize: '4px',paddingLeft: '2px' }}>{downloadData.reciptData.date}</td>
            <td style={{ ...defaultStyle,fontSize: '4px' }}>{downloadData.reciptData.mode}</td>
            <td style={{ ...defaultStyle,fontSize: '4px' }}>{downloadData.reciptData.name}</td>
          </tr>
        </tbody>

      </table>

      <table cellPadding={10} style={{ width: "200px", margin: "20px 5px" }}>

      <thead>
          <tr>
            {/* <th
              style={{
                ...defaultStyle,
                ...tableHead,
              }}
            >
              HT
            </th>

            <th
              style={{
                ...defaultStyle,
                ...tableHead,
              }}
            >
              TVA (20%)
            </th> */}

            <th
              style={{
                ...defaultStyle,
                ...tableHead,
                fontSize: '4px',
                paddingLeft: '2px'
              }}
            >
              Type
            </th>

            <th
              style={{
                ...defaultStyle,
                ...tableHead,
                fontSize: '4px'
              }}
            >
              NumberBankCheck
            </th>

            <th
              style={{
                ...defaultStyle,
                ...tableHead,
                fontSize: '4px'
              }}
            >
              Price
            </th>
          </tr>
        </thead>
        <tbody>
        <tr>
            {/* <td style={{ ...defaultStyle }}>{333.33}</td>
            <td style={{ ...defaultStyle }}>{"66.67 €"}</td> */}
            <td style={{ ...defaultStyle,fontSize: '4px',paddingLeft: '2px' }}>{downloadData.reciptData.type}</td>
            <td style={{ ...defaultStyle,fontSize: '4px' }}>{downloadData.reciptData.bankCheck}</td>
            <td style={{ ...defaultStyle,fontSize: '4px' }}>{downloadData.reciptData.tarif}</td>
          </tr>
        </tbody>
      </table>

      <p style={{ ...defaultStyle, marginLeft: "120px", whiteSpace: "nowrap",fontSize: '5px' }}>
        Montant Total : {downloadData?.reciptData?.tarif}
      </p>

      <div>
      <p
        style={{
          ...defaultStyle,
          margin: "20px 5px 0 5px",
          width: "200px",
          fontWeight: "bold",
          fontSize: '5px'
        }}
      >
        Facture acquittée ce jour
      </p>
      <p style={{ ...defaultStyle, margin: "5px", width: "200px",fontSize: '4px' }}>
        Le montant anticipé des factures ne donne lieu à aucun escompte. Toute
        somme demeurée impayée sera majorée d'intérêt de retard au taux pratiqué
        par la Banque Centrale Européenne pour sa dernière opération de
        refinancement. Majorée de 10% indemnité forfaitaire pour frais de
        recouvrement en cas de retard de paiement : 40€
      </p>
      </div>
      
    </>
  );
};

export default ReportTemplate;
