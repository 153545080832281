import { api } from "../../utils/constants"
import { vehicle,typesVehicle } from "../../utils/urls";
import { ADDVEHICLE, ADDVEHICLE_FAILED, ADDVEHICLE_SUCCESS, DELETEVEHICLE, DELETEVEHICLE_FAILED, DELETEVEHICLE_SUCCESS, SINGLEVEHICLE, SINGLEVEHICLE_FAILED, SINGLEVEHICLE_SUCCESS, VEHICLES, VEHICLES_FAILED, VEHICLES_SUCCESS, UPDATEVEHICLE, UPDATEVEHICLE_FAILED, UPDATEVEHICLE_SUCCESS, VEHICLESTYPE, VEHICLESTYPE_SUCCESS } from "./actionType"



export const vehicleList = () => async dispatch => {
    dispatch({
        type: VEHICLES,
    })
    try {
        let response = await api(vehicle(), 'get', {})
        dispatch({
            type: VEHICLES_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("fetching students error is ===>", error);
        return Promise.reject(dispatch({
            type: VEHICLES_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}
export const singleVehicle = (id) => async dispatch => {
    dispatch({
        type: SINGLEVEHICLE,
        
    })
    try {
        let response = await api(vehicle(id), 'get', {})
        dispatch({
            type: SINGLEVEHICLE_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch(error) {
        return Promise.reject(dispatch({
            type: SINGLEVEHICLE_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const addVehicle = (data, headers) => async dispatch => {
    dispatch({
        type: ADDVEHICLE,
    })
    try {
        let response = await api(vehicle(), 'post', data, headers);
        dispatch({
            type: ADDVEHICLE_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("adding student error is ===>", error);
        return Promise.reject(dispatch({
            type: ADDVEHICLE_FAILED,
            payload: error?.response?.data?.message || error?.message
        })      )  
    }
}

export const updateVehicle = (id, data, headers) => async dispatch => {
    dispatch({
        type: UPDATEVEHICLE,
    })
    try {
        let response = await api(vehicle(id), 'put', data, headers);
        dispatch({
            type: UPDATEVEHICLE_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("updating student error is ==>", error);
        return Promise.reject(dispatch({
            type: UPDATEVEHICLE_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))   
    }
}

export const deleteVehicle = (id) => async dispatch => {
    dispatch({
        type: DELETEVEHICLE,
    })
    try {
        let response = await api(vehicle(id), 'delete', {});
        dispatch({
            type: DELETEVEHICLE_SUCCESS,
            payload: response?.data,
        })
        return response
    } catch (error) {
        console.log("deleting student error is ===>", error);
        return Promise.reject(dispatch({
            type: DELETEVEHICLE_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const vehicleTypes=()=>async dispatch=>{
    dispatch({type: VEHICLESTYPE })
    try{
        let response=await api(typesVehicle(),'get',{});
        dispatch({
            type:VEHICLESTYPE_SUCCESS,
            payload:response?.data?.data
        })
        return response
    }catch(error){
        console.log("Error While fetching Type of vehicles",error);
        return Promise.reject(dispatch({
            type:VEHICLES_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}