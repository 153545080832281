import React, { useEffect, useState } from "react";
import GraphPage1 from "./GraphPage1";
import GraphPage2 from "./GraphPage2";
import { connect } from "react-redux";
import { getInstructorAbsentHours } from "../../../../../store/statistics/action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Nav, Spinner, Tab } from "react-bootstrap";
import { usersList } from "../../../../../store/users/action";
import Select from "react-select";
import { themeStyle } from "../../../../../utils/style";

const Tab8 = ({ instructorAbsentHours, getInstructorAbsentHours, loading, usersData, usersList }) => {
  const [montlyTotalHours, setMonthlyTotalHours] = useState([]);
  const [yearlyTotalHours, setYearlyTotalHours] = useState([]);
  const [resources, setResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState({})

  useEffect(() => {
    if (usersData?.length) {
      const res = usersData.reduce((result, val) => {
        if (val?.userGroup === "Moniteurs" && val?.userDrivingschool) {
          result.push({
            value: val.id,
            label: val.firstname || val.lastname || val.email,
          });
        }
        return result;
      }, []);
      setResources(res);
    }
  }, [usersData]);

  useEffect(() => {
    if(resources?.length && !selectedResource?.value){
      setSelectedResource(resources[0])
    }
  }, [resources])

  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    usersList().catch((error) => {
      notifyError(error?.payload);
    });
  }, []);

  useEffect(() => {
    if(selectedResource?.value){
      getInstructorAbsentHours(selectedResource?.value).catch((error) => {
        notifyError(`While Fetching Instructor Hours Error is: => ${error}`);
      });
    }
  }, [selectedResource])

  useEffect(() => {
    if (instructorAbsentHours.monthly && instructorAbsentHours.yearly && !loading) {
      var monthData = [];
      var yearData = [];

      const monthNames = {
        1: "Janvier",
        2: "Février",
        3: "Mars",
        4: "Avril",
        5: "Mai",
        6: "Juin",
        7: "Juillet",
        8: "Août",
        9: "Septembre",
        10: "Octobre",
        11: "Novembre",
        12: "Décembre",
      };
      for (let i = 1; i <= 12; i++) {
        const monthName = monthNames[i];
        monthData.push({
          month: monthName,
          total_hours: 0,
          monthIndex: i,
        });
      }
      monthData.forEach((item) => {
        instructorAbsentHours?.monthly?.forEach((month) => {
          if (month.month === item.monthIndex) {
            item.total_hours = month?.total_hours;
          }
        });
      });

      instructorAbsentHours?.yearly?.forEach((item) => {
        console.log(item, "debug")
        yearData.push({
          year: item.year,
          total_hours: item?.total_hours,
        });
      });
    }
    setMonthlyTotalHours(monthData);
    setYearlyTotalHours(yearData);
  }, [instructorAbsentHours, loading]);

  const tabData = [
    {
      name: "month",
      content: <GraphPage1 data={montlyTotalHours} />,
    },
    {
      name: "year",
      content: <GraphPage2 data={yearlyTotalHours} />,
    },
  ];


  return (
    <>
      <ToastContainer />
      {console.log(resources)}
      <div>
        <div className="col-12">
          <Card>
            <Card.Body>
              {loading && (
                <div
                  className="text-center"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ borderRadius: "50%" }}
                  ></Spinner>
                  <span>Loading...</span>
                </div>
              )}
              {!loading && (
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav
                    as="ul"
                    className="nav-pills mb-4 justify-content-between"
                  >
                    <h5>In interdum mauris sit amet porttitor pretium</h5>
                    <Select
                      options={resources}
                      styles={themeStyle}
                      onChange={setSelectedResource}
                      value={selectedResource}
                    />
                    <div className="d-flex">
                      {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                          <Nav.Link className="nav-link-tab" eventKey={data.name.toLowerCase()}>
                            {i === 0 ? "Mensuel" : i === 1 ? "Annuel" : ""}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </div>
                  </Nav>
                  <Tab.Content>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    instructorAbsentHours: state?.statistics?.instructorAbsentHours,
    loading: state?.statistics?.loading,
    usersData: state.users.users,
  };
};
const mapDispatchToProps = {
  getInstructorAbsentHours,
  usersList
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab8);
