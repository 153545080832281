import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { singleVehicle } from "../../../../store/vehicles/action";
import { Button, Card, Nav, Tab, Table, Modal, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import product1 from "../../../../images/product/1.jpg";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { saveAs } from "file-saver";

const initVehicle = {
  name: "",
  immatriculation: "",
  date: "",
  instructor: "",
  type: "",
  drivingSchool: "",
};

const ViewVehicle = ({ oneVehicle, loading, singleVehicle }) => {
  const [finePopUpModal, setFinePopUpModal] = useState(false);
  const [crashPopUpModal, setCrashPopUpModal] = useState(false);
  const [selectedVehicleDocs, setSelectedVehicleDocs] = useState({});
  const [vehicle, setVehicle] = useState({ ...initVehicle });
  const [vehicleFines, setVehicleFines] = useState([]);
  const [vehicleRepairs, setVehicleRepairs] = useState([]);
  const [vehicleReports, setVehicleReport] = useState([]);
  const [modalData, setModalData] = useState({});
  const navigation = useNavigate();
  const { id } = useParams();
  let vehicleId = id;
  let filesRootPath =
    process.env.NODE_ENV === "development" ? "http://localhost:5001/" : "/api/";

  useEffect(() => {
    singleVehicle(vehicleId).catch((error) => {
      notifyError(`Vehicle data Loading Failed, Error: ${error?.payload}`);
      setTimeout(function () {
        navigation("/vehicules");
      }, 7000);
    });
  }, [vehicleId]);

  useEffect(() => {
    if (oneVehicle && vehicleId) {
      setVehicle({
        ...initVehicle,
        name: oneVehicle?.name,
        immatriculation: oneVehicle?.immatriculation,
        type: oneVehicle?.VehicleTypes?.type,
        date: moment(oneVehicle?.date).format("YYYY-MM-DD") || "",
        instructor:
          oneVehicle?.instructorVehicles &&
          oneVehicle?.instructorVehicles?.firstname +
            " " +
            oneVehicle?.instructorVehicles?.lastname,
        drivingSchool: oneVehicle?.drivingSchoolVehicles?.name,
      });
    }
    let vehicleDocs = {};
    oneVehicle?.vehicleImage?.forEach((image, index) => {
      if (image.path) {
        vehicleDocs[index] = image;
      }
    });
    if (Object.keys(vehicleDocs).length > 0) {
      setSelectedVehicleDocs(vehicleDocs);
    }

    let fines = oneVehicle?.vehiclePenalty?.map((penalty) => ({
      instructorName:
        oneVehicle?.instructorVehicles?.firstname +
        " " +
        oneVehicle?.instructorVehicles?.lastname,
      instructorImage: oneVehicle?.instructorVehicles?.photo_id,
      id: penalty?.id,
      type:penalty?.penaltyType?.type + "-" + penalty?.penaltyType?.subtype,
      amount: penalty?.amount,
      comment: penalty?.comment,
      date: moment(penalty?.created_at).format("YYYY/MM/DD"),
      docs: penalty?.penaltyDocs?.map((doc) => ({
        name: doc?.documentPenalty?.path?.slice("uploads\\penalties\\".length),
        file: doc?.documentPenalty?.path,
        type: doc?.documentPenalty?.type,
      })),
    }));
    fines?.length > 0 ? setVehicleFines(fines) : setVehicleFines([]);
    let repairs = oneVehicle?.vehicleRepairs?.map((repair) => ({
      instructorName:
        oneVehicle?.instructorVehicles?.firstname +
        " " +
        oneVehicle?.instructorVehicles?.lastname,
      instructorImage: oneVehicle?.instructorVehicles?.photo_id,
      id: repair?.id,
      type: repair?.repairType?.type + "-" + repair?.repairType?.subtype,
      amount: repair?.amount,
      comment: repair?.comment,
      date: moment(repair?.created_at).format("YYYY/MM/DD"),
      docs: repair?.repairDoc?.map((doc) => ({
        name: doc?.documentRepair?.path?.slice("uploads\\repairs\\".length),
        file: doc?.documentRepair?.path,
        type: doc?.documentRepair?.type,
      })),
    }));
    repairs?.length > 0 ? setVehicleRepairs(repairs) : setVehicleRepairs([]);

    let reports = oneVehicle?.vehicleReport?.map((report) => ({
      instructorName:
        oneVehicle?.instructorVehicles?.firstname +
        " " +
        oneVehicle?.instructorVehicles?.lastname,
      instructorImage: oneVehicle?.instructorVehicles?.photo_id,
      id: report?.id,
      type: report?.reportType?.type + "-" + report?.reportType?.subtype,
      immatriculation: oneVehicle?.immatriculation,
      comment: report?.comment,
      amount: report?.amount,
      date: moment(report?.created_at).format("YYYY/MM/DD"),
      docs: report?.reportDocs?.map((doc) => ({
        name: doc?.documentReport?.path?.slice("uploads\\reports\\".length),
        file: doc?.documentReport?.path,
        type: doc?.documentReport?.type,
      })),
    }));
    reports?.length > 0 ? setVehicleReport(reports) : setVehicleReport([]);

    return () => {
      if (!oneVehicle) {
        setVehicle({ ...initVehicle });
        setSelectedVehicleDocs([]);
      }
    };
  }, [vehicleId, oneVehicle]);

  // const handlePrintClick = async (docsArray) => {
  //   if(docsArray && docsArray.length) {
  //     for (const docs of docsArray) {
  //       if (docs.file) {
  //         const printWindow = window.open("", "_blank");
  //         let src = `${filesRootPath}${docs.file}`;
  
  //         if (docs?.type === "application/pdf") {
  //           const response = await fetch(`${filesRootPath}${docs.file}`);
  //           const arrayBuffer = await response.arrayBuffer();
  //           const blob = new Blob([arrayBuffer], { type: "application/pdf" });
  //           const url = URL.createObjectURL(blob);
  
  //           printWindow.document.write(`
  //             <html>
  //               <head>
  //                 <title>Print</title>
  //               </head>
  //               <body>
  //                 <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.min.js"></script>
  //                 <script src="https://cdnjs.cloudflare.com/ajax/libs/pdfjs-dist/2.11.338/pdf.worker.min.js"></script>
  //                 <script>
  //                   var pdfPath = '${url}';
  //                   pdfjsLib.getDocument(pdfPath).promise.then(function(pdf) {
  //                     var numPages = pdf.numPages;
  //                     var pagesLoaded = 0;
  //                     var container = document.createElement('div');
  //                     container.style.visibility = 'hidden';
  //                     document.body.appendChild(container);
      
  //                     for (var i = 1; i <= numPages; i++) {
  //                       pdf.getPage(i).then(function(page) {
  //                         var scale = 1.5;
  //                         var viewport = page.getViewport({ scale: scale });
  //                         var canvas = document.createElement('canvas');
  //                         var context = canvas.getContext('2d');
  //                         canvas.height = viewport.height;
  //                         canvas.width = viewport.width;
  //                         container.appendChild(canvas);
  //                         page.render({ canvasContext: context, viewport: viewport }).promise.then(function() {
  //                           pagesLoaded++;
  //                           if (pagesLoaded === numPages) {
  //                             container.style.visibility = 'visible';
  //                             setTimeout(function() {
  //                               window.print();
  //                               window.onafterprint = function() {
  //                                 // Close the print window after printing
  //                                 setTimeout(function() {
  //                                   window.close();
  //                                 }, 100);
  //                               };
  //                             }, 100);
  //                           }
  //                         });
  //                       });
  //                     }
  //                   });
  //                 </script>
  //               </body>
  //             </html>
  //           `);
  //         } else if (docs?.type.startsWith("image/")) {
  //           printWindow.document.write(`
  //             <html>
  //               <head>
  //                 <title>Print</title>
  //               </head>
  //               <body>
  //                 <img src="${src}" style="max-width: 100%; max-height: 100%;" />
  //               </body>
  //             </html>
  //           `);
  
  //           printWindow.document.close();
  //           printWindow.onload = () => {
  //             printWindow.print();
  //             printWindow.onafterprint = () => {
  //               printWindow.close();
  //             };
  //           };
  //         }
  //       }
  //     }
  //   }
  // };

  const handleDownloadClick = (docsArray) => {
    if (docsArray && docsArray.length) {
      for (const docs of docsArray) {
        if (docs.file) {
          const fileURL = `${filesRootPath}${docs.file} `;
          saveAs(fileURL, docs.name);
        }
      }
    }
  };

  const handleViewClick = (id, type) => {
    let data = {};

    if (type === "Fines") {
      data = vehicleFines.find((fine) => fine.id === id);
      data.mType = type;
      setFinePopUpModal(true);
    } else if (type === "Repairs") {
      data = vehicleRepairs.find((repair) => repair.id === id);
      data.mType = type;
      setFinePopUpModal(true);
    } else if (type === "Reports") {
      data = vehicleReports.find((report) => report.id === id);
      data.mType = type;
      setCrashPopUpModal(true);
    }
    setModalData(data);
  };

  const handleBackClick = () => {
    navigation("/vehicules");
  };

  const handleModalClose = () => {
    setCrashPopUpModal(false);
    setFinePopUpModal(false);
    setModalData({});
  };

  // Toaster ****
  const notifyError = (msg) => {
    toast.error(`❌ ${msg} `, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div>
      <ToastContainer />
      {loading ? (
        <Card>
          <Card.Body>
            <div
              className="text-center"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Spinner
                animation="border"
                role="status"
                style={{ borderRadius: "50%" }}
              ></Spinner>
              <span>Loading...</span>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Card>
          <Card.Body>
            <div className="row">
              <div className="col-lg-5 col-md-6 col-12">
                <Tab.Container defaultActiveKey="0">
                  <Tab.Content>
                    {Array.from({ length: 4 }, (_, index) => (
                      <Tab.Pane eventKey={index} key={index}>
                        {selectedVehicleDocs[index] ? (
                          <img
                            src={`${filesRootPath}${selectedVehicleDocs[index].path} `}
                            alt=""
                            style={{
                              borderRadius: "12px",
                              width: "100%",
                              height: "350px",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "350px",
                              backgroundColor: "black",
                              borderRadius: "12px",
                              display: "block",
                            }}
                          ></div>
                        )}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                  <div className="tab-slide-content new-arrival-product mb-4 mb-xl-0">
                    {/* Nav tabs */}
                    <Nav
                      as="ul"
                      className="nav slide-item-list mt-3"
                      role="tablist"
                    >
                      {Array.from({ length: 4 }, (_, index) => {
                        const file = selectedVehicleDocs[index];

                        return (
                          <Nav.Item as="li" key={index}>
                            <Nav.Link as="a" to={index} eventKey={index}>
                              {file ? (
                                <img
                                  src={`${filesRootPath}${file.path} `}
                                  alt=""
                                  style={{
                                    objectFit: "cover",
                                    objectPosition: "center",
                                    width: "100%",
                                    height: "65px",
                                  }}
                                />
                              ) : (
                                <div
                                  className="img-fluid"
                                  style={{
                                    width: "100%",
                                    height: "65px",
                                    backgroundColor: "black",
                                    borderRadius: "12px",
                                    display: "block",
                                  }}
                                ></div>
                              )}
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav>
                  </div>
                </Tab.Container>
              </div>
              <div className="col-xl-7 col-md-6 col-12">
                <div className="detailsPage">
                  <div className="mb-3">
                    <label className="col-form-label">Brand</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        defaultValue={vehicle.name}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">Registration</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        name="immatriculation"
                        defaultValue={vehicle.immatriculation}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">Monitor</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        name="monitor"
                        defaultValue={vehicle.instructor}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">Driving School</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        name="drivingSchool"
                        defaultValue={vehicle.drivingSchool}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">Date</label>
                    <input
                      type="date"
                      className="form-control"
                      defaultValue={vehicle.date}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">Type</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        name="vehicleType"
                        defaultValue={vehicle.type}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5 pt-5">
              <div
                className="col-lg-4 col-md-6 col-12"
                style={{ maxHeight: "600px", overflow: "hidden" }}
              >
                <Card style={{ border: "1px solid gray" }}>
                  <Card.Header>
                    <Card.Title>Amendes</Card.Title>
                  </Card.Header>
                  <Card.Body style={{ overflow: "scroll", padding: "0.5rem" }}>
                    <Table responsive>
                      <tbody>
                        {!vehicleFines.length && (
                          <tr>
                            <td colSpan={3} style={{ textAlign: "center" }}>
                              No Records...
                            </td>
                          </tr>
                        )}
                        {vehicleFines.length > 0 &&
                          vehicleFines?.map((data) => (
                            <tr key={data.id}>
                              <td>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ gap: "10px", cursor: "pointer" }}
                                  onClick={() =>
                                    handleViewClick(data.id, "Fines")
                                  }
                                >
                                  {data.instructorImage && (
                                    <img
                                      src={data.instructorImage}
                                      className="rounded-lg"
                                      width="24"
                                      alt=""
                                    />
                                  )}
                                  <span className="w-space-no">
                                    {data.instructorName}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="d-flex"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleViewClick(data.id, "Fines")
                                  }
                                >
                                  {data.date}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex">
                                  {/* <button
                                    className="btn btn-primary shadow sharp me-1"
                                    onClick={() => handlePrintClick(data.docs)}
                                  >
                                    <i className="far fa-file-alt"></i>
                                  </button> */}
                                  <button
                                    className="btn btn-danger shadow sharp"
                                    onClick={() =>
                                      handleDownloadClick(data.docs)
                                    }
                                  >
                                    <i className="fas fa-upload"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </div>

              <div
                className="col-lg-4 col-md-6 col-12"
                style={{ maxHeight: "600px", overflow: "hidden" }}
              >
                <Card style={{ border: "1px solid gray" }}>
                  <Card.Header>
                    <Card.Title>Entretiens et réparations</Card.Title>
                  </Card.Header>
                  <Card.Body style={{ overflow: "scroll", padding: "0.5rem" }}>
                    <Table responsive>
                      <tbody>
                        {!vehicleRepairs.length && (
                          <tr>
                            <td colSpan={3} style={{ textAlign: "center" }}>
                              No Records...
                            </td>
                          </tr>
                        )}
                        {vehicleRepairs.length > 0 &&
                          vehicleRepairs?.map((data) => (
                            <tr key={data.id}>
                              <td>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ gap: "10px", cursor: "pointer" }}
                                  onClick={() =>
                                    handleViewClick(data.id, "Repairs")
                                  }
                                >
                                  {data.instructorImage && (
                                    <img
                                      src={data.instructorImage}
                                      className="rounded-lg"
                                      width="24"
                                      alt=""
                                    />
                                  )}
                                  <span className="w-space-no">
                                    {data.instructorName}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="d-flex"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleViewClick(data.id, "Repairs")
                                  }
                                >
                                  {data.date}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex">
                                  {/* <button
                                    className="btn btn-primary shadow sharp me-1"
                                    onClick={() => handlePrintClick(data.docs)}
                                  >
                                    <i className="far fa-file-alt"></i>
                                  </button> */}
                                  <button
                                    className="btn btn-danger shadow sharp"
                                    onClick={() =>
                                      handleDownloadClick(data.docs)
                                    }
                                  >
                                    <i className="fas fa-upload"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </div>

              <div
                className="col-lg-4 col-md-6 col-12"
                style={{ maxHeight: "600px", overflow: "hidden" }}
              >
                <Card style={{ border: "1px solid gray" }}>
                  <Card.Header>
                    <Card.Title>Constats</Card.Title>
                  </Card.Header>
                  <Card.Body style={{ overflow: "scroll", padding: "0.5rem" }}>
                    <Table responsive>
                      <tbody>
                        {!vehicleReports.length && (
                          <tr>
                            <td colSpan={3} style={{ textAlign: "center" }}>
                              No Records...
                            </td>
                          </tr>
                        )}
                        {vehicleReports.length > 0 &&
                          vehicleReports?.map((data) => (
                            <tr key={data.id}>
                              <td>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ gap: "10px", cursor: "pointer" }}
                                  onClick={() =>
                                    handleViewClick(data.id, "Reports")
                                  }
                                >
                                  {data.instructorImage && (
                                    <img
                                      src={data.instructorImage}
                                      className="rounded-lg"
                                      width="24"
                                      alt=""
                                    />
                                  )}
                                  <span className="w-space-no">
                                    {data.instructorName}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="d-flex"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleViewClick(data.id, "Reports")
                                  }
                                >
                                  {data.date}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex">
                                  {/* <button
                                    className="btn btn-primary shadow sharp me-1"
                                    onClick={() => handlePrintClick(data.docs)}
                                  >
                                    <i className="far fa-file-alt"></i>
                                  </button> */}
                                  <button
                                    className="btn btn-danger shadow sharp"
                                    onClick={() =>
                                      handleDownloadClick(data.docs)
                                    }
                                  >
                                    <i className="fas fa-upload"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-5">
              <Button
                className="mb-2"
                variant="info"
                width={30}
                onClick={handleBackClick}
              >
                Retour aux véhicules
              </Button>
            </div>
          </Card.Body>
        </Card>
      )}
      <Modal
        className="fade bd-example-modal-lg "
        show={finePopUpModal}
        onHide={() => handleModalClose()}
        size="md"
      >
        <Modal.Header>
          <h4>
            {modalData.type} {modalData.date}
          </h4>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setFinePopUpModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <div className="mb-1">
              <label className="col-form-label">Date</label>
              <input
                className="form-control"
                type="text"
                defaultValue={modalData.date}
                disabled
              />
            </div>
            <div className="mb-1">
              <label className="col-form-label d-flex justify-content-between">
                <p>Télécharger le fichier constat</p>
                <button
                  className="btn btn-lg sharp"
                  onClick={() => {
                    handleDownloadClick(modalData?.docs);
                  }}
                >
                  <i className="fas fa-upload"></i>
                </button>
              </label>
              <div className="">
                <textarea
                  className="form-txtarea form-control"
                  rows={modalData?.docs?.length || 1}
                  style={{ resize: "none" }}
                  spellCheck="false"
                  defaultValue={modalData?.docs?.map((doc) => doc?.name)
                    .join("\n")}
                  disabled
                ></textarea>
              </div>
            </div>
            <div className="mb-1">
              <label className="col-form-label">Type d'amende</label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={modalData.type}
                  disabled
                />
              </div>
            </div>
            <div className="mb-1">
              <label className="col-form-label">Commentaire</label>
              <div className="">
                <textarea
                  className="form-txtarea form-control"
                  rows="3"
                  spellCheck="false"
                  defaultValue={modalData.comment}
                  disabled
                ></textarea>
              </div>
            </div>
            <div className="mb-1">
              <label className="col-form-label">Montant</label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={modalData.amount}
                />
              </div>
            </div>
            <div className="me-auto ms-auto">
              <Button
                className="text-center mt-5 "
                variant="info"
                width={20}
                onClick={() => handleDownloadClick(modalData.docs)}
              >
                Téléharger l'amende
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* POP UP model for CRASH */}
      <Modal
        className="fade bd-example-modal-lg "
        show={crashPopUpModal}
        onHide={() => handleModalClose()}
        size="md"
      >
        <Modal.Header>
          <h4>
            {modalData.type} {modalData.date}
          </h4>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setCrashPopUpModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <div className="mb-1">
              <label className="col-form-label">Date</label>
              <input
                className="form-control"
                type="text"
                defaultValue={modalData.date}
                disabled
              />
            </div>
            <div className="mb-1">
              <label className="col-form-label d-flex justify-content-between">
                <p>Télécharger le fichier constat</p>
                <button
                  className="btn btn-lg sharp"
                  onClick={() => {
                    handleDownloadClick(modalData?.docs);
                  }}
                >
                  <i className="fas fa-upload"></i>
                </button>
              </label>
              <div className="">
                <textarea
                  className="form-txtarea form-control"
                  rows={modalData?.docs?.length || 1}
                  style={{ resize: "none" }}
                  spellCheck="false"
                  defaultValue={modalData?.docs?.map((doc) => doc?.name).join("\n")}
                  disabled
                ></textarea>
              </div>
            </div>
            <div className="mb-1">
              <label className="col-form-label">Type d'amende</label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={modalData.type}
                  disabled
                />
              </div>
            </div>
            <div className="mb-1">
              <label className="col-form-label">Commentaire</label>
              <div className="">
                <textarea
                  className="form-txtarea form-control"
                  rows="3"
                  spellCheck="false"
                  defaultValue={modalData.comment}
                  disabled
                ></textarea>
              </div>
            </div>
            <div className="mb-1">
              <label className="col-form-label">immatriculation</label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={modalData.immatriculation}
                  disabled
                />
              </div>
            </div>
            <div className="mb-1">
              <label className="col-form-label">Montant</label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={modalData.amount}
                />
              </div>
            </div>
            <div className="me-auto ms-auto">
              <Button
                className="text-center mt-5 "
                variant="info"
                width={20}
                onClick={() => handleDownloadClick(modalData.docs)}
              >
                Téléharger l'constats
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    oneVehicle: state.vehicle.singlevehicle,
    loading: state.vehicle.loading,
  };
};

const mapDispatchToProps = {
  singleVehicle,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewVehicle);
