import React, { useEffect, useState } from "react";
import { Card, Tab, Nav, Spinner } from "react-bootstrap";
import GraphPage1 from "./GraphPage1";
import GraphPage2 from "./GraphPage2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { getTotalPackages } from "../../../../../store/statistics/action";

const Tab1 = ({ getTotalPackages, totalPackage, loading }) => {
  const [monthlyPackages, setMonthlyPackages] = useState([]);
  const [yearlyPackages, setYearlyPackages] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0)

  useEffect(() => {
    getTotalPackages().catch((error) => {
      notifyError(
        `while fetching number of Registrations, error : => ${error}`
      );
    });
  }, []);

  useEffect(() => {
    if (totalPackage.monthly && totalPackage.yearly && !loading) {
      var monthData = [];
      var yearData = [];

      const monthNames = {
        1: "Janvier",
        2: "Février",
        3: "Mars",
        4: "Avril",
        5: "Mai",
        6: "Juin",
        7: "Juillet",
        8: "Août",
        9: "Septembre",
        10: "Octobre",
        11: "Novembre",
        12: "Décembre",
      };
      for (let i = 1; i <= 12; i++) {
        const monthName = monthNames[i];
        monthData.push({
          month: monthName,
          newStudentCount: 0,
          monthIndex: i,
        });
      }

      monthData.forEach((item) => {
        totalPackage.monthly.forEach((month) => {
          if (month.month === item.monthIndex) {
            item.newStudentCount = month.newStudentCount;
          }
        });
      });

      const currentYear = new Date().getFullYear();
      for (let year = 2013; year <= currentYear; year++) {
        const yearInfo = totalPackage.yearly.find(
          (item) => item.year === year
        );
        yearData.push({
          year: year,
          newStudentCount: yearInfo ? yearInfo.newStudentCount : 0,
        });
      }
    }
    setYearlyPackages(yearData);
    setMonthlyPackages(monthData);
    setTotalStudents(totalPackage?.totalStudents)
  }, [totalPackage, loading]);
  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const tabData = [
    {
      name: "month",
      content: <GraphPage1 data={monthlyPackages} />,
    },
    {
      name: "year",
      content: <GraphPage2 data={yearlyPackages} />,
    },
  ];

  return (
    <>
      <ToastContainer />
      <div>
        <div className="col-12">
          <Card>
            <Card.Body>
              {loading && (
                <div
                  className="text-center"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ borderRadius: "50%" }}
                  ></Spinner>
                  <span>Loading...</span>
                </div>
              )}
              {!loading && (
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav
                    as="ul"
                    className="nav-pills mb-4 justify-content-between"
                  >
                    <h5>In interdum mauris sit amet porttitor pretium</h5>
                    <div className="d-flex">
                      {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                          <Nav.Link className="nav-link-tab" eventKey={data.name.toLowerCase()}>
                            {i === 0 ? "Mensuel" : i === 1 ? "Annuel" : ""}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </div>
                  </Nav>
                  <Tab.Content>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', background: "#F44336", color: "white", padding: '0.5rem', borderRadius: "6px" }} >
                <span>Total Registrations</span>
                <span>{totalStudents}</span>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    totalPackage: state?.statistics?.totalPackages,
    loading: state?.statistics?.loading,
  };
};
const mapDispatchToProps = {
  getTotalPackages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab1);
