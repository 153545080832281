import React, { useState, useEffect } from "react";
import FilteringTable from "./components/FilteringTable";
import { Button, Card, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { deleteStudent, studentList } from "../../../store/students/action";
import { connect } from "react-redux";
import moment from "moment";
import "./components/filtering.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Students = (props) => {
  const { loading, list /* :studentData */, listStudent } = props;
  const [studentData, setStudentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordIdToDelete, setRecordIdToDelete] = useState(null);

  useEffect(() => {
    dataLoading();
  }, []);

  const dataLoading = async () => {
    try {
      return await listStudent();
    } catch (error) {
      notifyFetchError(error?.payload);
    }
  };
  useEffect(() => {
    list?.length > 0 ? setStudentData(list) : setStudentData([]);
    setIsLoading(loading);
  }, [loading, list]);
  const notifyFetchError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifyError = () => {
    toast.error("❌ Stduent Can't Be Deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifyDeleteSuccess = () => {
    toast.success("Student Deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleDelete = async (id) => {
    try {
      await props.deleteStudent(id);
      notifyDeleteSuccess();
      await listStudent();
    } catch (error) {
      console.log(error, "<== while deleting student error");
      notifyError();
    } finally {
    }
  };

  const openDeleteModal = (recordId) => {
    setRecordIdToDelete(recordId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setRecordIdToDelete(null);
  };

  const confirmDelete = async () => {
    if (recordIdToDelete) {
      await handleDelete(recordIdToDelete);
      closeDeleteModal();
    }
  };

  const dateFormat = (value) => {
    if (!value) {
      return null;
    }
    let formattedDate = moment(value).add(1, "year").format("MM/DD/YYYY");
    return formattedDate;
  };

  const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;

    return (
      <div>
        <input
          className="form-control input-search"
          value={filterValue || ""}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
    );
  };

  const ColumnFilterSelect = ({ column }) => {
    const { filterValue, setFilter } = column;
    const dropdownOptions = [
      { value: "", label: "All" },
      { value: "-", label: "code" },
      { value: "null", label: "no code" },
    ];
    return (
      <div>
        <select
          className="form-select"
          value={filterValue || ""}
          onChange={(e) => setFilter(e.target.value)}
        >
          {dropdownOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const COLUMNS = [
    {
      Header: "Genre",
      Footer: "Genre",
      accessor: "gender",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <Link
            to={`/View-Student/${row.original.id}`}
            className="d-flex align-items-center"
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
          >
            {row.original.gender}
          </Link>
        );
      },
    },
    {
      Header: "Nom",
      Footer: "Nom",
      accessor: "lastname",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <Link
            to={`/View-Student/${row.original.id}`}
            className="d-flex align-items-center"
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
          >
            {row.original.lastname}
          </Link>
        );
      },
    },
    {
      Header: "Prénom",
      Footer: "Prénom",
      accessor: "firstname",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <Link
            to={`/View-Student/${row.original.id}`}
            className="d-flex align-items-center"
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
          >
            {row.original.firstname}
          </Link>
        );
      },
    },
    {
      Header: () => {
        return (
          <>
            Address
            <div
              className="d-flex justify-content-start"
              style={{ height: "38px", marginTop: "5px" }}
            ></div>
          </>
        );
      },
      Footer: "Adresse",
      accessor: "address",
      disableFilters: true,
      minWidth: 300,
      maxWidth: 300,
      width: 300,
    },
    {
      Header: "Téléphone",
      Footer: "Téléphone",
      accessor: "mobile",
      Filter: ColumnFilter,
    },
    {
      Header: "NEPH",
      Footer: "NEPH",
      accessor: "neph",
      Filter: ColumnFilter,
    },
    {
      Header: "Date fin de formule",
      Footer: "Date fin de formule",
      accessor: "date_code",
      minWidth: 150,
      maxWidth: 150,
      width: 150,
      Filter: ColumnFilterSelect,
      Cell: ({ value }) => {
        const date = dateFormat(value);
        return <p style={{ margin: 0 }}>{date}</p>;
      },
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: ({ row }) => {
        const recordId = row.original.id;
        return (
          <div className="d-flex">
            <Link
              to={`/Edit-Student/${recordId}`}
              className="btn btn-transparent  btn-xs sharp me-3"
            >
              <i className="fa-solid fa-pencil fa-2xl"></i>
            </Link>
            <button
              className="btn btn-transparent btn-xs sharp me-1"
              // style={{ color: "white" }}
              onClick={() => openDeleteModal(recordId)}
            >
              <i className="fa-solid fa-trash fa-2xl"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ToastContainer />

      <Link to="/Add-Student" className="btn btn-primary">
        Ajouter un élève
      </Link>
      <Card className="mt-3">
        <Card.Body>
          <FilteringTable
            students={studentData}
            headerColumn={COLUMNS}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>

      <Modal show={showDeleteModal} onHide={closeDeleteModal}>
        <Modal.Header>
          <Modal.Title>Confirmation de la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer cet étudiant ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    list: state.student.students,
    loading: state.student.loading,
  };
};

const mapDispatchToProps = {
  listStudent: studentList,
  deleteStudent,
};
export default connect(mapStateToProps, mapDispatchToProps)(Students);
