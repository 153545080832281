import React, { useEffect, useState } from "react";
import { Tab, Nav, Card } from "react-bootstrap";
import FilteringTable from "./components/FilteringTable";
import { alertsList } from "../../../store/alerts/action";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const Alerts = (props) => {
  let { list, loading, alertsList } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [alertsData, setAlertsData] = useState([]);
  const [generalAlert, setGeneralAlert] = useState([]);
  const [depositAlert, setDepositAlert] = useState([]);
  const [cashingAlert, setCashingAlert] = useState([]);

  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    alertsList().catch((error) => {
      notifyError(error?.payload);
    });
  }, []);

  useEffect(() => {
    setAlertsData(list);
    if (list?.length > 0) {
      alertsData.forEach((val) => {
        if (val?.type === "deposit") {
          setDepositAlert((prevData) => [
            ...prevData,
            {
              first_name: val.studentAlerts.firstname,
              last_name: val.studentAlerts.lastname,
              date: moment(val.created_at).format("DD/MM/YYYY"),
              type: val.resume,
              valid: val.status,
            },
          ]);
        } else if (val?.type === "cashing") {
          setCashingAlert((prevData) => [
            ...prevData,
            {
              first_name: val.studentAlerts.firstname,
              last_name: val.studentAlerts.lastname,
              date: moment(val.created_at).format("DD/MM/YYYY"),
              type: val.resume,
              valid: val.status,
            },
          ]);
        } else if (val?.type === "general") {
          setGeneralAlert((prevData) => [
            ...prevData,
            {
              first_name: val.studentAlerts.firstname,
              last_name: val.studentAlerts.lastname,
              date: moment(val.created_at).format("DD/MM/YYYY"),
              type: val.resume,
              valid: val.status,
            },
          ]);
        }
      });
    }
    setIsLoading(loading);
  }, [list, loading]);

  const COLUMNS = [
    {
      Header: "Nom",
      Footer: "Nom",
      accessor: "last_name",
    },
    {
      Header: "Prénom",
      Footer: "Prénom",
      accessor: "first_name",
    },
    {
      Header: "Date",
      Footer: "Date of  Birth",
      accessor: "date",
    },
    {
      Header: "Type",
      Footer: "Type",
      accessor: "type",
    },
    {
      Header: "Valide",
      Footer: "Valide",
      Cell: ({ row }) => (
        <div className="d-flex">
          <button
            className="btn btn-transparent btn-xs sharp"
            disabled={!row.original.valid}
            // style={{ color: "white" }}
          >
            <i className="fa-solid fa-triangle-exclamation fa-2xl"></i>
          </button>
        </div>
      ),
    },
  ];
  return (
    <>
      <ToastContainer />
      <div>
        <Tab.Container defaultActiveKey="Alertes générales">
          <Card>
            <Card.Body>
              <Nav as="ul" className="nav-pills light">
                <Nav.Item as="li">
                  <Nav.Link eventKey="Alertes générales">
                    Alertes générales
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="Alerte chèques d'encaissement">
                    Alerte chèques d'encaissement
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="Alerte chèques de caution">
                    Alerte chèques de caution
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Body>
          </Card>
          <Tab.Content className="">
            <Tab.Pane eventKey="Alertes générales">
              <Card className="mt-3">
                <Card.Body>
                  <FilteringTable
                    datas={generalAlert}
                    headerColumn={COLUMNS}
                    isLoading={isLoading}
                  />
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="Alerte chèques d'encaissement">
              <Card className="mt-3">
                <Card.Body>
                  <FilteringTable
                    datas={depositAlert}
                    headerColumn={COLUMNS}
                    isLoading={isLoading}
                  />
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="Alerte chèques de caution">
              <Card className="mt-3">
                <Card.Body>
                  <FilteringTable
                    datas={cashingAlert}
                    headerColumn={COLUMNS}
                    isLoading={isLoading}
                  />
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.alerts.alerts,
    loading: state.alerts.loading,
  };
};
const mapDispatchToProps = {
  alertsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
