import { CREATE_PLANNING, CREATE_PLANNING_FAIL, CREATE_PLANNING_SUCCESS, DELETE_PLANNING, DELETE_PLANNING_FAIL, DELETE_PLANNING_SUCCESS, GET_PLANNING, GET_PLANNING_FAIL, GET_PLANNING_SUCCESS, UPDATE_PLANNING, UPDATE_PLANNING_FAIL, UPDATE_PLANNING_SUCCESS } from "./actionTypes"

let initialState = {
    planningList : [],
    addPlanning : {},
    update : {},
    loading:true,
    success:false,
    error:''
}

export const planningReducer = (state=initialState,action)=>{
    switch(action.type){
        case GET_PLANNING : return {
            ...state,
            planningList:[],
            loading:true,
            success:false,
            error:''
        }
        case GET_PLANNING_SUCCESS : return {
            ...state,
            planningList: action.payload,
            loading:false,
            success:true,
            error:''
        }
        case GET_PLANNING_FAIL : return {
            ...state,
            planningList:[],
            loading:false,
            success:false,
            error:action.payload
        }
        case CREATE_PLANNING : return {
            ...state,
            addPlanning:{},
            loading:true,
            success:false,
            error:''
        }
        case CREATE_PLANNING_SUCCESS : return {
            ...state,
            addPlanning:action.payload,
            loading:false,
            success:true,
            error:''
        }
        case CREATE_PLANNING_FAIL : return {
            ...state,
            addPlanning:{},
            loading:false,
            success:false,
            error:action.payload
        }
        case DELETE_PLANNING : return {
            ...state,
            loading:true,
            success:false,
            error:''
        }
        case DELETE_PLANNING_SUCCESS : return {
            ...state,
            loading:false,
            success:true,
            error:''
        }
        case DELETE_PLANNING_FAIL : return {
            ...state,
            loading:false,
            success:false,
            error:action.payload
        }
        case UPDATE_PLANNING : return {
            ...state,
            update: {},
            loading:true,
            success:false,
            error : ''
        }
        case UPDATE_PLANNING_SUCCESS : return {
            ...state,
            update : action.payload,
            loading:false,
            success:true,
            error:''
        }
        case UPDATE_PLANNING_FAIL : return {
            ...state,
            update : {},
            loading:false,
            success:false,
            error:action.payload
        }
        default : return state
    }
} 