import moment from "moment";

/**
 * Application configuration
 */
export const calendarConfig = {
    // Features named by the properties are included.
    // An object is used to configure the feature.
    features : {
        // Turn the editor off completely, won't be created
        eventEdit : false,
        scheduleMenu : {
            items : {
                addEvent: null
            }
        }
     },
    eventTooltipFeature: {
        // align: 'l-r'
        disabled : true
    },

    date: moment(),

    // sidebar : {
    //     items : {
    //         resourceFilter : {
    //             // Initially select resource IDs 2, 3 and 4
    //             selected : [2, 1]
    //         }
    //     }
    // },
    // The subviews have a close tool which filters them out
    modeDefaults : {
        view : {
            // Show a close icon to filter out the resource
            tools : {
                // close : {
                //     cls     : 'b-fa b-fa-times',
                //     tooltip : 'Filter out this resource',

                //     // Will find the handler on the Calendar
                //     // handler : 'up.onSubviewCloseClick'
                //     handler : (...props) => console.log(props, ' <== click close')
                // }
                
            },
            autoCreate: false,
            strips : {
                // A simple widget showing location, temperature and a weather icon for each resource
                resourceInfo : {
                    type : 'widget',
                    dock : 'header',
                    cls  : 'b-resource-location',
                    // This method gets called when the panel is created and we return some meta data about the
                    // resource, like weather and city. Will be found on the Calendar
                    // html : 'up.getSubViewHeader'
                }
            }
        }
    },
    modes : {
        // Let's not show the default views
        day    : null,
        week   : null,
        month  : null,
        year   : null,
        agenda : null,

        // Mode name can be anything if it contains a "type" property.
        weekResources : {
            // Type has the final say over which view type is created
            type               : 'resource',
            title              : 'Week',
            // Specify how wide each resource panel should be
            resourceWidth      : '30em',
            hideNonWorkingDays : true,

            // This is a config object for the subviews
            view : {
                dayStartTime : 8
                // Dock an additional widget at the end of the header
            },

            // Info to display below a resource name
            meta : resource => resource.title
        },
        monthResources : {
            type               : 'resource',
            title              : 'Month',
            resourceWidth      : '30em',
            hideNonWorkingDays : false,

            view : {
                type : 'monthview'
            },

            meta : resource => resource.title
        }
    }
};
