import { api } from "../../utils/constants"
import { planning, student } from "../../utils/urls"
import { ADDSTUDENT, ADDSTUDENTCOMMENT, ADDSTUDENTCOMMENT_FAILED, ADDSTUDENTCOMMENT_SUCCESS, ADDSTUDENTFORMULA, ADDSTUDENTFORMULA_FAILED, ADDSTUDENTFORMULA_SUCCESS, ADDSTUDENTPAYMENT, ADDSTUDENTPAYMENT_FAILED, ADDSTUDENTPAYMENT_SUCCESS, ADDSTUDENTSKILL, ADDSTUDENTSKILL_FAILED, ADDSTUDENTSKILL_SUCCESS, ADDSTUDENT_FAILED, ADDSTUDENT_SUCCESS, DELETESTUDENT, DELETESTUDENTCOMMENT, DELETESTUDENTCOMMENT_FAILED, DELETESTUDENTCOMMENT_SUCCESS, DELETESTUDENTFORMULA, DELETESTUDENTFORMULA_FAILED, DELETESTUDENT_FAILED, DELETESTUDENT_SUCCESS, SINGLESTUDENT, SINGLESTUDENT_FAILED, SINGLESTUDENT_SUCCESS, STUDENTS, STUDENTS_FAILED, STUDENTS_SUCCESS, STUDENT_PLANNING, STUDENT_PLANNING_FAILED, STUDENT_PLANNING_SUCCESS, UPDATESTUDENT, UPDATESTUDENT_FAILED, UPDATESTUDENT_LICENCES, UPDATESTUDENT_LICENCES_FAILED, UPDATESTUDENT_LICENCES_SUCCESS, UPDATESTUDENT_SUCCESS } from "./actionType"


export const studentList = () => async dispatch => {
    dispatch({
        type: STUDENTS,
    })
    try {
        let response = await api(student(), 'get', {})
        dispatch({
            type: STUDENTS_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("fetching students error is ===>", error);
        return Promise.reject(dispatch({
            type: STUDENTS_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const singleStudent = (id) => async dispatch => {
    dispatch({
        type: SINGLESTUDENT,

    })
    try {
        let response = await api(student(id), 'get', {})
        dispatch({
            type: SINGLESTUDENT_SUCCESS,
            payload: response?.data?.data[0]
        })
        return response
    } catch (error) {
        return Promise.reject(dispatch({
            type: SINGLESTUDENT_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const addStudent = (data, headers) => async dispatch => {
    dispatch({
        type: ADDSTUDENT,
    })
    try {
        let response = await api(student(), 'post', data, headers);
        dispatch({
            type: ADDSTUDENT_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("adding student error is ===>", error);
        return Promise.reject(dispatch({
            type: ADDSTUDENT_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const updateStudent = (id, data, headers) => async dispatch => {
    dispatch({
        type: UPDATESTUDENT,
    })
    try {
        let response = await api(student(id), 'put', data, headers);
        dispatch({
            type: UPDATESTUDENT_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("updating student error is ==>", error);
        return Promise.reject(dispatch({
            type: UPDATESTUDENT_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const deleteStudent = (id) => async dispatch => {
    dispatch({
        type: DELETESTUDENT,
    })
    try {
        let response = await api(student(id), 'delete', {});
        console.log("deleting student ===>", response);
        dispatch({
            type: DELETESTUDENT_SUCCESS,
            payload: response?.data,
        })
        return response
    } catch (error) {
        console.log("deleting student error is ===>", error);
        return Promise.reject(dispatch({
            type: DELETESTUDENT_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const addStudentComment = (data) => async dispatch => {
    dispatch({
        type: ADDSTUDENTCOMMENT,
    })
    try {
        let response = await api(student("comment"), 'post', data);
        dispatch({
            type: ADDSTUDENTCOMMENT_SUCCESS,
            payload: response?.data?.data,
        })
        return response
    } catch (error) {
        console.log("adding comment for student Error: ==> ", error);
        return Promise.reject(dispatch({
            type: ADDSTUDENTCOMMENT_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const deleteStudentComment = (id) => async dispatch => {
    dispatch({
        type: DELETESTUDENTCOMMENT,
    })
    try {
        let response = await api(student("comment/" + id), 'delete');
        dispatch({
            type: DELETESTUDENTCOMMENT_SUCCESS,
            payload: response?.data?.data,
        })
        return response
    } catch (error) {
        console.log("deleting comment for student Error: ==> ", error);
        return Promise.reject(dispatch({
            type: DELETESTUDENTCOMMENT_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const addStudentPayment = (data) => async dispatch => {
    dispatch({
        type: ADDSTUDENTPAYMENT,
    })
    try {
        let response = await api(student("payment"), 'post', data);
        dispatch({
            type: ADDSTUDENTPAYMENT_SUCCESS,
            payload: response?.data?.data,
        })
        return response
    } catch (error) {
        console.log("adding payment for student Error: ==> ", error);
        return Promise.reject(dispatch({
            type: ADDSTUDENTPAYMENT_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const addStudentFormula = (data) => async dispatch => {
    dispatch({
        type: ADDSTUDENTFORMULA,
    })
    try {
        let response = await api(student("formula"), 'post', data);
        dispatch({
            type: ADDSTUDENTFORMULA_SUCCESS,
            payload: response?.data?.data,
        })
        return response
    } catch (error) {
        console.log("adding payment for student Error: ==> ", error);
        return Promise.reject(dispatch({
            type: ADDSTUDENTFORMULA_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const deleteStudentFormula = (id) => async dispatch => {
    dispatch({
        type: DELETESTUDENTFORMULA,
    })
    try {
        let response = await api(student(`formula/${id}`), 'delete', {});
        dispatch({
            type: DELETESTUDENT_SUCCESS,
            payload: response?.data?.data,
        })
        return response
    } catch (error) {
        console.log("deleting formula for student Error: ==> ", error);
        return Promise.reject(dispatch({
            type: DELETESTUDENTFORMULA_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const addStudentSkills = (data) => async dispatch => {
    dispatch({
        type: ADDSTUDENTSKILL,
    })
    try {
        let response = await api(student("skills"), 'post', data);
        dispatch({
            type: ADDSTUDENTSKILL_SUCCESS,
            payload: response?.data?.data,
        })
        return response
    } catch (error) {
        console.log("adding skills for student Error: ==> ", error);
        return Promise.reject(dispatch({
            type: ADDSTUDENTSKILL_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}
export const updateStudentLicences = (id,data) => async dispatch => {
    dispatch({
        type: UPDATESTUDENT_LICENCES,
    })
    try {
        let response = await api(student(`permit/${id}`), 'put', data);
        dispatch({
            type: UPDATESTUDENT_LICENCES_SUCCESS,
            payload: response?.data?.data,
        })
        return response
    } catch (error) {
        console.log("updating licences for student Error: ==> ", error);
        return Promise.reject(dispatch({
            type: UPDATESTUDENT_LICENCES_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}
export const getStudentPlanning = (id) => async dispatch => {
    dispatch({
        type: STUDENT_PLANNING,
    })
    try {
        let response = await api(planning(`student/${id}`), 'get', {})
        dispatch({
            type: STUDENT_PLANNING_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("fetching student planning error is ===>", error);
        return Promise.reject(dispatch({
            type: STUDENT_PLANNING_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}