import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Container, Dropdown } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { themeStyle } from "../../../utils/style";
import { connect, useDispatch } from "react-redux";
import { studentList } from "../../../store/students/action";
import { Logout } from "../../../store/actions/AuthActions";
import HeaderNew from "../../layouts/nav/HeaderNew";


const MenuNew = (props) => {
  // This is load more function

  const navigate = useNavigate()
  

  const MenuList = [
    {
      title: 'Eleves',
      iconStyle: "user",
      to: 'eleves',
    },
    // planning
    {
      title: 'Planning',
      iconStyle: "calendar",
      to: 'planning',
    },
    // Examens 
    {

      title: 'Examens',
      classsChange: 'mm-collapse',
      iconStyle: "graduation-cap",
      to: 'examens',
    },
    {

      title: 'Exam wishlist',
      classsChange: 'mm-collapse',
      iconStyle: "graduation-cap",
      to: 'exam-Wishlist',
    },
    {
      title: 'Alertes',
      classsChange: 'mm-collapse',
      iconStyle: "exclamation-triangle",
      to: 'alertes',
    },
    {
      title: 'Debits',
      iconStyle: "money-bill-1",
      to: 'debits',
    },
    {
      title: 'Statistiques',
      classsChange: 'mm-collapse',
      iconStyle: "square-poll-vertical",
      to: 'statistiques'
    },
    {
      title: 'Reglages',
      classsChange: 'mm-collapse',
      iconStyle: "cog",
      isMultiple: true,
      options: [
        {
          title: 'Auto-ecoles',
          to: 'auto-ecoles',
        },
        {
          title: 'Utilisate',
          to: 'utilisate',
        },
        {
          title: 'Moniteurs',
          to: 'moniteurs',
        },
        {
          title: 'Offres',
          to: 'offres',
        },
        {
          title: 'Vehicules',
          to: 'vehicules',
        },
      ]
    },
    {
      title: 'Recettes',
      classsChange: 'mm-collapse',
      iconStyle: "eur",
      to: 'recettes',
    },
  ]


  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div className="d-flex m-2 flex-column align-items-center gap-3" onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }} >
      {children}
    </div>
  ));




  return (
    <>
      <ToastContainer />
      <div id="menuSection">
        <HeaderNew />
        <h2 className="text-center" style={{ marginTop: "4rem", marginBottom: "2rem" }} >ADMINISTRATION</h2>
        <div className="d-flex align-item-center justify-content-center p-4" >
          <div className="d-flex flex-wrap gap-4 m-auto justify-content-center" style={{ maxWidth: "70%" }} >
            {
              MenuList?.map((item, index) => {

                if (item?.isMultiple) {
                  return (
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} >
                        <div className="rounded-circle shadow bg-white d-flex align-items-center justify-content-center" style={{ height: "150px", width: "150px" }} >
                          <i className={`fa-solid fa-${item?.iconStyle} fa-5x text-primary`}></i>
                        </div>
                        <h3 className="text-primary text-align-center" >{item?.title}</h3>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {
                          item?.options?.map((itemData, index) => (
                            <Dropdown.Item key={index} onClick={() => navigate(`/${itemData?.to}`)} >{itemData?.title}</Dropdown.Item>
                          ))
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                }

                return (
                  <div onClick={() => navigate(`/${item?.to}`)} key={index} className="d-flex m-2 flex-column align-items-center gap-3" >
                    <div className="rounded-circle shadow bg-white d-flex align-items-center justify-content-center" style={{ height: "150px", width: "150px" }} >
                      <i className={`fa-solid fa-${item?.iconStyle} fa-5x text-primary`}></i>
                    </div>
                    <h3 className="text-primary text-align-center" >{item?.title}</h3>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.student.students,
    loading: state.student.loading,
  };
};

const mapDispatchToProps = {
  listStudent: studentList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuNew);
