export const GET_PLANNING = "GET_PLANNING"
export const GET_PLANNING_SUCCESS = "GET_PLANNING_SUCCESS"
export const GET_PLANNING_FAIL = "GET_PLANNING_FAIL"

export const CREATE_PLANNING = "CREATE_PLANNING"
export const CREATE_PLANNING_SUCCESS = "CREATE_PLANNING_SUCCESS"
export const CREATE_PLANNING_FAIL = "CREATE_PLANNING_FAIL"

export const DELETE_PLANNING = "DELETE_PLANNING"
export const DELETE_PLANNING_SUCCESS = "DELETE_PLANNING_SUCCESS"
export const DELETE_PLANNING_FAIL = "DELETE_PLANNING_FAIL"

export const UPDATE_PLANNING = "UPDATE_PLANNING"
export const UPDATE_PLANNING_SUCCESS = "UPDATE_PLANNING_SUCCESS"
export const UPDATE_PLANNING_FAIL = "UPDATE_PLANNING_FAIL"