import { ADDDRIVINGSCHOOL, ADDDRIVINGSCHOOL_FAILED, ADDDRIVINGSCHOOL_SUCCESS, DELETEDRIVINGSCHOOL, DELETEDRIVINGSCHOOL_FAILED, DELETEDRIVINGSCHOOL_SUCCESS, DRIVINGSCHOOLS, DRIVINGSCHOOLS_FAILED, DRIVINGSCHOOLS_SUCCESS, SINGLEDRIVINGSCHOOL, SINGLEDRIVINGSCHOOL_FAILED, SINGLEDRIVINGSCHOOL_SUCCESS, UPDATEDRIVINGSCHOOL, UPDATEDRIVINGSCHOOL_FAILED, UPDATEDRIVINGSCHOOL_SUCCESS } from "./actionType"

let initialState = {
    drivingschools: [],
    drivingschool: {},
    addDrivingschool: '',
    updateDrivingschool: '',
    loading: false,
    success: false,
    error: '',
}

export function DrivingSchoolsReducer(state = initialState, action) {
    switch (action.type) {
        case DRIVINGSCHOOLS: return {
            ...state,
            drivingschools: [],
            loading: true,
            success: false,
            error: '',
        }
        case DRIVINGSCHOOLS_SUCCESS: return {
            ...state,
            drivingschools: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case DRIVINGSCHOOLS_FAILED: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case ADDDRIVINGSCHOOL: return {
            ...state,
            addDrivingschool: '',
            loading: true,
            success: false,
            error: '',
        }
        case ADDDRIVINGSCHOOL_SUCCESS: return {
            ...state,
            addDrivingschool: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case ADDDRIVINGSCHOOL_FAILED: return {
            ...state,
            addDrivingschool: '',
            loading: false,
            success: false,
            error: action.payload
        }
        case UPDATEDRIVINGSCHOOL: return {
            ...state,
            updateDrivingschool: '',
            loading: true,
            success: false,
            error: '',
        }
        case UPDATEDRIVINGSCHOOL_SUCCESS: return {
            ...state,
            updateDrivingschool: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case UPDATEDRIVINGSCHOOL_FAILED: return {
            ...state,
            updateDrivingschool: '',
            loading: false,
            success: false,
            error: action.payload
        }
        case DELETEDRIVINGSCHOOL: return {
            ...state,
            loading: true,
            success: false,
            error: ''
        }
        case DELETEDRIVINGSCHOOL_SUCCESS: return {
            ...state,
            loading: false,
            success: true,
            error: '',
        }
        case DELETEDRIVINGSCHOOL_FAILED: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case SINGLEDRIVINGSCHOOL: return {
            ...state,
            drivingschool: {},
            loading: true,
            success: false,
            error: ''
        }
        case SINGLEDRIVINGSCHOOL_SUCCESS: return {
            ...state,
            drivingschool: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case SINGLEDRIVINGSCHOOL_FAILED: return {
            ...state,
            drivingschool: {},
            loading: false,
            success: false,
            error: action.payload
        }
        default:
            return state
    }
}