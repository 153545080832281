import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tab, Nav, Spinner } from "react-bootstrap";
import Select from "react-select";
import "./EditStudent.css";
import {
  addStudent,
  singleStudent,
  studentList,
  updateStudent,
} from "../../../store/students/action";
import { drivingSchoolsList } from "../../../store/drivingSchools/action";

import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { themeStyle } from "../../../utils/style";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Avatar from "react-avatar";

const initStudent = {
  photo_id: "",
  gender: "",
  lastname: "",
  firstname: "",
  birthday: "",
  department: "",
  place_birthday: "",
  email: "",
  mobile: "",
  address: "",
  place_meet: "",
  neph: "",
  status: true,
  drivingschool_id: "",
  date_code: "",
  docsType: "Conduite accompagnée",
};

const errObj = {
  lastnameErr: "Nom de famille requis",
  firstnameErr: "Prénom requis",
  mobileErr: "Numéro de mobile requis",
  emailErr: "Adresse e-mail requise",
  invalidEmailErr: "Adresse e-mail invalide",
  birthdayErr: "Date de naissance requise",
};

const initDocs = {
  "Pièce d'identité": "",
  "Journée appel": "",
  "Justificatif de domicile": "",
  "Autre documents": "",
  "Pièce d'identité hébergeur": "",
  "Fac similé": "",
  ASSR2: "",
  "Permis de conduire": "",
  ref44: "",
  "Visite médicale": "",
  "Test psycho technique": "",
};

const AddEditStudent = (props) => {
  const {
    ListOfDrivingSchool,
    drivingSchoolsList,
    studentSuccess,
    studentLoading,
    studentInfo,
  } = props;
  const { id } = useParams();
  let studentID = id;
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedDrivingSchool, setSelectedDrivingSchool] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [student, setStudent] = useState({ ...initStudent });
  const [docs, setDocs] = useState({ ...initDocs });
  const baseUrl = process.env.NODE_ENV === 'development' ? "http://localhost:5001/" : "/api/"
  const [error, setError] = useState({
    lastnameErr: "",
    firstnameErr: "",
    mobileErr: "",
    emailErr: "",
    invalidEmailErr: "",
    birthdayErr: "",
  });
  const navigation = useNavigate();
  const typeWithDocs = {
    "Conduite accompagnée": [
      "Pièce d'identité",
      "Journée appel",
      "Justificatif de domicile",
      "Autre documents",
      "Pièce d'identité hébergeur",
      "Fac similé",
      "ASSR2",
    ],
    Voiture: [
      "Pièce d'identité",
      "Journée appel",
      "Justificatif de domicile",
      "Autre documents",
      "Pièce d'identité hébergeur",
      "Fac similé",
      "ASSR2",
    ],
    Moto: [
      "Pièce d'identité",
      "Journée appel",
      "Justificatif de domicile",
      "Autre documents",
      "Pièce d'identité hébergeur",
      "Fac similé",
      "ASSR2",
      "Permis de conduire",
    ],
    Annulation: [
      "ref44",
      "Visite médicale",
      "Test psycho technique",
      "Autre documents",
      "Fac similé",
    ],
  };
  const genderOptions = [
    { value: "Féminin", label: "Féminin" },
    { value: "mâle", label: "mâle" },
  ];

  //   Driving school fetching
  useEffect(() => {
    if (!ListOfDrivingSchool.length) {
      drivingSchoolsList();
    }
  }, []);
  const drivingSchoolOptions = ListOfDrivingSchool.map((val) => {
    return { value: { name: val.name, id: val.id }, label: val.name };
  });

  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifySuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleDocChange = (e, doc) => {
    const file = e.target.files[0];
    setDocs((prevDocs) => ({
      ...prevDocs,
      [doc]: file,
    }));
  };
  const handleChange = (e, fieldName) => {
    const value = e.target.value;
    setStudent((prevStudent) => ({
      ...prevStudent,
      [fieldName]: value,
    }));

    if (fieldName === "photo_id") {
      let file = e.target.files[0];
      if (file) {
        setStudent((prevStudent) => ({
          ...prevStudent,
          [fieldName]: file,
        }));
      }
    }
  };
  const clearInput = (e, fieldName) => {
    if (fieldName === "email") {
      setError({
        ...error,
        emailErr: "",
        invalidEmailErr: "",
      });
    } else if (fieldName === "lastname") {
      setError({
        ...error,
        lastnameErr: "",
      });
    } else if (fieldName === "firstname") {
      setError({
        ...error,
        firstnameErr: "",
      });
    } else if (fieldName === "mobile") {
      setError({
        ...error,
        mobileErr: "",
      });
    } else if (fieldName === "birthday") {
      setError({
        ...error,
        birthdayErr: "",
      });
    }
  };

  const validInputValue = (e, fieldName) => {
    let validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let value = e.target.value;

    if (fieldName === "email") {
      if (!value) {
        setError({
          ...error,
          emailErr: errObj.emailErr,
        });
      } else if (!validEmailRegex.test(value)) {
        setError({
          ...error,
          invalidEmailErr: errObj.invalidEmailErr,
        });
      }
    } else if (fieldName === "lastname") {
      if (!value) {
        setError({
          ...error,
          lastnameErr: errObj.lastnameErr,
        });
      }
    } else if (fieldName === "firstname") {
      if (!value) {
        setError({
          ...error,
          firstnameErr: errObj.firstnameErr,
        });
      }
    } else if (fieldName === "mobile") {
      if (!value) {
        setError({
          ...error,
          mobileErr: errObj.mobileErr,
        });
      }
    } else if (fieldName === "birthday") {
      if (!value) {
        setError({
          ...error,
          birthdayErr: errObj.birthdayErr,
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        !student.firstname ||
        !student.email ||
        !student.birthday ||
        !student.mobile ||
        !student.lastname
      ) {
        notifyError("provide valid data");
        return "-";
      }
      let studentData = {
        ...student,
        gender: selectedGender?.value,
        drivingschool_id: selectedDrivingSchool?.value?.id,
        birthday: new Date(student.birthday) || "",
        date_code: new Date(student.date_code) || "",
        docs: {},
      };
      const selectedDocs = typeWithDocs[student.docsType] || [];
      const formData = new FormData();
      let existingData = studentInfo || {};
      selectedDocs.forEach((doc) => {
        if (docs[doc]) {
          if (isUpdate) {
            studentData.id = existingData.id;
            const saveDocument = existingData?.studentDocument?.find(
              (document) => document.type === doc
            );

            if (saveDocument) {
              if (docs[doc] !== saveDocument.documentStudent.path) {
                studentData.docs[doc] = {
                  id: saveDocument.id,
                  student_id: saveDocument.student_id,
                  type: saveDocument.type,
                  documentStudent: {
                    id: saveDocument.documentStudent.id,
                    file: docs[doc],
                    path: "",
                    type: "",
                  },
                };
                formData.append(doc, docs[doc]);
              } else {
                studentData.docs[doc] = {
                  id: saveDocument.id,
                  student_id: saveDocument.student_id,
                  type: saveDocument.type,
                  documentStudent: {
                    id: saveDocument.documentStudent.id,
                    path: saveDocument.documentStudent.path,
                    type: saveDocument.documentStudent.type,
                  },
                };
              }
            } else {
              studentData.docs[doc] = docs[doc];
              formData.append(doc, docs[doc]);
            }
          } else {
            studentData.docs[doc] = docs[doc];
            formData.append(doc, docs[doc]);
          }
        }
      });
      if (student.photo_id) {
        formData.append("photo_id", student?.photo_id);
      }
      formData.append("studentData", JSON.stringify(studentData));
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      setIsLoading(true);
      if (isUpdate) {
        await props.updateStudent(studentID, formData, headers);
        notifySuccess("Update Successfully");
        setIsLoading(false);
      } else {
        await props.addStudent(formData, headers);
        notifySuccess("Added Successfully");
      }
      if (studentSuccess && !studentLoading) {
        navigation("/eleves");
      }
    } catch (error) {
      console.log(error);
      notifyError(error?.payload);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    props.getStudent(studentID);
  }, [studentID]);

  useEffect(() => {
    if (props.studentInfo && studentID) {
      setIsUpdate(true);
      let data = props.studentInfo ? props.studentInfo : {};
      setStudent((prevStudent) => ({
        ...prevStudent,
        photo_id: data?.photo_id ? `${baseUrl}${data?.photo_id}` : "",
        gender: data?.gender,
        lastname: data?.lastname,
        firstname: data?.firstname,
        mobile: data?.mobile,
        address: data?.address,
        neph: data?.neph,
        department: data?.department,
        place_birthday: data?.place_birthday,
        email: data?.email,
        place_meet: data?.place_meet,
        status: data?.status,
        drivingschool_id: data?.drivingschool_id,
        docsType: data?.docsType,
      }));
      setStudent((prevStudent) => ({
        ...prevStudent,
        date_code: moment(data?.date_code).format("YYYY-MM-DD"),
        birthday: moment(data?.birthday).format("YYYY-MM-DD"),
      }));
      let gender = genderOptions.filter((gender) => {
        return gender.value === data?.gender;
      });
      let drivingSchool = drivingSchoolOptions.filter((school) => {
        return school.value.id === data?.drivingschool_id;
      });
      setSelectedGender(gender[0]);
      setSelectedDrivingSchool(drivingSchool[0]);

      let studentDocuments = data?.studentDocument || [];
      let docs = { ...initDocs };
      if (studentDocuments.length > 0) {
        studentDocuments.forEach((studentDoc) => {
          if (docs.hasOwnProperty(studentDoc.type)) {
            docs[studentDoc.type] = studentDoc.documentStudent.path;
          }
        });
        setDocs(docs);
      } else {
        setDocs(docs);
      }
    }
  }, [props.studentInfo, studentID]);

  return (
    <>
      <ToastContainer />

      <Row id="EditStudentSection">
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="basic-form">
                <h3>Informations</h3>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div
                      className="form-group mb-3 col-12"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>Photo d'identité</label>
                      {isUpdate &&
                        student?.photo_id &&
                        !student?.photo_id?.name && (
                          <Avatar
                            src={student?.photo_id}
                            name={student?.firstname + " " + student?.lastname}
                            size="80"
                            round
                          />
                        )}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleChange(e, "photo_id")}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Sexe</label>
                      <Select
                        defaultValue={genderOptions[0]}
                        value={selectedGender}
                        onChange={setSelectedGender}
                        options={genderOptions}
                        styles={themeStyle}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        value={student.email}
                        onChange={(e) => handleChange(e, "email")}
                        onFocus={(e) => clearInput(e, "email")}
                        onBlur={(e) => validInputValue(e, "email")}
                      />
                      <p className="text-danger">
                        {error?.emailErr || error?.invalidEmailErr}
                      </p>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        value={student.lastname}
                        onChange={(e) => handleChange(e, "lastname")}
                        onFocus={(e) => clearInput(e, "lastname")}
                        onBlur={(e) => validInputValue(e, "lastname")}
                      />
                      <p className="text-danger">{error?.lastnameErr}</p>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Telephone mobile</label>
                      <input
                        type="text"
                        className="form-control"
                        value={student.mobile}
                        onChange={(e) => handleChange(e, "mobile")}
                        onFocus={(e) => clearInput(e, "mobile")}
                        onBlur={(e) => validInputValue(e, "mobile")}
                      />
                      <p className="text-danger">{error?.mobileErr}</p>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Prénom</label>
                      <input
                        type="text"
                        className="form-control"
                        value={student.firstname}
                        onChange={(e) => handleChange(e, "firstname")}
                        onFocus={(e) => clearInput(e, "firstname")}
                        onBlur={(e) => validInputValue(e, "firstname")}
                      />
                      <p className="text-danger">{error?.firstnameErr}</p>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Adresse</label>
                      <input
                        type="text"
                        className="form-control"
                        value={student.address}
                        onChange={(e) => handleChange(e, "address")}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-4">
                      <label>Date de naissance</label>
                      <input
                        type="date"
                        className="form-control"
                        value={student.birthday}
                        onChange={(e) => handleChange(e, "birthday")}
                        onFocus={(e) => clearInput(e, "birthday")}
                        onBlur={(e) => validInputValue(e, "birthday")}
                      />
                      <p className="text-danger">{error?.birthdayErr}</p>
                    </div>
                    <div className="form-group mb-3 col-md-2">
                      <label>Département</label>
                      <input
                        type="text"
                        className="form-control"
                        value={student.department}
                        onChange={(e) => handleChange(e, "department")}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Lieu de rencontre</label>
                      <input
                        type="text"
                        className="form-control"
                        value={student.place_meet}
                        onChange={(e) => handleChange(e, "place_meet")}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Lieu de naissance</label>
                      <input
                        type="text"
                        className="form-control"
                        value={student.place_birthday}
                        onChange={(e) => handleChange(e, "place_birthday")}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <div
                        className="form-check custom-checkbox checkbox-success check-lg"
                        style={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                          paddingTop: "20px",
                          gap: "10px",
                        }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="enabled"
                          name="enabled"
                          checked={student.status}
                          onChange={(e) => {
                            setStudent({
                              ...student,
                              status: e.target.checked,
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="enabled"
                          style={{ margin: 0 }}
                        >
                          Actif
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5 w-100">
                    <h3>Autres informations</h3>
                    <div className="d-flex flex-column">
                      <div className="row w-100 mb-2">
                        <div className="form-group mb-3 col-md-4">
                          <label>Nom de l'auto-école</label>
                          <Select
                            defaultValue={drivingSchoolOptions[0]}
                            value={selectedDrivingSchool}
                            onChange={setSelectedDrivingSchool}
                            options={drivingSchoolOptions}
                            styles={themeStyle}
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="row w-100">
                            <div className="form-group mb-3 col-md-6">
                              <label>Date de réussite du code</label>
                              <input
                                type="date"
                                className="form-control"
                                value={student.date_code}
                                onChange={(e) => handleChange(e, "date_code")}
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>NEPH</label>
                              <input
                                type="text"
                                className="form-control"
                                value={student.neph}
                                onChange={(e) => handleChange(e, "neph")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <Row>
                        <Tab.Container
                          id="docs-tabs"
                          activeKey={student.docsType}
                          onSelect={(type) =>
                            setStudent({
                              ...student,
                              docsType: type,
                            })
                          }
                        >
                          <Col md={3}>
                            <h2>Type de permis</h2>
                            <Nav
                              as="ul"
                              className="flex-column nav-pills mb-3 mt-3"
                            >
                              {Object.keys(typeWithDocs).map((type) => (
                                <Nav.Item key={type}>
                                  <Nav.Link eventKey={type}>{type}</Nav.Link>
                                </Nav.Item>
                              ))}
                            </Nav>
                          </Col>
                          <Col md={9}>
                            <Tab.Content>
                              {Object.entries(typeWithDocs).map(
                                ([type, documents]) => (
                                  <Tab.Pane key={type} eventKey={type}>
                                    <div className="tab-card">
                                      <h3 className="text-center">
                                        Documents à fournir : {type}
                                      </h3>
                                      <div className="uploadSection">
                                        <div className="row w-100">
                                          {documents.map((doc, index) => (
                                            <div
                                              key={doc}
                                              className="col-md-6 col-12"
                                            >
                                              <div className="d-flex w-100">
                                                <label
                                                  htmlFor={doc}
                                                  className={
                                                    docs[doc] !== ""
                                                      ? "btn btn-primary w-100"
                                                      : "btn btn-outline-primary w-100"
                                                  }
                                                >
                                                  {doc}
                                                </label>
                                                <input
                                                  type="file"
                                                  name={doc}
                                                  id={doc}
                                                  className="d-none"
                                                  onChange={(e) =>
                                                    handleDocChange(e, doc)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                )
                              )}
                            </Tab.Content>
                          </Col>
                        </Tab.Container>
                      </Row>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="btn btn-primary"
                    >
                      {isLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Save...</span>
                        </Spinner>
                      ) : (
                        "Créer une fiche élève"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    studentSuccess: state.student.success,
    studentLoading: state.student.loading,
    studentInfo: state.student.student,
    ListOfDrivingSchool: state.drivingSchool.drivingschools,
  };
};

const mapDispatchToProps = {
  addStudent,
  updateStudent,
  getStudent: singleStudent,
  studentList,
  drivingSchoolsList,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddEditStudent);
