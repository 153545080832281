import React from 'react'
import CalendarComponent from './components/Calender'

const Planning = ({studentId, studentInfo}) => {
  return (
    <div>
        <h2>Student Planning</h2>
        <CalendarComponent studentId={studentId} studentInfo={studentInfo} />
    </div>
  )
}

export default Planning
