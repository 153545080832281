import React from "react";
import TabData1 from "./tab/tab1/Tab1";
import TabData2 from "./tab/tab2/Tab2";
import TabData3 from "./tab/tab3/Tab3";
import TabData4 from "./tab/tab4/Tab4";
import TabData5 from "./tab/tab5/Tab5";
import TabData6 from "./tab/tab6/Tab6";
import TabData7 from "./tab/tab7/Tab7";
import TabData8 from "./tab/tab8/Tab8";
import TabData9 from "./tab/tab9/Tab9";
import TabData10 from "./tab/tab10/Tab10";
import TabData11 from "./tab/tab11/Tab11";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";

const Statistics = () => {
  const tabData = [
    {
      name: "Nombre d'inscriptions",
      mean: "Number of Registrations",
      content: <TabData1 />,
    },
    {
      name: "Nombre d'heures vendues à l'unité",
      mean: "Number of hours sold per unit",
      content: <TabData2 />,
    },
    {
      name: "Nombre d'heures placées dans le mois",
      mean: "Number of hours places in the month",
      content: <TabData3 />,
    },

    {
      name: "Nombre d'heures modifiées",
      mean: "Number of hours modified",
      content: <TabData4 />,
    },
    {
      name: "Nombre d'heures annulées",
      mean: "Number of hours canceled",
      content: <TabData5 />,
    },
    {
      name: "Nombre d'heures erreurs de planification",
      mean: "Number of hours errored in planning",
      content: <TabData6 />,
    },
    {
      name: "Heures effectuées par le moniteur",
      mean: "Hours done by instructor",
      content: <TabData7 />,
    },
    {
      name: "Totaux heures de vacances par moniteur",
      mean: "Totals hours holidays by instructor",
      content: <TabData8 />,
    },
    {
      name: "Heures moyennes effectuées par l'instructeur de tous les étudiants",
      mean: "average hours done by instructor of all students",
      content: <TabData9 />,
    },
    {
      name: "Nombre total d'étudiants par instructeur pour faire une liste de souhaits",
      mean: "total students by instructor for make a wishlist",
      content: <TabData10 />,
    },
    {
      name: "Total constat /amendes / réparations par véhicule",
      mean: "Total constat /amendes / repairs by vehicle",
      content: <TabData11 />,
    },
  ];
  return (
    <>
      <div id="statisticSection">
        <Row>
          <Col xl={12}>
            <Card>
              <Card.Body className="cardBody">
                {/* <!-- Nav tabs --> */}
                <div className="default-tab">
                  <Tab.Container
                    defaultActiveKey={tabData[0].name.toLowerCase()}
                  >
                    <Nav as="ul" className="nav-tabs">
                      {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                          <Nav.Link
                            eventKey={data.name.toLowerCase()}
                            className="statsNavLink text-center"
                          >
                            {data.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content className="pt-4">
                      {tabData.map((data, i) => (
                        <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                          {data.content}
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default (Statistics);
