import { OFFERS,OFFERS_SUCCESS,OFFERS_FAIL, ADDOFFERS, ADDOFFERS_SUCCESS, ADDOFFERS_FAIL, DELETE_OFFERS, DELETE_OFFERS_SUCCESS, DELETE_OFFERS_FAIL, UPDATE_OFFERS, UPDATE_OFFERS_SUCCESS, UPDATE_OFFERS_FAIL } from "./actionTypes"

const initialState={
    offers:[],
    addOffer:'',
    loading:false,
    success:false,
    updateOffer:'',
    error:''
}

export const offersReducer=(state=initialState,action)=>{
    switch(action.type){
        case OFFERS: return {
            ...state,
            offers:[],
            loading:true,
            success:false,
            error:''
        }
        case OFFERS_SUCCESS: return {
            ...state,
            offers:action.payload,
            loading:false,
            success:true,
            error:''
        }
        case OFFERS_FAIL : return {
            ...state,
            offers:[],
            success:false,
            error:action.payload,
            loading:false
        }
        case ADDOFFERS: return {
            ...state,
            addOffer:'',
            loading:true,
            success:false,
            error:''
        }
        case ADDOFFERS_SUCCESS:return {
            ...state,
            addOffer:action.payload,
            loading:false,
            success:true,
            error:''
        }
        case ADDOFFERS_FAIL:return {
            ...state,
            addOffer:'',
            loading:false,
            error:action.payload,
            success:false
        }
        case DELETE_OFFERS : return {
            ...state,
            loading: true,
            success: false,
            error:''
        }
        case DELETE_OFFERS_SUCCESS : return {
            ...state,
            loading: false,
            success: true,
            error: '',
        }
        case DELETE_OFFERS_FAIL : return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case UPDATE_OFFERS: return {
            ...state,
            updateOffer: '',
            loading: true,
            success: false,
            error: '',
        }
        case UPDATE_OFFERS_SUCCESS: return {
            ...state,
            updateOffer: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case UPDATE_OFFERS_FAIL: return {
            ...state,
            updateOffer: '',
            loading: false,
            success: false,
            error: action.payload
        }
        default:
            return state
    }
}