import React from 'react';
import { useTable, useFilters, usePagination } from 'react-table';
import './filtering.css';
import { Spinner } from 'react-bootstrap';


export const FilteringTable = ({ headerColumn, datas, isLoading }) => {
	const chackbox = document.querySelectorAll(".bs_exam_topper input");
	const motherChackBox = document.querySelector(".bs_exam_topper_all input");
	const chackboxFun = (type) => {
		for (let i = 0; i < chackbox.length; i++) {
			const element = chackbox[i];
			if (type === "all") {
				if (motherChackBox.checked) {
					element.checked = true;
				} else {
					element.checked = false;
				}
			} else {
				if (!element.checked) {
					motherChackBox.checked = false;
					break;
				} else {
					motherChackBox.checked = true;
				}
			}
		}
	};

	const columns = headerColumn
	const data = datas;
	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
	} = tableInstance


	const { pageIndex } = state


	return (
		<>
			<div className="table-responsive">
				<table {...getTableProps()} className="table dataTable display">
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								<th className="width50">
									<div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
										<input
											type="checkbox"
											className="form-check-input"
											id="checkAll"
											required=""
											onClick={() => chackboxFun("all")}
										/>
										<label
											className="form-check-label"
											htmlFor="checkAll"
										></label>
									</div>
								</th>
								{headerGroup.headers.map(column => (
									<th {...column.getHeaderProps()} style={{ wordWrap: 'break-word' }}>
										{column.render('Header')}
										{column.canFilter ? column.render('Filter') : null}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()} className="" >

						{isLoading ? (
							<tr>
								<td colSpan={headerColumn?.length + 5}>
									<div className="text-center" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '15px' }}>
										<Spinner animation="border" role="status" style={{ borderRadius: '50%' }}>
										</Spinner>
										<span>Loading...</span>
									</div>
								</td>
							</tr>
						) :
							!page.length ? (
								<tr>
									<td
										colSpan={headerColumn?.length + 5}
										style={{ textAlign: "center" }}
									>
										No Records
									</td>
								</tr>
							) : (
								<>
									{
										page.map((row) => {
											prepareRow(row)
											return (
												<tr {...row.getRowProps()}>
													<td>
														<div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
															<input
																type="checkbox"
																className="form-check-input"
																id="customCheckBox2"
																required=""
																onClick={() => chackboxFun()}
															/>
															<label
																className="form-check-label"
																htmlFor="customCheckBox2"
															></label>
														</div>
													</td>
													{row.cells.map((cell) => {
														return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
													})}
												</tr>
											)
										})
									}
									<tr style={{ backgroundColor: "#F44336", color: "white" }} >
										<td colSpan={headerColumn?.length} >Subtotal</td>
										<td>{
											page?.map(row => {
												let final = 0
												row.cells.map(cell => {
													if (cell.column.Header === "Montant") {
														final = cell.value
													} else {
														return 0
													}
												})
												return final
											}).reduce((accumulator, currentValue) => accumulator + (Number(currentValue) || 0), 0)
										}</td>
									</tr>
								</>
							)}
					</tbody>
				</table>
				<div className="d-flex justify-content-between">
					<span>
						Page{' '}
						<strong>
							{pageIndex + 1} of {pageOptions.length}
						</strong>{''}
					</span>
					<span className="table-index">
						Go to page : {' '}
						<input type="number"
							className="ml-2"
							defaultValue={pageIndex + 1}
							onChange={e => {
								const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
								gotoPage(pageNumber)
							}}
						/>
					</span>
				</div>
				<div className="text-center d-flex justify-content-end mb-3">
					<div className="filter-pagination  mt-3">
						<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

						<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
							Previous
						</button>
						<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
							Next
						</button>
						<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
					</div>
				</div>
			</div>
		</>
	)

}
export default FilteringTable;