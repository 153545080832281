import { api } from "../../utils/constants";
import { permissions } from "../../utils/urls";
import { PERMISSIONS, PERMISSIONS_FAILED, PERMISSIONS_SUCCESS } from "./actionTypes";



export const permissionsList = () => async (dispatch) => {
    dispatch({
        type: PERMISSIONS,
    });
    try {
        let response = await api(permissions(), "get", {});
        dispatch({
            type: PERMISSIONS_SUCCESS,
            payload: response?.data?.data,
        });
        return response;
    } catch (error) {
        console.log("fetching permissions error is ===>", error);
        return Promise.reject(
            dispatch({
                type: PERMISSIONS_FAILED,
                payload: error?.response?.data?.message || error?.message,
            })
        );
    }
};
