import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getInstructorWishlistCount } from "../../../../../store/statistics/action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Spinner } from "react-bootstrap";
import { usersList } from "../../../../../store/users/action";
import Select from "react-select";
import { themeStyle } from "../../../../../utils/style";

const Tab10 = ({ instructorWishlistCount, getInstructorWishlistCount, loading, usersData, usersList }) => {
  const [resources, setResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState({})

  useEffect(() => {
    if (usersData?.length) {
      const res = usersData.reduce((result, val) => {
        if (val?.userGroup === "Moniteurs" && val?.userDrivingschool) {
          result.push({
            value: val.id,
            label: val.firstname || val.lastname || val.email,
          });
        }
        return result;
      }, []);
      setResources(res);
    }
  }, [usersData]);

  useEffect(() => {
    if (resources?.length && !selectedResource?.value) {
      setSelectedResource(resources[0])
    }
  }, [resources])

  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    usersList().catch((error) => {
      notifyError(error?.payload);
    });
  }, []);

  useEffect(() => {
    if (selectedResource?.value) {
      getInstructorWishlistCount(selectedResource?.value).catch((error) => {
        notifyError(`While Fetching Instructor Hours Error is: => ${error}`);
      });
    }
  }, [selectedResource])


  return (
    <>
      <ToastContainer />
      <div>
        <div className="col-12">
          <Card>
            <Card.Body>
              {loading && (
                <div
                  className="text-center"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ borderRadius: "50%" }}
                  ></Spinner>
                  <span>Loading...</span>
                </div>
              )}
              {!loading && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: "1rem" }} >
                  <h5>In interdum mauris sit amet porttitor pretium</h5>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }} >
                    <Select
                      options={resources}
                      styles={themeStyle}
                      onChange={setSelectedResource}
                      value={selectedResource}
                    />
                  </div>
                  <div style={{ width: "100%", display: 'flex', justifyContent: 'center', fontSize: "1rem" }} >
                    <div>
                      Nombre total d'étudiants
                      <span style={{ marginLeft: "0.5rem" }} >{instructorWishlistCount?.studentCount || 0}</span>
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    instructorWishlistCount: state?.statistics?.instructorWishlistCount,
    loading: state?.statistics?.loading,
    usersData: state.users.users,
  };
};
const mapDispatchToProps = {
  getInstructorWishlistCount,
  usersList
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab10);
