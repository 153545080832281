export const VEHICLES= 'VEHICLES';
export const VEHICLES_SUCCESS = 'VEHICLES_SUCCESS';
export const VEHICLES_FAILED = 'VEHICLES_FAILED';
export const ADDVEHICLE = 'ADDVEHICLE';
export const ADDVEHICLE_SUCCESS = 'ADDVEHICLE_SUCCESS';
export const ADDVEHICLE_FAILED = 'ADDVEHICLE_FAILED';
export const UPDATEVEHICLE = 'UPDATEVEHICLE';
export const UPDATEVEHICLE_SUCCESS = 'UPDATEVEHICLE_SUCCESS';
export const UPDATEVEHICLE_FAILED = 'UPDATEVEHICLE_FAILED'; 
export const DELETEVEHICLE = 'DELETEVEHICLE'
export const DELETEVEHICLE_SUCCESS = "DELETEVEHICLE_SUCCESS";
export const DELETEVEHICLE_FAILED = 'DELETEVEHICLE_FAILED';
export const SINGLEVEHICLE = 'SINGLEVEHICLE';
export const SINGLEVEHICLE_SUCCESS = 'SINGLEVEHICLE_SUCCESS';
export const SINGLEVEHICLE_FAILED = 'SINGLEVEHICLE_FAILED';
export const VEHICLESTYPE="VEHICLETYPE"
export const VEHICLESTYPE_SUCCESS="VEHICLETYPE_SUCCESS"
export const VEHICLESTYPE_FAILED="VEHICLETYPE_FAILED"