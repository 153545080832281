import { api } from "../../utils/constants";
import { savePassword, users, verify, passwordChange } from "../../utils/urls";
import {
  ADDUSER,
  ADDUSER_FAILED,
  ADDUSER_SUCCESS,
  DELETEUSER,
  DELETEUSER_FAILED,
  DELETEUSER_SUCCESS,
  SINGLEUSER,
  SINGLEUSER_FAILED,
  SINGLEUSER_SUCCESS,
  USERS,
  USERS_FAILED,
  USERS_SUCCESS,
  UPDATEUSER,
  UPDATEUSER_FAILED,
  UPDATEUSER_SUCCESS,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAIL,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from "./actionType";

export const usersList = () => async (dispatch) => {
  dispatch({
    type: USERS,
  });
  try {
    let response = await api(users(), "get", {});
    dispatch({
      type: USERS_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    console.log("fetching users error is ===>", error);
    return Promise.reject(
      dispatch({
        type: USERS_FAILED,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};
export const singleUser = (id) => async (dispatch) => {
  dispatch({
    type: SINGLEUSER,
  });
  try {
    let response = await api(users(id), "get", {});
    dispatch({
      type: SINGLEUSER_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    return Promise.reject(
      dispatch({
        type: SINGLEUSER_FAILED,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};

export const addUser = (data) => async (dispatch) => {
  dispatch({
    type: ADDUSER,
  });
  try {
    let response = await api(users(), "post", data);
    dispatch({
      type: ADDUSER_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    console.log("adding user error is ===>", error);
    return Promise.reject(
      dispatch({
        type: ADDUSER_FAILED,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};

export const updateUser = (id, data) => async (dispatch) => {
  dispatch({
    type: UPDATEUSER,
  });
  try {
    let response = await api(users(id), "put", data);
    dispatch({
      type: UPDATEUSER_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    console.log("updating user error is ==>", error);
    return Promise.reject(
      dispatch({
        type: UPDATEUSER_FAILED,
        payload:
          error?.response?.data?.data?.errors?.[0].message ||
          error?.response?.data?.errors,
      })
    );
  }
};

export const deleteUser = (id) => async (dispatch) => {
  dispatch({
    type: DELETEUSER,
  });
  try {
    let response = await api(users(id), "delete", {});
    console.log("deleting student ===>", response);
    dispatch({
      type: DELETEUSER_SUCCESS,
      payload: response?.data,
    });
    return response;
  } catch (error) {
    console.log("deleting user error is ===>", error);
    return Promise.reject(
      dispatch({
        type: DELETEUSER_FAILED,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};

export const changePassword = (email) => async (dispatch) => {
  dispatch({
    type: VERIFY_EMAIL,
  });
  console.log(email);
  try {
    let response = await api(verify("email"), "post", email);
    dispatch({
      type: VERIFY_EMAIL_SUCCESS,
      payload: response?.data,
    });
    return response;
  } catch (error) {
    console.log("while verifing email error:==>", error);
    return Promise.reject(
      dispatch({
        type: VERIFY_EMAIL_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};

export const confirmOtp = (data) => async (dispatch) => {
  dispatch({
    type: VERIFY_OTP,
  });
  try {
    let response = await api(verify("otp"), "post", data);
    dispatch({
      type: VERIFY_OTP_SUCCESS,
      payload: response?.data,
    });
    return response;
  } catch (error) {
    console.log("while verifing otp error:==>", error);
    return Promise.reject(
      dispatch({
        type: VERIFY_OTP_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};

export const saveNewPassword = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_PASSWORD,
  });
  try {
    let response = await api(savePassword(), "post", data);
    dispatch({
      type: UPDATE_PASSWORD_SUCCESS,
      payload: response?.data,
    });
    return response;
  } catch (error) {
    console.log("while saving new password error:==>", error);
    return Promise.reject(
      dispatch({
        type: UPDATE_PASSWORD_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};

export const passwordUpdate = (data) => async (dispatch) => {
  dispatch({
    type: CHANGE_PASSWORD,
  });
  try {
    let response = await api(passwordChange(), "post", data);
    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: response?.data,
    });
    return response;
  } catch (error) {
    console.log("while changing password error ==>", error);
    return Promise.reject(
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};
