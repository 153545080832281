import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { Spinner } from "react-bootstrap";

const StudentInfo = ({
  studentInfo,
  handleAddComment,
  handlePermit,
  handleCommentDelete,
}) => {
  const [student, setStudent] = useState({});
  const [loadingComment, setLoadingComment] = useState(null);
  const RootToPath =
    process.env.NODE_ENV === "development" ? "http://localhost:5001/" : "/api/";

  useEffect(() => {
    setStudent({
      ...studentInfo,
    });
  }, [studentInfo]);

  const handleDocDownload = (doc) => {
    if (doc.file) {
      const fileURL = `${RootToPath}${doc?.file}`;
      saveAs(fileURL, doc.name);
    }
  };

  let status = student?.status === true ? "yes" : "no";


  const Comment = ({comment, loadingComment, setLoadingComment}) => {

    const deleteComment = async (comment) => {
      setLoadingComment(comment?.id)
      await handleCommentDelete(comment)
      setLoadingComment(null)
    }

    return (
      <li key={comment?.id} style={{ display: 'flex', width: '100%', justifyContent: 'space-between', paddingBottom: '5px' }}>
        <p
          style={{
            margin: 0,
            color: "white",
            fontSize: "14px",
          }}
        >
          {comment.date} - {comment.comment}
        </p>
        <button
          className="btn btn-transparent btn-xs sharp"
          style={{ color: "white", background: 'none' }}
          onClick={() => deleteComment(comment)}
          disabled={comment?.id === loadingComment}
        >
          {
            comment?.id === loadingComment ? 
            <Spinner animation="border" role="status" style={{ borderRadius: '50%' }} size="sm" />
            :
            <i className="fa-solid fa-trash fa-xl"></i>
          }
        </button>
      </li>
    )
  }


  return (
    <>
      <div className="row">
        <div className="form-group mb-3 col-md-6">
          <label>Sexe</label>
          <input
            type="text"
            defaultValue={student.gender}
            className="form-control"
            disabled
          />
        </div>
        <div className="form-group mb-3 col-md-6">
          <label>Email</label>
          <input
            type="text"
            className="form-control"
            defaultValue={student.email}
            disabled
          />
        </div>
        <div className="form-group mb-3 col-md-6">
          <label>Nom</label>
          <input
            type="text"
            className="form-control"
            defaultValue={student.lastname}
            disabled
          />
        </div>
        <div className="form-group mb-3 col-md-6">
          <label>Prénom</label>
          <input
            type="text"
            className="form-control"
            value={student.firstname}
            disabled
          />
        </div>
        <div className="form-group mb-3 col-md-6">
          <label>Telephone mobile</label>
          <input
            type="text"
            className="form-control"
            defaultValue={student.mobile}
            disabled
          />
        </div>
        <div className="form-group mb-3 col-md-6">
          <label>Adresse</label>
          <input
            type="text"
            className="form-control"
            defaultValue={student.address}
            disabled
          />
        </div>
        <div className="form-group mb-3 col-md-4">
          <label>Date de naissance</label>
          <input
            type="text"
            className="form-control"
            defaultValue={student.birthday}
            disabled
          />
        </div>
        <div className="form-group mb-3 col-md-2">
          <label>Département</label>
          <input
            type="text"
            className="form-control"
            defaultValue={student.department}
            disabled
          />
        </div>
        <div className="form-group mb-3 col-md-6">
          <label>Lieu de rencontre</label>
          <input
            type="text"
            className="form-control"
            defaultValue={student.place_meet}
            disabled
          />
        </div>
        <div className="form-group mb-3 col-md-6">
          <label>Lieu de naissance</label>
          <input
            type="text"
            className="form-control"
            defaultValue={student.place_birthday}
            disabled
          />
        </div>
        <div className="form-group mb-3 col-md-2">
          <label>Actif</label>
          <input type="text" className="form-control" value={status} disabled />
        </div>
      </div>
      <div className="row mt-5 w-100">
        <h3>Autres informations</h3>
        <div className="d-flex flex-column">
          <div className="row w-100 mb-2">
            <div className="form-group mb-3 col-md-4">
              <label>Nom de l'auto-école</label>
              <input
                type="text"
                className="form-control"
                defaultValue={student.drivingSchool}
                disabled
              />
            </div>
            <div className="col-md-8">
              <div className="row w-100">
                <div className="form-group mb-3 col-md-6">
                  <label>Date de réussite du code</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={student.date_code}
                    disabled
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>NEPH</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={student.neph}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 w-100">
        <div className="col-lg-6 col-12">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <h3>Commentaires</h3>
              <button
                className="btn btn-transparent"
                style={{ fontSize: "20px", color: "white", padding: 0 }}
                onClick={() => handleAddComment()}
              >
                <i className="fa-solid fa-circle-plus"></i>
              </button>
            </div>
            <div className="d-flex mt-1 w-100">
              <ul style={{ listStyleType: "none", width: '100%' }}>
                {!student?.comments?.length && (
                  <li>
                    <p style={{ margin: 0, color: "white", fontSize: "14px" }}>
                      no comments for student...
                    </p>
                  </li>
                )}
                {student?.comments?.length &&
                  student?.comments?.map((comment, index) => {
                    return (
                      <Comment comment={comment} key={index} setLoadingComment={setLoadingComment} loadingComment={loadingComment} />
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <h3>Permis</h3>
              <button
                className="btn btn-transparent"
                style={{ fontSize: "20px", color: "white", padding: 0 }}
                onClick={() => handlePermit()}
              >
                <i className="fa-solid fa-circle-plus"></i>
              </button>
            </div>
            <div className="d-flex mt-1 w-100">
              <ul style={{ listStyleType: "none" }}>
                <li
                  className="d-flex"
                  style={{ color: "white", fontSize: "16px" }}
                >
                  <p className="ms-2">{student?.licences?.name} </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 w-100">
        <div className="d-flex flex-column">
          <h3>Documents</h3>
          <ul style={{ listStyleType: "none" }}>
            {!student?.documents?.length && (
              <li className="d-flex align-items-center justify-content-between w-75">
                <p style={{ margin: 0, color: "white", fontSize: "14px" }}>
                  no document for student...
                </p>
              </li>
            )}
            {student?.documents?.length &&
              student?.documents?.map((doc) => {
                return (
                  <li
                    className="d-flex align-items-center justify-content-between w-75"
                    key={doc.id}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px", fontSize: "14px", color: "white" }}
                    >
                      <i className="fa-solid fa-file"></i>
                      <p
                        style={{ margin: 0, color: "white", fontSize: "14px" }}
                      >
                        {doc.name}
                      </p>
                    </div>
                    <button
                      className="btn btn-transparent"
                      style={{ fontSize: "16px", color: "white", padding: "0" }}
                      onClick={() => handleDocDownload(doc)}
                    >
                      <i className="fa-solid fa-circle-down"></i>
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default StudentInfo;
