export const STUDENTS= 'STUDENTS';
export const STUDENTS_SUCCESS = 'STUDENTS_SUCCESS';
export const STUDENTS_FAILED = 'STUDENTS_FAILED';
export const ADDSTUDENT = 'ADDSTUDENT';
export const ADDSTUDENT_SUCCESS = 'ADDSTUDENT_SUCCESS';
export const ADDSTUDENT_FAILED = 'ADDSTUDENT_FAILED';
export const UPDATESTUDENT = 'UPDATESTUDENT';
export const UPDATESTUDENT_SUCCESS = 'UPDATESTUDENT_SUCCESS';
export const UPDATESTUDENT_FAILED = 'UPDATESTUDENT_FAILED'; 
export const DELETESTUDENT = 'DELETESTUDENT'
export const DELETESTUDENT_SUCCESS = "DELETESTUDENT_SUCCESS";
export const DELETESTUDENT_FAILED = 'DELETESTUDENT_FAILED';
export const SINGLESTUDENT = 'SINGLESTUDENT';
export const SINGLESTUDENT_SUCCESS = 'SINGLESTUDENT_SUCCESS';
export const SINGLESTUDENT_FAILED = 'SINGLESTUDENT_FAILED';
export const ADDSTUDENTCOMMENT = 'ADDSTUDENTCOMMENT';
export const ADDSTUDENTCOMMENT_SUCCESS = 'ADDSTUDENTCOMMENT_SUCCESS';
export const ADDSTUDENTCOMMENT_FAILED = 'ADDSTUDENTCOMMENT_FAILED';
export const DELETESTUDENTCOMMENT = 'DELETESTUDENTCOMMENT';
export const DELETESTUDENTCOMMENT_SUCCESS = 'DELETESTUDENTCOMMENT_SUCCESS';
export const DELETESTUDENTCOMMENT_FAILED = 'DELETESTUDENTCOMMENT_FAILED';
export const ADDSTUDENTFORMULA = 'ADDSTUDENTFORMULA';
export const ADDSTUDENTFORMULA_SUCCESS = 'ADDSTUDENTFORMULA_SUCCESS';
export const ADDSTUDENTFORMULA_FAILED = 'ADDSTUDENTFORMULA_FAILED';
export const ADDSTUDENTPAYMENT = 'ADDSTUDENTPAYMENT';
export const ADDSTUDENTPAYMENT_SUCCESS = 'ADDSTUDENTPAYMENT_SUCCESS';
export const ADDSTUDENTPAYMENT_FAILED = 'ADDSTUDENTPAYMENT_FAILED';
export const ADDSTUDENTSKILL = 'ADDSTUDENTSKILL';
export const ADDSTUDENTSKILL_SUCCESS = 'ADDSTUDENTSKILL_SUCCESS';
export const ADDSTUDENTSKILL_FAILED = 'ADDSTUDENTSKILL_FAILED';
export const DELETESTUDENTFORMULA = 'DELETESTUDENTFORMULA';
export const DELETESTUDENTFORMULA_SUCCESS = 'DELETESTUDENTFORMULA_SUCCESS';
export const DELETESTUDENTFORMULA_FAILED = 'DELETESTUDENTFORMULA_FAILED';
export const UPDATESTUDENT_LICENCES = 'UPDATESTUDENT_LICENCES';
export const UPDATESTUDENT_LICENCES_SUCCESS = 'UPDATESTUDENT_LICENCES_SUCCESS';
export const UPDATESTUDENT_LICENCES_FAILED = 'UPDATESTUDENT_LICENCES_FAILED';
export const STUDENT_PLANNING = 'STUDENT_PLANNING';
export const STUDENT_PLANNING_SUCCESS = 'STUDENT_PLANNING_SUCCESS';
export const STUDENT_PLANNING_FAILED = 'STUDENT_PLANNING_FAILED';
