import axios from "axios";
import { baseUrl } from "./urls";
import moment from "moment";

const Axios = axios.create({
    baseURL: baseUrl,
})

export const api = async (url, type, data, headers={}, options = {}) => {
    let requestTypes = ['get', 'post', 'put', 'delete']
    let requestType = requestTypes.find((reqType) => { return reqType === type })
    let reqHeaders = headers;
    let authToken = localStorage.getItem('userDetails');
    if (authToken) {
        reqHeaders['authorization'] = `Bearer ${JSON.parse(authToken).accessToken}`
    }
    if (requestType) {
        try {
            const response = await Axios({ method: requestType, url: url, data: data, headers: reqHeaders, ...options })
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    } else {
        return Promise.reject("invalid request type")
    }
}

export const utcToLocal = (utcDateString) => {
    let utcMoment = moment.utc(utcDateString);
    let localMoment = utcMoment.local();
    const localDateString = localMoment.format('YYYY-MM-DD HH:mm:ss');
    return localDateString
}

export function getYearsArray(targetYear) {
    const currentYear = moment().year();
    // const targetYear = 2013;
    const years = [];

    for (let year = currentYear; year >= targetYear; year--) {
        years.push(year);
    }

    return years;
}

export function getDaysInMonth(month, year) {
    const momentObj = moment(`${year}-${month}`, "YYYY-MM");
    return Array.from({ length: momentObj.daysInMonth() }, (_, index) => index + 1);
}