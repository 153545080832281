import { DEBITS, DEBITS_FAIL, DEBITS_SUCCESS, SENDMAIL, SENDMAIL_FAIL, SENDMAIL_SUCCESS } from "./actionTypes";

let initialState = {
    debits: [],
    message: '',
    loading: false,
    success: false,
    error: ''
}
export const DebitReducer = (state = initialState, action) => {
    switch (action.type) {
        case DEBITS: return {
            ...state,
            debits: [],
            loading: true,
            success: false,
            error: ''
        }
        case DEBITS_SUCCESS: return {
            ...state,
            debits: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case DEBITS_FAIL: return {
            ...state,
            loading: false,
            error: action.payload,
            success: false
        }
        case SENDMAIL: return {
            ...state,
            message: '',
            loading: true,
            success: false,
            error: '',
        }
        case SENDMAIL_SUCCESS: return {
            ...state,
            message: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case SENDMAIL_FAIL: return {
            ...state,
            message: '',
            loading: false,
            success: false,
            error: action.payload,
        }
        default:
            return state;
    }
}