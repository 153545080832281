import { api } from "../../utils/constants";
import { statistics } from "../../utils/urls";
import {
  CANCLEDHOURS,
  CANCLEDHOURS_FAIL,
  CANCLEDHOURS_SUCCESS,
  ERROREDHOURS,
  ERROREDHOURS_FAIL,
  ERROREDHOURS_SUCCESS,
  HOURS,
  HOURS_FAIL,
  HOURS_SUCCESS,
  INSTRUCTORABSENTHOURS,
  INSTRUCTORABSENTHOURS_FAIL,
  INSTRUCTORABSENTHOURS_SUCCESS,
  INSTRUCTORAVERAGEHOURS,
  INSTRUCTORAVERAGEHOURS_FAIL,
  INSTRUCTORAVERAGEHOURS_SUCCESS,
  INSTRUCTORHOURS,
  INSTRUCTORHOURS_FAIL,
  INSTRUCTORHOURS_SUCCESS,
  INSTRUCTORVEHICLESTATS,
  INSTRUCTORVEHICLESTATS_FAIL,
  INSTRUCTORVEHICLESTATS_SUCCESS,
  INSTRUCTORWISHLISTCOUNT,
  INSTRUCTORWISHLISTCOUNT_FAIL,
  INSTRUCTORWISHLISTCOUNT_SUCCESS,
  MODIFIEDHOURS,
  MODIFIEDHOURS_FAIL,
  MODIFIEDHOURS_SUCCESS,
  PACKAGES,
  PACKAGES_FAIL,
  PACKAGES_SUCCESS,
  PLANNINGHOURS,
  PLANNINGHOURS_FAIL,
  PLANNINGHOURS_SUCCESS,
} from "./actionTypes";

export const getTotalPackages = () => async (dispatch) => {
  dispatch({
    type: PACKAGES,
  });
  try {
    let response = await api(statistics("studentCount"), "get", {});
    dispatch({
      type: PACKAGES_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    console.log("fetching total selled packages error is ==>", error);
    return Promise.reject(
      dispatch({
        type: PACKAGES_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};

export const getTotalHours = () => async (dispatch) => {
  dispatch({
    type: HOURS,
  });
  try {
    let response = await api(statistics("totalhour"), "get", {});
    dispatch({
      type: HOURS_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    console.log("fetching total selled package hours error is ==>", error);
    return Promise.reject(
      dispatch({
        type: HOURS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};

export const getPlanningHours = () => async (dispatch) => {
  dispatch({
    type: PLANNINGHOURS
  })
  try {
    let response = await api(statistics("planninghours"), "get", {});
    dispatch({
      type: PLANNINGHOURS_SUCCESS,
      payload: response?.data?.data,
    })
    return response
  } catch (error) {
    console.log("fetching planning hours error is ==>", error);
    return Promise.reject(
      dispatch({
        type: PLANNINGHOURS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    )
  }
}

export const getModifiedHours = () => async (dispatch) => {
  dispatch({
    type: MODIFIEDHOURS
  })
  try {
    let response = await api(statistics("modifiedHours"), "get", {});
    dispatch({
      type: MODIFIEDHOURS_SUCCESS,
      payload: response?.data?.data,
    })
    return response
  } catch (error) {
    console.log("fetching modified hours error is ==>", error);
    return Promise.reject(
      dispatch({
        type: MODIFIEDHOURS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    )
  }
}

export const getCancledHours = () => async (dispatch) => {
  dispatch({
    type: CANCLEDHOURS
  })
  try {
    let response = await api(statistics("cancledHours"), "get", {});
    dispatch({
      type: CANCLEDHOURS_SUCCESS,
      payload: response?.data?.data,
    })
    return response
  } catch (error) {
    console.log("fetching cancled hours error is ==>", error);
    return Promise.reject(
      dispatch({
        type: CANCLEDHOURS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    )
  }
}

export const getErroredHours = () => async (dispatch) => {
  dispatch({
    type: ERROREDHOURS
  })
  try {
    let response = await api(statistics("erroredHours"), "get", {});
    dispatch({
      type: ERROREDHOURS_SUCCESS,
      payload: response?.data?.data,
    })
    return response
  } catch (error) {
    console.log("fetching errored hours error is ==>", error);
    return Promise.reject(
      dispatch({
        type: ERROREDHOURS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    )
  }
}

export const getInstructorHours = (id) => async (dispatch) => {
  dispatch({
    type: INSTRUCTORHOURS
  })
  try {
    let response = await api(statistics(`instructorHours?instructorId=${id}`), "get", {});
    dispatch({
      type: INSTRUCTORHOURS_SUCCESS,
      payload: response?.data?.data,
    })
    return response
  } catch (error) {
    console.log("fetching instructor hours error is ==>", error);
    return Promise.reject(
      dispatch({
        type: INSTRUCTORHOURS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    )
  }
}

export const getInstructorAbsentHours = (id) => async (dispatch) => {
  dispatch({
    type: INSTRUCTORABSENTHOURS
  })
  try {
    let response = await api(statistics(`instructorAbsent?instructorId=${id}`), "get", {});
    dispatch({
      type: INSTRUCTORABSENTHOURS_SUCCESS,
      payload: response?.data?.data,
    })
    return response
  } catch (error) {
    console.log("fetching instructor absent hours error is ==>", error);
    return Promise.reject(
      dispatch({
        type: INSTRUCTORABSENTHOURS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    )
  }
}

export const getInstructorAverageHours = (id) => async (dispatch) => {
  dispatch({
    type: INSTRUCTORAVERAGEHOURS
  })
  try {
    let response = await api(statistics(`instructorAverage?instructorId=${id}`), "get", {});
    dispatch({
      type: INSTRUCTORAVERAGEHOURS_SUCCESS,
      payload: response?.data?.data,
    })
    return response
  } catch (error) {
    console.log("fetching instructor average hours error is ==>", error);
    return Promise.reject(
      dispatch({
        type: INSTRUCTORAVERAGEHOURS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    )
  }
}

export const getInstructorWishlistCount = (id) => async (dispatch) => {
  dispatch({
    type: INSTRUCTORWISHLISTCOUNT
  })
  try {
    let response = await api(statistics(`instructorWishlist?instructorId=${id}`), "get", {});
    dispatch({
      type: INSTRUCTORWISHLISTCOUNT_SUCCESS,
      payload: response?.data?.data,
    })
    return response
  } catch (error) {
    console.log("fetching instructor wishlist count error is ==>", error);
    return Promise.reject(
      dispatch({
        type: INSTRUCTORWISHLISTCOUNT_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    )
  }
}

export const getInstructorVehicleStats = (id) => async (dispatch) => {
  dispatch({
    type: INSTRUCTORVEHICLESTATS
  })
  try {
    let response = await api(statistics(`instructorVehicleStats?instructorId=${id}`), "get", {});
    dispatch({
      type: INSTRUCTORVEHICLESTATS_SUCCESS,
      payload: response?.data?.data,
    })
    return response
  } catch (error) {
    console.log("fetching instructor vehicle stats error is ==>", error);
    return Promise.reject(
      dispatch({
        type: INSTRUCTORVEHICLESTATS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    )
  }
}