import React, { useState, useEffect } from "react";
import FilteringTable from "./components/FilteringTable";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import {
  drivingSchoolsList,
  addDrivingSchool,
  updateDrivingSchool,
  deleteDrivingSchool,
} from "../../../../store/drivingSchools/action";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
// import PageTitle from "../../../layouts/PageTitle"
import "react-toastify/dist/ReactToastify.css";

const initState = {
  name: "",
  start_date: "",
  valid_date: "",
  enabled: false,
};

const DrivingSchools = (props) => {
  const {
    list,
    loading,
    drivingSchoolsList,
    addDrivingSchool,
    updateDrivingSchool,
    deleteDrivingSchool,
  } = props;
  const [modalCentered, setModalCentered] = useState(false);
  const [drivingSchoolState, setDrivingSchoolState] = useState({
    ...initState,
  });
  const [selectedDrivingSchoolId, setSelectedDrivingSchoolId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [drivingSchoolData, setDrivingSchoolData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let schoolData = {};
      if (isUpdate) {
        schoolData = {
          id: selectedDrivingSchoolId,
          name: drivingSchoolState.name,
          start_date: new Date(drivingSchoolState.start_date) || "",
          valid_date: new Date(drivingSchoolState.valid_date) || "",
          enabled: drivingSchoolState.enabled,
        };
      } else {
        schoolData = {
          name: drivingSchoolState.name,
          start_date: new Date(drivingSchoolState.start_date) || "",
          valid_date: new Date(drivingSchoolState.valid_date) || "",
          enabled: drivingSchoolState.enabled,
        };
      }
      setIsLoading(true);
      if (isUpdate) {
        await updateDrivingSchool(selectedDrivingSchoolId, schoolData);
        notifySuccess("Update SuccessFully");
      } else {
        await addDrivingSchool(schoolData);
        notifySuccess("Added SuccessFully");
      }
      setModalCentered(false);
      await dataLoading();
      setDrivingSchoolState({ ...initState });
    } catch (error) {
      console.log(error.payload);
      notifyError("Driving School Can't Added");
    } finally {
      setIsLoading(false);
      setIsUpdate(false);
      setSelectedDrivingSchoolId(null);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    setDrivingSchoolState({
      ...initState,
    });
    setModalCentered(false);
    setSelectedDrivingSchoolId(null);
  };

  const openDeleteModal = (recordId) => {
    setSelectedDrivingSchoolId(recordId);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setSelectedDrivingSchoolId(0);
  };
  const openEditModal = (record) => {
    setModalCentered(true);
    setSelectedDrivingSchoolId(record.id);
    setDrivingSchoolState({
      ...drivingSchoolState,
      name: record?.name,
      start_date: moment(record?.start_date).format("YYYY-MM-DD"),
      valid_date: moment(record?.valid_date).format("YYYY-MM-DD"),
      enabled: record?.enabled,
    });
    setIsUpdate(true);
  };

  useEffect(() => {
    dataLoading();
  }, []);

  const dataLoading = async () => {
    try {
      return await drivingSchoolsList();
    } catch (error) {
      notifyError(error?.payload || error);
    }
  };

  useEffect(() => {
    list?.length > 0 ? setDrivingSchoolData(list) : setDrivingSchoolData([]);
    setIsLoading(loading);
  }, [list, loading]);

  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifySuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleDelete = async (id) => {
    try {
      await deleteDrivingSchool(id);
      setIsLoading(true);
      notifySuccess("Deleted SuccessFully");
      await dataLoading();
    } catch (error) {
      console.log(error, "<== while deleting driving school error");
      notifyError("Driving School Can't Deleted");
    } finally {
      setIsLoading(false);
      setSelectedDrivingSchoolId(0);
    }
  };

  const confirmDelete = async () => {
    if (selectedDrivingSchoolId !== 0) {
      await handleDelete(selectedDrivingSchoolId);
      closeDeleteModal();
    }
  };

  const COLUMNS = [
    {
      Header: "Nom",
      Footer: "Nom",
      accessor: "name",
    },
    {
      Header: "Date d'expiration",
      Footer: "Date d'expiration",
      accessor: "valid_date",
      Cell: ({ value }) => {
        return value ? moment(value).format("DD/MM/YYYY") : "";
      },
    },
    {
      Header: "Éditer",
      Footer: "Éditer",
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-transparent btn-xs sharp me-1"
            // style={{ color: "white" }}
            onClick={() => {
              return openEditModal(row.original);
            }}
          >
            <i className="fa-solid fa-pencil fa-2xl"></i>
          </button>
        );
      },
    },
    {
      Header: "Supprimer",
      Footer: "Supprimer",
      Cell: ({ row }) => {
        const recordId = row.original.id;
        return (
          <button
            className="btn btn-transparent btn-xs sharp me-1"
            // style={{ color: "white" }}
            onClick={() => openDeleteModal(recordId)}
          >
            <i className="fa-solid fa-trash fa-2xl"></i>
          </button>
        );
      },
    },
  ];

  return (
    <div>
      <ToastContainer />
      <div className="d-flex">
        <button
          className="btn btn-primary"
          onClick={() => setModalCentered(true)}
        >
          Ajouter une auto-école
        </button>
      </div>
      <div className="card mt-3">
        <div className="card-header">
          <h3>Auto-écoles</h3>
        </div>
        <div className="card-body">
          <FilteringTable
            datas={drivingSchoolData}
            headerColumn={COLUMNS}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Modal
        className="fade"
        show={modalCentered}
        onHide={() => setModalCentered(false)}
      >
        <Modal.Header closeButton onClick={handleClose}></Modal.Header>
        <Modal.Body>
          <div className="basic-form">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div className="form-group mb-3 col-md-12">
                  <label>Nom</label>
                  <input
                    type="text"
                    className="form-control"
                    value={drivingSchoolState.name}
                    onChange={(e) => {
                      e.preventDefault();
                      setDrivingSchoolState({
                        ...drivingSchoolState,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Date de début</label>
                  <input
                    type="date"
                    className="form-control"
                    value={drivingSchoolState.start_date}
                    onChange={(e) => {
                      e.preventDefault();
                      setDrivingSchoolState({
                        ...drivingSchoolState,
                        start_date: moment(e.target.value).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Date de fin validité</label>
                  <input
                    type="date"
                    className="form-control"
                    value={drivingSchoolState.valid_date}
                    onChange={(e) => {
                      e.preventDefault();
                      setDrivingSchoolState({
                        ...drivingSchoolState,
                        valid_date: moment(e.target.value).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-check custom-checkbox checkbox-success check-lg">
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    id="enabled"
                    name="enabled"
                    checked={drivingSchoolState.enabled}
                    onChange={(e) => {
                      setDrivingSchoolState({
                        ...drivingSchoolState,
                        enabled: e.target.checked,
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor="enabled">
                    Actif
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={(e) => handleSubmit(e)}
                >
                  {isLoading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Enregistrement...</span>
                    </div>
                  ) : isUpdate ? (
                    "Mettre à jour une auto-école"
                  ) : (
                    "Créer une auto-école"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={deleteModal}
        onHide={closeDeleteModal}
        className="fade"
        style={{ position: "absolute", top: "25%" }}
      >
        <Modal.Header>
          <Modal.Title>Confirmation de la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Voulez-vous vraiment supprimer cette auto-école ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closeDeleteModal}>
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.drivingSchool.drivingschools,
    loading: state.drivingSchool.loading,
  };
};

const mapDispatchToProps = {
  drivingSchoolsList,
  updateDrivingSchool,
  addDrivingSchool,
  deleteDrivingSchool,
};
export default connect(mapStateToProps, mapDispatchToProps)(DrivingSchools);
