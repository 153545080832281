import { api } from "../../utils/constants"
import { drivingSchool } from "../../utils/urls"
import { ADDDRIVINGSCHOOL, ADDDRIVINGSCHOOL_FAILED, ADDDRIVINGSCHOOL_SUCCESS, DELETEDRIVINGSCHOOL, DELETEDRIVINGSCHOOL_FAILED, DELETEDRIVINGSCHOOL_SUCCESS, DRIVINGSCHOOLS, DRIVINGSCHOOLS_FAILED, DRIVINGSCHOOLS_SUCCESS, SINGLEDRIVINGSCHOOL, SINGLEDRIVINGSCHOOL_FAILED, SINGLEDRIVINGSCHOOL_SUCCESS, UPDATEDRIVINGSCHOOL, UPDATEDRIVINGSCHOOL_FAILED, UPDATEDRIVINGSCHOOL_SUCCESS } from "./actionType"



export const drivingSchoolsList = () => async dispatch => {
    dispatch({
        type: DRIVINGSCHOOLS,
    })
    try {
        let response = await api(drivingSchool(), 'get', {})
        dispatch({
            type: DRIVINGSCHOOLS_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("fetching drivingschools error is ===>", error);
        return Promise.reject(dispatch({
            type: DRIVINGSCHOOLS_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}
export const singleDrivingSchool = (id) => async dispatch => {
    dispatch({
        type: SINGLEDRIVINGSCHOOL,
        
    })
    try {
        let response = await api(drivingSchool(id), 'get', {})
        dispatch({
            type: SINGLEDRIVINGSCHOOL_SUCCESS,
            payload: response?.data?.data[0]
        })
        return response
    } catch(error) {
        return Promise.reject(dispatch({
            type: SINGLEDRIVINGSCHOOL_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const addDrivingSchool = (data) => async dispatch => {
    dispatch({
        type: ADDDRIVINGSCHOOL,
    })
    try {
        let response = await api(drivingSchool(), 'post', data);
        dispatch({
            type: ADDDRIVINGSCHOOL_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("adding drivingschool error is ===>", error);
        return Promise.reject(dispatch({
            type: ADDDRIVINGSCHOOL_FAILED,
            payload: error?.response?.data?.message || error?.message
        })      )  
    }
}

export const updateDrivingSchool = (id, data) => async dispatch => {
    dispatch({
        type: UPDATEDRIVINGSCHOOL,
    })
    try {
        let response = await api(drivingSchool(id), 'put', data);
        dispatch({
            type: UPDATEDRIVINGSCHOOL_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("updating drivingschool error is ==>", error);
        return Promise.reject(dispatch({
            type: UPDATEDRIVINGSCHOOL_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))   
    }
}

export const deleteDrivingSchool = (id) => async dispatch => {
    dispatch({
        type: DELETEDRIVINGSCHOOL,
    })
    try {
        let response = await api(drivingSchool(id), 'delete', {});
        dispatch({
            type: DELETEDRIVINGSCHOOL_SUCCESS,
            payload: response?.data,
        })
        return response
    } catch (error) {
        console.log("deleting drivingschool error is ===>", error);
        return Promise.reject(dispatch({
            type: DELETEDRIVINGSCHOOL_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}