import React, { useEffect, useState } from "react";
import FilteringTable from "./components/FilteringTable";
import { Button, Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import {
  usersList,
  addUser,
  updateUser,
  deleteUser,
  singleUser,
} from "../../../../store/users/action";
import { drivingSchoolsList } from "../../../../store/drivingSchools/action";
import { rolesList } from "../../../../store/roles/action";
import { permissionsList } from "../../../../store/permissions/action";
import { themeMultiSelectStyle, themeStyle } from "../../../../utils/style";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let initState = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  confirmPassword: "",
  enabled: true,
};
let initdrivingSchoolOptions = [];
let initPermissionsOptions = [];

const Users = ({
  list,
  loading,
  sUser,
  drivingSchoolsList,
  rolesList,
  permissionsList,
  drivingSchoolsOptions,
  rolesOptions,
  permissionOptions,
  usersList,
  addUser,
  updateUser,
  deleteUser,
  singleUser,
  loginUser
}) => {
  const [modalCentered, setModalCentered] = useState(false);
  const [selectedDrivingSchool, setSelectedDrivingSchool] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [user, setUser] = useState({ ...initState });
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [drivingSchoolOptions, setDrivingSchoolOptions] = useState([
    ...initdrivingSchoolOptions,
  ]);
  const [userPermissionsOptions, setUserPermissionsOptions] = useState([
    ...initPermissionsOptions,
  ]);
  const [userGroupOptions, setUserGroupOptions] = useState([]);

  useEffect(() => {
    if (drivingSchoolsOptions.length === 0) {
      drivingSchoolsList();
    }
    if (rolesOptions.length === 0) {
      rolesList();
    }
    if (permissionOptions.length === 0) {
      permissionsList();
    }
  }, []);

  useEffect(() => {
    initPermissionsOptions = permissionOptions?.map((permission) => {
      return {
        value: { id: permission.id, type: permission.type },
        label: permission.type,
      };
    });
    let roleOpt = rolesOptions?.map((role) => {
      return {
        value: { id: role.id, name: role.name },
        label: role.name,
      };
    });
    initdrivingSchoolOptions = drivingSchoolsOptions?.map((ds) => {
      return {
        value: { id: ds.id, name: ds.name },
        label: ds.name,
      };
    });
    setUserGroupOptions(roleOpt);
    setDrivingSchoolOptions(initdrivingSchoolOptions);
    setUserPermissionsOptions(initPermissionsOptions);
  }, [drivingSchoolsOptions, rolesOptions, permissionOptions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!isUpdate) {
        if (user.password !== user.confirmPassword) {
          notifyError("password didn't matched, please add correct password");
          return;
        }
        let data = {
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          password: user.password,
          permissions: (loginUser?.usersRole?.name !== 'Secrétaires') ? selectedPermission?.map(
            (permission) => permission.value
          ) : [],
          role_id: (loginUser?.usersRole?.name !== 'Secrétaires') ? selectedGroup?.value?.id : 1,
          drivingSchoolUser: (loginUser?.usersRole?.name !== 'Secrétaires') ? selectedDrivingSchool?.map((school) => ({
            ...school.value,
          })) : loginUser?.userDrivingschool?.drivingschool_id,
          enabled: user.enabled,
        };
        await addUser(data);
      } else {
        let updatedData = {
          id: sUser.id,
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          role_id: (loginUser?.usersRole?.name !== 'Secrétaires') ? selectedGroup?.value?.id : 1,
          enabled: user.enabled,
        };
        let newAddSchool = [];
        let deletedSchool = [];
        let newAddPermission = [];
        let deletedPermission = [];
        const existingSchool = sUser?.userDrivingschool || [];
        const existingPermission = sUser?.userPermissions || [];
        selectedDrivingSchool.forEach((school) => {
          if (existingSchool.length) {
            let isSchoolExist = existingSchool.some(
              (esch) => esch.drivingschool_id === school?.value?.id
            );
            if (!isSchoolExist) {
              newAddSchool.push({ ...school.value });
            }
          } else {
            newAddSchool.push({ ...school.value });
          }
        });

        existingSchool.forEach((exSchool) => {
          let isSchoolDeleted = !selectedDrivingSchool.some(
            (school) => school.value.id === exSchool.drivingschool_id
          );
          if (isSchoolDeleted) {
            deletedSchool.push(exSchool.id);
          }
        });

        selectedPermission.forEach((permission) => {
          if (existingPermission.length) {
            let isPermissionExist = existingPermission.some(
              (exPer) => exPer.permission_id === permission.value.id
            );
            if (!isPermissionExist) {
              newAddPermission.push({ ...permission.value });
            }
          } else {
            newAddPermission.push({ ...permission.value });
          }
        });
        existingPermission.forEach((exPer) => {
          let isPermissionDeleted = !selectedPermission.some(
            (permission) => permission.value.id === exPer.permission_id
          );
          if (isPermissionDeleted) {
            deletedPermission.push(exPer.id);
          }
        });

        updatedData = {
          ...updatedData,
          newAddSchool: newAddSchool,
          newAddPermission: newAddPermission,
          deletedSchool: deletedSchool,
          deletedPermission: deletedPermission,
        };
        await updateUser(sUser.id, updatedData);
      }
      setIsLoading(true);
      await dataLoading();
      setUser({ ...initState });
      setSelectedPermission([]);
      setSelectedDrivingSchool(null);
      setSelectedGroup(null);
      setSelectedUserId(null);
      setIsUpdate(false);
      setModalCentered(false);
      isUpdate
        ? notifySuccess("User successfully Upated")
        : notifySuccess("User successfully added");
    } catch (error) {
      console.log(error);
      // alert(error);
      isUpdate
        ? notifyError(`User can't be updated, Error: ${error?.payload}`)
        : notifyError(`User can't be added, Error: ${error?.payload}`);
    } finally {
      setIsLoading(false);
    }
  };

  const openDeleteModal = (recordId) => {
    setSelectedUserId(recordId);
    setShowDeleteModal(true);
  };

  const openEditModal = async (recordId) => {
    setModalCentered(true);
    setSelectedUserId(recordId);
    setIsUpdate(true);
    try {
      await singleUser(recordId);
      setModalCentered(true);
    } catch (error) {
      console.log(error);
      notifyError(error?.payload);
    }
  };

  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifySuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleDelete = async (id) => {
    try {
      await deleteUser(id);
      setIsLoading(true);
      await dataLoading();
      notifySuccess("User Deleted Successfully");
    } catch (error) {
      console.log(error, "<== while deleting user error");
      notifyError(`User Can't Delete, Error: ${error?.payload}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setUser({ ...initState });
    setSelectedPermission([]);
    setSelectedDrivingSchool(null);
    setSelectedGroup(null);
    setSelectedUserId(null);
    setIsUpdate(false);
    setModalCentered(false);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedUserId(null);
  };

  const confirmDelete = async () => {
    if (selectedUserId) {
      await handleDelete(selectedUserId);
      closeDeleteModal();
    }
  };

  useEffect(() => {
    if (isUpdate && selectedUserId && selectedUserId === sUser?.id) {
      setUser({
        ...initState,
        firstname: sUser?.firstname,
        lastname: sUser?.lastname,
        email: sUser?.email,
        enabled: sUser?.enabled,
      });
      if (sUser.userPermissions.length) {
        let permissions = sUser?.userPermissions?.map((up) => {
          let matchedPermission = userPermissionsOptions?.filter(
            (permissionOp) => permissionOp.value.id === up?.permissionUser?.id
          )[0];
          return matchedPermission;
        });
        setSelectedPermission(permissions);
      }
      if (sUser.role_id) {
        let group = userGroupOptions?.filter(
          (group) => group.value.id === sUser?.role_id
        )[0];
        setSelectedGroup(group);
      }
      if (sUser.userDrivingschool) {
        let school = sUser.userDrivingschool.map((drivingSchool) => {
          const matchedSchool = drivingSchoolOptions.filter(
            (ds) => ds.value.id === drivingSchool?.drivingSchoolUser?.id
          )[0];
          return matchedSchool;
        });
        setSelectedDrivingSchool(school);
      }
    }
  }, [sUser, selectedUserId, isUpdate]);

  useEffect(() => {
    dataLoading();
  }, []);

  const dataLoading = async () => {
    try {
      return await usersList();
    } catch (error) {
      notifyError(error?.payload);
    }
  };

  useEffect(() => {
    list?.length > 0 ? setUsersData(list) : setUsersData([]);
    setIsLoading(loading);
  }, [list, loading]);

  useEffect(() => {
    if (selectedDrivingSchool?.length) {
      const updatedDrivingSchoolOptions = initdrivingSchoolOptions.filter(
        (option) => !selectedDrivingSchool.includes(option)
      );
      setDrivingSchoolOptions(updatedDrivingSchoolOptions);
    } else {
      setDrivingSchoolOptions([...initdrivingSchoolOptions]);
    }
  }, [selectedDrivingSchool]);

  useEffect(() => {
    if (selectedPermission?.length) {
      const updatedPermissionOptions = initPermissionsOptions.filter(
        (option) => !selectedPermission.includes(option)
      );
      setUserPermissionsOptions(updatedPermissionOptions);
    } else {
      setUserPermissionsOptions([...initPermissionsOptions]);
    }
  }, [selectedPermission]);

  const COLUMNS = [
    {
      Header: "Nom",
      Footer: "Nom",
      accessor: "firstname",
    },
    {
      Header: "Prénom",
      Footer: "Prénom",
      accessor: "lastname",
    },
    {
      Header: "Email",
      Footer: "Email",
      accessor: "email",
    },
    {
      Header: "Groupes",
      Footer: "Groupes",
      accessor: "userGroup",
    },
    {
      Header: "Auto-écoles",
      Footer: "Auto-écoles",
      accessor: "userDrivingschool",
    },
    {
      Header: "Enabled",
      Footer: "Enabled",
      accessor: "enabled",
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: ({ row }) => {
        let recordId = row.original.id;
        return (
          <div className="d-flex">
            <button
              href="#"
              className="btn btn-success shadow btn-xs sharp me-1"
              onClick={() => openEditModal(recordId)}
            >
              <i className="fa-solid fa-pencil fa-lg"></i>
            </button>
            <button
              href="#"
              className="btn btn-danger shadow btn-xs sharp me-1"
              onClick={() => openDeleteModal(recordId)}
            >
              <i className="fa-solid fa-trash-can fa-lg"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <ToastContainer />
      <div className="d-flex">
        <button
          className="btn btn-primary"
          onClick={() => setModalCentered(true)}
        >
          Ajouter un utilisateur
        </button>
      </div>
      <div className="card mt-3">
        <div className="card-header">
          <h3>Utilisateurs</h3>
        </div>
        <div className="card-body">
          <FilteringTable
            users={usersData}
            headerColumn={COLUMNS}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Modal
        className="fade"
        show={modalCentered}
        onHide={() => setModalCentered(false)}
      >
        <Modal.Header closeButton onClick={handleClose}></Modal.Header>
        <Modal.Body>
          <div className="basic-form">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div className="form-group mb-3 col-md-12">
                  <label>Email*</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={user.email}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        email: e.target.value,
                      })
                    }
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Nom*</label>
                  <input
                    type="text"
                    name="lastname"
                    className="form-control"
                    value={user.lastname}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        lastname: e.target.value,
                      })
                    }
                    required
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Prénom</label>
                  <input
                    type="text"
                    name="firstname"
                    className="form-control"
                    value={user.firstname}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        firstname: e.target.value,
                      })
                    }
                  />
                </div>
                {loginUser?.usersRole?.name !== 'Secrétaires' && <div className="form-group mb-3 col-md-12">
                  <label>Auto-école</label>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    value={selectedDrivingSchool}
                    options={drivingSchoolOptions}
                    onChange={(data) => setSelectedDrivingSchool(data)}
                    styles={themeMultiSelectStyle}
                  />
                </div>}
                <div className="form-group mb-3 col-md-12">
                  <label>Mot de passe*</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="insert password"
                    value={user.password}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        password: e.target.value,
                      })
                    }
                    required={!isUpdate}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Confirmation du mot de passe*</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    className="form-control"
                    placeholder="insert confirm password"
                    value={user.confirmPassword}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        confirmPassword: e.target.value,
                      })
                    }
                    required={!isUpdate}
                    autoComplete="off"
                  />
                </div>
               {loginUser?.usersRole?.name !== 'Secrétaires' && <div className="form-group mb-3 col-md-12">
                  <label>Groupe</label>
                  <Select
                    value={selectedGroup}
                    options={userGroupOptions}
                    onChange={setSelectedGroup}
                    styles={themeStyle}
                  />
                </div>}
                {loginUser?.usersRole?.name !== 'Secrétaires' &&  <div className="form-group mb-3 col-md-12">
                  <label>Peut voir la page des recettes</label>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    value={selectedPermission}
                    options={userPermissionsOptions}
                    onChange={(data) => setSelectedPermission(data)}
                    styles={themeMultiSelectStyle}
                  />
                </div>}
              </div>
              <div className="form-group">
                <div className="form-check custom-checkbox checkbox-success check-lg">
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    id="enabled"
                    name="enabled"
                    checked={user.enabled}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        enabled: e.target.checked,
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor="enabled">
                    Actif
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-info"
                  disabled={isLoading}
                >
                  {isUpdate
                    ? "mettre à jour un utilisateur"
                    : "Créer un utilisateur"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showDeleteModal} onHide={closeDeleteModal}>
        <Modal.Header>
          <Modal.Title>Confirmation de la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Voulez-vous vraiment supprimer cet utilisateur ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.users.users,
    sUser: state.users.user,
    loading: state.users.loading,
    rolesOptions: state.role.roles,
    permissionOptions: state.permission.permissions,
    drivingSchoolsOptions: state.drivingSchool.drivingschools,
    loginUser: state?.auth?.auth,
  };
};

const mapDispatchToProps = {
  usersList,
  singleUser,
  addUser,
  updateUser,
  deleteUser,
  rolesList,
  permissionsList,
  drivingSchoolsList,
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);
