import {
  CREATE_PLANNING,
  CREATE_PLANNING_FAIL,
  CREATE_PLANNING_SUCCESS,
  DELETE_PLANNING,
  DELETE_PLANNING_FAIL,
  DELETE_PLANNING_SUCCESS,
  GET_PLANNING,
  GET_PLANNING_FAIL,
  GET_PLANNING_SUCCESS,
  UPDATE_PLANNING,
  UPDATE_PLANNING_FAIL,
  UPDATE_PLANNING_SUCCESS,
} from "./actionTypes";
import { api } from "../../utils/constants";
import { planning } from "../../utils/urls";

export const getPlanningList = () => async (dispatch) => {
  dispatch({
    type: GET_PLANNING,
  });
  try {
    let response = await api(planning(), "get", {});
    dispatch({
      type: GET_PLANNING_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    console.log("Error While Fetching Planning Data==>", error);
    return Promise.reject(
      dispatch({
        type: GET_PLANNING_FAIL,
        payload: error?.response?.error?.message || error?.message,
      })
    );
  }
};

export const createPlanning = (data, headers) => async (dispatch) => {
  dispatch({
    type: CREATE_PLANNING,
  });
  try {
    let response = await api(planning(), "post", data, headers);
    dispatch({
      type: CREATE_PLANNING_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    console.log("Error While Creating Planning", error);
    return Promise.reject(
      dispatch({
        type: CREATE_PLANNING_FAIL,
        payload: error?.response?.error?.message || error?.message,
      })
    );
  }
};

export const deletePlanning = (id) => async (dispatch) => {
  dispatch({
    type: DELETE_PLANNING,
  });
  try {
    let response = await api(planning(id), "delete", {});
    dispatch({
      type: DELETE_PLANNING_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    console.log("Error While Deleting Planning = > ", error);
    return Promise.reject(
      dispatch({
        type: DELETE_PLANNING_FAIL,
        payload: error?.response?.error?.message || error?.message,
      })
    );
  }
};

export const updatePlanning = (id, data) => async (dispatch) => {
  dispatch({
    type: UPDATE_PLANNING,
  });
  try {
    let response = await api(planning(id), "put", data);
    dispatch({
      type: UPDATE_PLANNING_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    console.log("Error While Updating Planning =>>", error);
    return Promise.reject(
      dispatch({
        type: UPDATE_PLANNING_FAIL,
        payload: error?.response?.error?.message || error?.message,
      })
    );
  }
};
