export const OFFERS='OFFERS';
export const OFFERS_SUCCESS='OFFERS_SUCCESS';
export const OFFERS_FAIL='OFFERS_FAIL';

export const ADDOFFERS='ADDOFFERS';
export const ADDOFFERS_SUCCESS='ADDOFFERS_SUCCESS';
export const ADDOFFERS_FAIL='ADDOFFERS_FAIL';

export const DELETE_OFFERS='DELETE_OFFERS';
export const DELETE_OFFERS_SUCCESS='DELETE_OFFERS_SUCCESS';
export const DELETE_OFFERS_FAIL='DELETE_OFFERS_FAIL';

export const UPDATE_OFFERS='UPDATE_OFFERS';
export const UPDATE_OFFERS_SUCCESS='UPDATE_OFFERS_SUCCESS';
export const UPDATE_OFFERS_FAIL='UPDATE_OFFERS_FAIL';

