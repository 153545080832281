import { FORMULAS, FORMULAS_FAIL, FORMULAS_SUCCESS } from "./actionType"


const initialState = {
    formula: [],
    loading: false,
    success: false,
    error: ''
}

export const formulaReducer = (state = initialState, action) => {
    switch (action.type) {

        case FORMULAS: return {
            ...state,
            formula: [],
            loading: true,
            success: false,
            error: ''
        }
        case FORMULAS_SUCCESS: return {
            ...state,
            formula: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case FORMULAS_FAIL: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        default:
            return state
    }
}