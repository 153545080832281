import { STUDENTS, STUDENTS_FAILED, STUDENTS_SUCCESS, ADDSTUDENT, ADDSTUDENT_SUCCESS, ADDSTUDENT_FAILED, UPDATESTUDENT, UPDATESTUDENT_SUCCESS, UPDATESTUDENT_FAILED, DELETESTUDENT, DELETESTUDENT_SUCCESS, DELETESTUDENT_FAILED, SINGLESTUDENT, SINGLESTUDENT_SUCCESS, SINGLESTUDENT_FAILED, ADDSTUDENTCOMMENT, ADDSTUDENTCOMMENT_SUCCESS, ADDSTUDENTCOMMENT_FAILED, ADDSTUDENTFORMULA, ADDSTUDENTFORMULA_SUCCESS, ADDSTUDENTFORMULA_FAILED, ADDSTUDENTPAYMENT, ADDSTUDENTPAYMENT_SUCCESS, ADDSTUDENTPAYMENT_FAILED, ADDSTUDENTSKILL, ADDSTUDENTSKILL_SUCCESS, ADDSTUDENTSKILL_FAILED, DELETESTUDENTFORMULA, DELETESTUDENTFORMULA_SUCCESS, DELETESTUDENTFORMULA_FAILED, UPDATESTUDENT_LICENCES, UPDATESTUDENT_LICENCES_SUCCESS, UPDATESTUDENT_LICENCES_FAILED, DELETESTUDENTCOMMENT, DELETESTUDENTCOMMENT_SUCCESS, DELETESTUDENTCOMMENT_FAILED, STUDENT_PLANNING, STUDENT_PLANNING_SUCCESS, STUDENT_PLANNING_FAILED } from "./actionType";

let initialState = {
    students: [],
    student: {},
    loading: false,
    success: false,
    error: '',
    addStudent: '',
    updateStudent: '',
    studentSkill: {},
    studentFormula: {},
    studentPayment: {},
    studentComment: {},
    updateStudentLicences:'',
    studentPlanning: []
}

export function StudentReducer(state = initialState, action) {
    switch (action.type) {
        case STUDENTS: return {
            ...state,
            students: [],
            loading: true,
            success: false,
            error: '',
        }
        case STUDENTS_SUCCESS: return {
            ...state,
            students: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case STUDENTS_FAILED: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case ADDSTUDENT: return {
            ...state,
            addStudent: '',
            loading: true,
            success: false,
            error: '',
        }
        case ADDSTUDENT_SUCCESS: return {
            ...state,
            addStudent: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case ADDSTUDENT_FAILED: return {
            ...state,
            addStudent: '',
            loading: false,
            success: false,
            error: action.payload
        }
        case UPDATESTUDENT: return {
            ...state,
            updateStudent: '',
            loading: true,
            success: false,
            error: '',
        }
        case UPDATESTUDENT_SUCCESS: return {
            ...state,
            updateStudent: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case UPDATESTUDENT_FAILED: return {
            ...state,
            updateStudent: '',
            loading: false,
            success: false,
            error: action.payload
        }
        case DELETESTUDENT: return {
            ...state,
            loading: true,
            success: false,
            error: ''
        }
        case DELETESTUDENT_SUCCESS: return {
            ...state,
            loading: false,
            success: true,
            error: '',
        }
        case DELETESTUDENT_FAILED: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case SINGLESTUDENT: return {
            ...state,
            student: {},
            loading: true,
            success: false,
            error: ''
        }
        case SINGLESTUDENT_SUCCESS: return {
            ...state,
            student: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case SINGLESTUDENT_FAILED: return {
            ...state,
            student: {},
            loading: false,
            success: false,
            error: action.payload
        }
        case ADDSTUDENTCOMMENT: return {
            ...state,
            studentComment: {},
            loading: true,
            success: false,
            error: ''
        }
        case ADDSTUDENTCOMMENT_SUCCESS: return {
            ...state,
            studentComment: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case ADDSTUDENTCOMMENT_FAILED: return {
            ...state,
            studentComment: {},
            loading: false,
            success: false,
            error: action.payload
        }
        case DELETESTUDENTCOMMENT: return {
            ...state,
            loading: true,
            success: false,
            error: ''
        }
        case DELETESTUDENTCOMMENT_SUCCESS: return {
            ...state,
            loading: false,
            success: true,
            error: ''
        }
        case DELETESTUDENTCOMMENT_FAILED: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case ADDSTUDENTFORMULA: return {
            ...state,
            studentFormula: {},
            loading: true,
            success: false,
            error: ''
        }
        case ADDSTUDENTFORMULA_SUCCESS: return {
            ...state,
            studentFormula: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case ADDSTUDENTFORMULA_FAILED: return {
            ...state,
            studentFormula: {},
            loading: false,
            success: false,
            error: action.payload
        }
        case ADDSTUDENTPAYMENT: return {
            ...state,
            studentPayment: {},
            loading: true,
            success: false,
            error: ''
        }
        case ADDSTUDENTPAYMENT_SUCCESS: return {
            ...state,
            studentPayment: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case ADDSTUDENTPAYMENT_FAILED: return {
            ...state,
            studentPayment: {},
            loading: false,
            success: false,
            error: action.payload
        }
        case ADDSTUDENTSKILL: return {
            ...state,
            studentSkill: {},
            loading: true,
            success: false,
            error: ''
        }
        case ADDSTUDENTSKILL_SUCCESS: return {
            ...state,
            studentSkill: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case ADDSTUDENTSKILL_FAILED: return {
            ...state,
            studentSkill: {},
            loading: false,
            success: false,
            error: action.payload
        }
        case DELETESTUDENTFORMULA: return {
            ...state,
            loading: true,
            success: false,
            error: ''
        }
        case DELETESTUDENTFORMULA_SUCCESS: return {
            ...state,
            loading: false,
            success: true,
            error: '',
        }
        case DELETESTUDENTFORMULA_FAILED: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case UPDATESTUDENT_LICENCES:return {
            ...state,
            updateStudentLicences:'',
            loading:true,
            success:false,
            error:''
        }
        case UPDATESTUDENT_LICENCES_SUCCESS : return{
            ...state,
            updateStudentLicences:action.payload,
            loading:false,
            success:true,
            error:''
        }
        case UPDATESTUDENT_LICENCES_FAILED : return {
            ...state,
            updateStudentLicences:'',
            loading:false,
            success:false,
            error:action.payload
        }
        case STUDENT_PLANNING:return {
            ...state,
            studentPlanning:[],
            loading:true,
            success:false,
            error:''
        }
        case STUDENT_PLANNING_SUCCESS : return{
            ...state,
            studentPlanning:action.payload,
            loading:false,
            success:true,
            error:''
        }
        case STUDENT_PLANNING_FAILED : return {
            ...state,
            studentPlanning:[],
            loading:false,
            success:false,
            error:action.payload
        }
        default:
            return state
    }
}