import { OFFERS, OFFERS_SUCCESS, OFFERS_FAIL, ADDOFFERS, ADDOFFERS_SUCCESS, ADDOFFERS_FAIL, DELETE_OFFERS, DELETE_OFFERS_SUCCESS, DELETE_OFFERS_FAIL, UPDATE_OFFERS, UPDATE_OFFERS_SUCCESS, UPDATE_OFFERS_FAIL } from "./actionTypes";
import { offers } from "../../utils/urls";
import { api } from "../../utils/constants";


export const offersList = () => async (dispatch) => {
  dispatch({
    type: OFFERS,
  });
  try {
    let response = await api(offers(), "get", {});
    dispatch({
      type: OFFERS_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    console.log("fetching Offers error is ===>", error);
    return Promise.reject(
      dispatch({
        type: OFFERS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};

export const addOffer=(data,headers)=>async (dispatch)=>{
  dispatch({
    type:ADDOFFERS
  })
  try {
    let response=await api(offers(),'post',data,headers);
    dispatch({
      type: ADDOFFERS_SUCCESS,
      payload: response?.data?.data,
    });
    return response;

  } catch (error) {
    console.log("Adding Monitors error is ===>", error);
    return Promise.reject(
      dispatch({
        type: ADDOFFERS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
}

export const deleteOffer=(id)=>async (dispatch)=>{
  dispatch({
    type:DELETE_OFFERS
  })
  try {
    let response=await api(offers(id),'delete',{});
    console.log("Offers Deleting",response);
    dispatch({
      type: DELETE_OFFERS_SUCCESS,
      payload: response?.data,
  })
  return response
  } catch (error) {
    console.log("deleting offers error is ===>", error);
    return Promise.reject(dispatch({
        type: DELETE_OFFERS_FAIL,
        payload: error?.response?.data?.message || error?.message
    }))
  }
}

export const updateOffer = (id, data, headers) => async dispatch => {
  dispatch({
      type: UPDATE_OFFERS,
  })
  try {
      let response = await api(offers(id), 'put', data, headers);
      dispatch({
          type: UPDATE_OFFERS_SUCCESS,
          payload: response?.data?.data
      })
      return response
  } catch (error) {
      console.log("updating offers error is ==>", error);
      return Promise.reject(dispatch({
          type: UPDATE_OFFERS_FAIL,
          payload: error?.response?.data?.message || error?.message
      }))   
  }
}