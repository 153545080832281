import React, { useEffect, useState } from "react";
import GraphPage1 from "./GraphPage1";
import GraphPage2 from "./GraphPage2";
import { connect } from "react-redux";
import { getInstructorVehicleStats } from "../../../../../store/statistics/action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Nav, Spinner, Tab } from "react-bootstrap";
import { usersList } from "../../../../../store/users/action";
import Select from "react-select";
import { themeStyle } from "../../../../../utils/style";

const Tab11 = ({ instructorVehicleStats, getInstructorVehicleStats, loading, usersData, usersList }) => {
  const [resources, setResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState({});
  const [vehicleOptions, setVehicleOptions] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState({})

  useEffect(() => {
    if (usersData?.length) {
      const res = usersData.reduce((result, val) => {
        if (val?.userGroup === "Moniteurs" && val?.userDrivingschool) {
          result.push({
            value: val.id,
            label: val.firstname || val.lastname || val.email,
          });
        }
        return result;
      }, []);
      setResources(res);
    }
  }, [usersData]);

  useEffect(() => {
    if (resources?.length && !selectedResource?.value) {
      setSelectedResource(resources[0])
    }
  }, [resources])

  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    usersList().catch((error) => {
      notifyError(error?.payload);
    });
  }, []);

  useEffect(() => {
    if (selectedResource?.value) {
      getInstructorVehicleStats(selectedResource?.value).catch((error) => {
        notifyError(`While Fetching Instructor Hours Error is: => ${error}`);
      });
    }
  }, [selectedResource])

  useEffect(() => {
    if (instructorVehicleStats.length && !loading) {
      setVehicleOptions(instructorVehicleStats?.map(item => ({
        value: item,
        label: item?.name
      })))
      setSelectedVehicle(instructorVehicleStats?.map(item => ({
        value: item,
        label: item?.name
      }))[0])
    }else{
      setVehicleOptions([])
    }
  }, [instructorVehicleStats, loading]);


  return (
    <>
      <ToastContainer />
      <div>
        <div className="col-12">
          <Card>
            <Card.Body>
              {loading && (
                <div
                  className="text-center"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ borderRadius: "50%" }}
                  ></Spinner>
                  <span>Loading...</span>
                </div>
              )}
              {!loading && (
                <div>
                  <Nav
                    as="ul"
                    className="nav-pills mb-4 justify-content-between"
                  >
                    <h5>In interdum mauris sit amet porttitor pretium</h5>
                    <Select
                      options={resources}
                      styles={themeStyle}
                      onChange={(value) => { setSelectedResource(value); setSelectedVehicle({}) }}
                      value={selectedResource}
                    />
                    <Select
                      options={vehicleOptions}
                      styles={themeStyle}
                      onChange={setSelectedVehicle}
                      value={selectedVehicle}
                    />
                  </Nav>
                  <div className="d-flex justify-content-center">
                    <GraphPage1 data={selectedVehicle?.value ? { values: [selectedVehicle?.value?.penalty, selectedVehicle?.value?.repair, selectedVehicle?.value?.report], labels: ["amendes", 'réparations', 'constats'] } : { values: [], labels: [] }} />
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    instructorVehicleStats: state?.statistics?.instructorVehicleStats,
    loading: state?.statistics?.loading,
    usersData: state.users.users,
  };
};
const mapDispatchToProps = {
  getInstructorVehicleStats,
  usersList
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab11);
