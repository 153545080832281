export const PACKAGES = "PACKAGES"
export const PACKAGES_SUCCESS = "PACKAGES_SUCCESS"
export const PACKAGES_FAIL = "PACKAGES_FAIL"
export const HOURS = "HOURS"
export const HOURS_SUCCESS = "HOURS_SUCCESS"
export const HOURS_FAIL = "HOURS_FAIL"
export const PLANNINGHOURS = "PLANNINGHOURS"
export const PLANNINGHOURS_SUCCESS = "PLANNINGHOURS_SUCCESS"
export const PLANNINGHOURS_FAIL = "PLANNINGHOURS_FAIL"
export const MODIFIEDHOURS = "MODIFIEDHOURS"
export const MODIFIEDHOURS_SUCCESS = "MODIFIEDHOURS_SUCCESS"
export const MODIFIEDHOURS_FAIL = "MODIFIEDHOURS_FAIL"
export const CANCLEDHOURS = "CANCLEDHOURS"
export const CANCLEDHOURS_SUCCESS = "CANCLEDHOURS_SUCCESS"
export const CANCLEDHOURS_FAIL = "CANCLEDHOURS_FAIL"
export const ERROREDHOURS = "ERROREDHOURS"
export const ERROREDHOURS_SUCCESS = "ERROREDHOURS_SUCCESS"
export const ERROREDHOURS_FAIL = "ERROREDHOURS_FAIL"
export const INSTRUCTORHOURS = "INSTRUCTORHOURS"
export const INSTRUCTORHOURS_SUCCESS = "INSTRUCTORHOURS_SUCCESS"
export const INSTRUCTORHOURS_FAIL = "INSTRUCTORHOURS_FAIL"
export const INSTRUCTORABSENTHOURS = "INSTRUCTORABSENTHOURS"
export const INSTRUCTORABSENTHOURS_SUCCESS = "INSTRUCTORABSENTHOURS_SUCCESS"
export const INSTRUCTORABSENTHOURS_FAIL = "INSTRUCTORABSENTHOURS_FAIL"
export const INSTRUCTORAVERAGEHOURS = "INSTRUCTORAVERAGEHOURS"
export const INSTRUCTORAVERAGEHOURS_SUCCESS = "INSTRUCTORAVERAGEHOURS_SUCCESS"
export const INSTRUCTORAVERAGEHOURS_FAIL = "INSTRUCTORAVERAGEHOURS_FAIL"
export const INSTRUCTORWISHLISTCOUNT = "INSTRUCTORWISHLISTCOUNT"
export const INSTRUCTORWISHLISTCOUNT_SUCCESS = "INSTRUCTORWISHLISTCOUNT_SUCCESS"
export const INSTRUCTORWISHLISTCOUNT_FAIL = "INSTRUCTORWISHLISTCOUNT_FAIL"
export const INSTRUCTORVEHICLESTATS = "INSTRUCTORVEHICLESTATS"
export const INSTRUCTORVEHICLESTATS_SUCCESS = "INSTRUCTORVEHICLESTATS_SUCCESS"
export const INSTRUCTORVEHICLESTATS_FAIL = "INSTRUCTORVEHICLESTATS_FAIL"