import { api } from "../../utils/constants";
import { examWishlists } from "../../utils/urls";
import { GET_EXAM_WISHLISTS, GET_EXAM_WISHLISTS_FAIL, GET_EXAM_WISHLISTS_SUCCESS, UPDATE_EXAM_WISHLISTS, UPDATE_EXAM_WISHLISTS_FAIL, UPDATE_EXAM_WISHLISTS_SUCCESS } from "./actionType";

export const getExamWishlists = () => async (dispatch) => {
    dispatch({
        type: GET_EXAM_WISHLISTS
    });
    try{
        let response = await api(examWishlists(), 'get')
        dispatch({
            type: GET_EXAM_WISHLISTS_SUCCESS,
            payload: response?.data?.data
        })
        return response
    }catch(error){
    console.log("Error While Fetching Exam Wishlists Data==>", error);
    return Promise.reject(
        dispatch({
            type: GET_EXAM_WISHLISTS_FAIL,
            payload: error?.response?.error?.message || error?.message
        })
    )
    }
}

export const updateExamWishlist = (id, data) => async (dispatch) => {
    dispatch({
      type: UPDATE_EXAM_WISHLISTS,
    });
    try {
      let response = await api(examWishlists(id), "put", data);
      dispatch({
        type: UPDATE_EXAM_WISHLISTS_SUCCESS,
        payload: response?.data?.data,
      });
      return response;
    } catch (error) {
      console.log("Error While Updating Exam Wishlist =>>", error);
      return Promise.reject(
        dispatch({
          type: UPDATE_EXAM_WISHLISTS_FAIL,
          payload: error?.response?.error?.message || error?.message,
        })
      );
    }
  };