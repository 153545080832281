import { api } from "../../utils/constants"
import { dashboard } from "../../utils/urls"
import { DASHBOARD, DASHBOARD_FAILED, DASHBOARD_SUCCESS } from "./actionTypes"

export const dashboardData=()=> async dispatch=>{
    dispatch({
        type:DASHBOARD
    })
    try {
        let response = await api(dashboard(),"get",{});
        dispatch({
            type:DASHBOARD_SUCCESS,
            payload:response?.data?.data
        })
        return response
    } catch (error) {
        dispatch({
            type:DASHBOARD_FAILED,
            payload : error?.response?.data?.message || error?.message
        })
    }
}

