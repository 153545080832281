import React, { useEffect, useState } from "react";
import FilteringTable from "./component/FilteringTable/FilteringTable";
import { ColumnFilter } from "./component/FilteringTable/ColumnFilter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Select from "react-select";
import { Card, Button, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";

import { useNavigate } from "react-router-dom";

import { monitorsList, addMonitor } from "../../../../store/monitors/action";
import { drivingSchoolsList } from "../../../../store/drivingSchools/action";
import { themeMultiSelectStyle } from "../../../../utils/style";
const initMonitors = {
  firstname: "",
  lastname: "",
  userDrivingschool: [],
  email: "",
  password: "",
  confirmPassword: "",
};

const Monitors = (props) => {
  let {
    list,
    success,
    loading,
    monitorsList,
    addMonitor,
    drivingSchoolsList,
    drivingSchOpt,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [showingData, setShowingData] = useState([]);

  const [instructor, setInstructor] = useState({ ...initMonitors });
  const [selectDrivingSchool, setSelectDrivingSchool] = useState([]);
  const [drivingSchoolOptions, setDrivingSchoolOPtions] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const navigation = useNavigate();

  useEffect(() => {
    if (!drivingSchOpt.length) {
      drivingSchoolsList();
    }
  }, []);

  useEffect(() => {
    let dslist = drivingSchOpt?.map((ds) => {
      return {
        value: {
          id: ds.id,
          name: ds.name,
        },
        label: ds.name,
      };
    });
    setDrivingSchoolOPtions(dslist);
  }, [drivingSchOpt]);

  useEffect(() => {
    dataLoading();
  }, []);

  const dataLoading = async () => {
    try {
      return await monitorsList();
    } catch (error) {
      notifyError(error?.payload || error);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
    if (list?.length > 0) {
      setShowingData(
        list.map((val) => {
          return {
            id: val?.id,
            Name: val?.firstname + " " + val?.lastname,
            "Hours worked in the last 1 month":
              val?.instructorGenerals?.[0]?.total_duration_last_month ||
              0,
            "Hours worked in the this month":
              val?.instructorGenerals?.[0]?.total_duration_current_month || 0,
            "Total hours worked":
              val?.instructorGenerals?.[0]?.total_duration_all_time ||
              0,
          };
        })
      );
    }
  }, [list, loading]);

  const chackboxFun = (type) => {
    const chackbox = document.querySelectorAll(".bs_exam_topper input");
    const motherChackBox = document.querySelector(".bs_exam_topper_all input");
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  const COLUMNS = [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
          <input
            type="checkbox"
            className="form-check-input"
            id="customCheckBox3"
            required=""
            onClick={() => chackboxFun()}
            {...getToggleAllRowsSelectedProps()}
          />
          <label className="form-check-label" htmlFor="customCheckBox3"></label>
        </div>
      ),
      Cell: ({ row }) => (
        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
          <input
            type="checkbox"
            className="form-check-input"
            id="customCheckBox3"
            required=""
            onClick={() => chackboxFun()}
            {...row.getToggleRowSelectedProps()}
          />
          <label className="form-check-label" htmlFor="customCheckBox3"></label>
        </div>
      ),
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "Name",
      Filter: ColumnFilter,
      //disableFilters: true,
    },
    {
      Header: "Hours worked in the last 1 month",
      Footer: "Hours worked in the last 1 month",
      accessor: "Hours worked in the last 1 month",
      Filter: ColumnFilter,
      //disableFilters: true,
    },
    {
      Header: "Hours worked in the this month",
      Footer: "Hours worked in the this month",
      accessor: "Hours worked in the this month",
      Filter: ColumnFilter,
      //disableFilters: true,
    },
    {
      Header: "Total hours worked",
      Footer: "Total hours worked",
      accessor: "Total hours worked",
      Filter: ColumnFilter,
    },
  ];

  const handleSelectChange = (selectedOptions) => {
    setSelectDrivingSchool(selectedOptions);
  };

  const filteredOptions = drivingSchoolOptions.filter(
    (option) =>
      !selectDrivingSchool.some(
        (selectedOption) => selectedOption.value.id === option.value.id
      )
  );

  const transformedValue = selectDrivingSchool.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  const handleChange = (e, fieldname) => {
    const value = e.target.value;
    setInstructor((preVal) => ({
      ...preVal,
      [fieldname]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!selectDrivingSchool) {
        notifyError("select driving School for monitor");
        return;
      }
      if (!instructor.firstname) {
        notifyError("please add correct monitors name");
        return;
      }
      if (!instructor.lastname) {
        notifyError("please add correct monitors lastname");
        return;
      }
      if (!instructor.email) {
        notifyError("please add correct monitors email");
        return;
      }
      if (!instructor.password || !instructor.confirmPassword) {
        notifyError("plaese add correct password");
        return;
      }
      if (instructor.password !== instructor.confirmPassword) {
        notifyError("password didn't matched, please add correct password");
        return;
      }
      let instructorData = {
        firstname: instructor.firstname,
        lastname: instructor.lastname,
        role_id: 2,
        email: instructor.email,
        password: instructor.password,
        userDrivingschool: selectDrivingSchool.map((d) => ({
          drivingschool_id: d?.value?.id,
        })),
      };
      const headers = {
        "Content-Type": "application/json",
      };
      setIsLoading(true);
      await addMonitor(instructorData, headers);
      setInstructor({ ...initMonitors });
      setSelectDrivingSchool([]);
      setAddModal(false);
      if (success && !loading) {
        notifySuccess("Added SuccessFully");
        await dataLoading();
      }
    } catch (error) {
      console.log(error);
      notifyError("Can't Be Added");
    } finally {
      setIsLoading(false);
    }
  };

  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifySuccess = (msg) => {
    console.log("Notified msg");
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <ToastContainer />
      <div id="OffersPage">
        <Button
          className="me-2 mb-2"
          variant="primary"
          width={30}
          onClick={() => setAddModal(true)}
        >
          Ajouter un Moniteur
        </Button>

        <Card className="mt-3">
          <FilteringTable
            datas={showingData}
            headerColumn={COLUMNS}
            isLoading={isLoading}
          />
        </Card>

        <Modal
          className="fade bd-example-modal-lg "
          show={addModal}
          onHide={() => setAddModal(false)}
          size="md"
        >
          <Modal.Header>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setAddModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                <div className="mb-3">
                  <label className="col-form-label">Nom</label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      value={instructor.lastname}
                      onChange={(e) => handleChange(e, "lastname")}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="col-form-label">Prénom</label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      value={instructor.firstname}
                      onChange={(e) => handleChange(e, "firstname")}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4 form-group">
                <label className="col-form-label">Email</label>
                <div className="">
                  <input
                    type="text"
                    className="form-control"
                    value={instructor.email}
                    onChange={(e) => handleChange(e, "email")}
                    required
                  />
                </div>
              </div>
              <div className="mb-4 form-group">
                <label className="col-form-label">Mot de passe</label>
                <div className="">
                  <input
                    type="text"
                    className="form-control"
                    value={instructor.password}
                    onChange={(e) => handleChange(e, "password")}
                    required
                  />
                </div>
              </div>
              <div className="mb-4 form-group">
                <label className="col-form-label">
                  Confirmation du mot de passe
                </label>
                <div className="">
                  <input
                    type="text"
                    className="form-control"
                    value={instructor.confirmPassword}
                    onChange={(e) => handleChange(e, "confirmPassword")}
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="col-form-label">
                  {" "}
                  <label className="col-form-label">Auto-école</label>
                </label>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={filteredOptions}
                  value={transformedValue}
                  onChange={handleSelectChange}
                  styles={themeMultiSelectStyle}
                />
              </div>
              <div className="me-auto ms-auto">
                <Button
                  type="submit"
                  className="text-center mt-5"
                  variant="info"
                  width={20}
                  onClick={handleSubmit}
                >
                  {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Save...</span>
                    </Spinner>
                  ) : (
                    "Créer un moniteur"
                  )}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.monitors.monitors,
    loading: state.monitors.loading,
    success: state.monitors.success,
    drivingSchOpt: state.drivingSchool.drivingschools,
  };
};

const mapDispatchToProps = {
  monitorsList,
  addMonitor,
  drivingSchoolsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Monitors);
