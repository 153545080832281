import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { connect } from "react-redux";
import {
  singleUser,
  passwordUpdate,
  updateUser,
} from "../../../store/users/action";
// import { drivingSchoolsList } from "../../../store/drivingSchools/action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Spinner } from "react-bootstrap";
// import { themeStyle } from "../../../utils/style";
// import Select from "react-select";

const Profile = ({
  user,
  loading,
  loggerId,
  singleUser,
  passwordUpdate,
  updateUser,
  // drivingSchoolsList,
  // drivingSchoolsOptions,
}) => {
  const [userData, setUserData] = useState({});
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [modalCentered, setModalCentered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //   const [dsOptions, setDsOptions] = useState([]);
  //   const [selectedDrivingSchool, setSelectedDrivingSchool] = useState({});
  useEffect(() => {
    if (loggerId) {
      singleUser(loggerId)
        .then()
        .catch((error) => {
          console.log(error, "while fetching data of user error");
          notifyError(`user data fetching Error:${error?.message}`);
        });
    }
    // if (!drivingSchoolsOptions.length) {
    //   drivingSchoolsList()
    //     .then()
    //     .catch((error) => {
    //       notifyError(
    //         `Error: while fetching driving school list ==> ${error?.message}`
    //       );
    //     });
    // }
  }, []);

  useEffect(() => {
    if (user) {
      let data = {
        id: user?.id,
        firstname: user?.firstname,
        lastname: user?.lastname,
        email: user?.email,
        name: user?.firstname + " " + user?.lastname,
        role: user?.usersRole?.name,
        drivingSchool: user?.userDrivingschool?.[0]?.drivingSchoolUser?.name,
      };
      setUserData(data);
    }
  }, [user]);

  //   useEffect(() => {
  //     if (drivingSchoolsOptions.length > 0) {
  //       let options = drivingSchoolsOptions?.map((ds) => {
  //         return {
  //           value: ds?.id,
  //           label: ds?.name,
  //         };
  //       });
  //       setDsOptions(options);
  //     }
  //   }, [drivingSchoolsOptions]);
  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifySuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (oldPassword === newPassword) {
      notifyError("you can't set same password again");
      return 0;
    } else if (newPassword !== confirmPassword) {
      notifyError("password not matched");
      return 0;
    } else if (newPassword.length < 6) {
      notifyError("password to short, minimum 6 character required");
    } else {
      let data = {
        password: oldPassword,
        newPassword: newPassword,
      };
      try {
        setIsLoading(true);
        let res = await passwordUpdate(data);
        if (!res?.data?.data?.error) {
          notifySuccess("Password SuccessFully Changed");
          setModalCentered(false);
          setNewPassword("");
          setOldPassword("");
          setConfirmPassword("");
        }
      } catch (error) {
        console.log(error);
        notifyError(
          `Oops: Password can't be Changed, Error: ${error?.message}`
        );
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleClose = () => {
    setModalCentered(false);
    setConfirmPassword("");
    setNewPassword("");
    setOldPassword("");
  };
  const handleUpdateInfo = async (e) => {
    e.preventDefault();
    try {
      if (
        user.firstname !== userData.firstname ||
        user.lastname !== userData.lastname
      ) {
        let data = {
          id: userData?.id,
          firstname: userData?.firstname,
          lastname: userData?.lastname,
        };
        let res = await updateUser(data.id, data);
        if (!res?.data?.data?.error) {
          notifySuccess("Your info successfully updated...");
        }
      } else {
        notifyError(
          "You can not update info, without change of firstname and lastname"
        );
      }
    } catch (error) {
      console.log(error);
      notifyError(
        `Oops: Info not Updated Error is: ${error?.message || error}`
      );
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-xl-3 col-lg-4">
          <div className="clearfix">
            <div className="card card-bx profile-card author-profile m-b30">
              <div className="card-body">
                <div className="p-5">
                  <div className="author-profile">
                    <div className="author-media">
                      <Avatar
                        src={""}
                        name={userData?.name || "Driving Acc"}
                        size="150"
                        round
                        color="#F44336"
                      />
                    </div>
                    <div className="author-info">
                      <h6 className="title">{userData?.name}</h6>
                      <span>{userData?.role}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8">
          <div className="card profile-card card-bx m-b30">
            <div className="card-header">
              <h6 className="title">Profile Details</h6>
            </div>
            <form className="profile-form">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Prénom</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userData?.firstname}
                      onChange={(e) =>
                        setUserData((prevState) => {
                          return {
                            ...prevState,
                            firstname: e.target.value,
                          };
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Nom</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userData?.lastname}
                      onChange={(e) =>
                        setUserData((prevState) => {
                          return {
                            ...prevState,
                            lastname: e.target.value,
                          };
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 m-b30">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={userData?.email}
                      disabled
                    />
                  </div>
                  {userData?.role !== "Gérants" && (
                    <div className="col-sm-12 m-b30">
                      <label className="form-label">Auto-écoles</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={userData?.drivingSchool}
                        disabled
                      />
                      {/* <Select
                          value={selectedDrivingSchool}
                          options={dsOptions}
                          onChange={(data) => setSelectedDrivingSchool(data)}
                          styles={themeStyle}
                        /> */}
                    </div>
                  )}
                </div>
              </div>
              <div className="card-footer">
                <button
                  className="btn btn-primary"
                  onClick={(e) => handleUpdateInfo(e)}
                  disabled={loading}
                >
                  mise à jour
                </button>
                <button
                  className="btn btn-transpatent"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalCentered(true);
                  }}
                >
                  changer le mot de passe
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal className="fade" show={modalCentered} onHide={handleClose}>
        <Modal.Header closeButton onClick={handleClose}></Modal.Header>
        <Modal.Body>
          <div className="basic-form">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div className="form-group mb-3 col-md-12">
                  <label>ancien mot de passe*</label>
                  <input
                    type="password"
                    name="oldPassword"
                    className="form-control"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                    autoComplete="off"
                  />
                  {!oldPassword.length && (
                    <div className="text-danger fs-12">Password not empty</div>
                  )}
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>nouveau mot de passe*</label>
                  <input
                    type="password"
                    name="newPassword"
                    className="form-control"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    autoComplete="off"
                  />
                  {newPassword.length !== 6 && (
                    <div className="text-danger fs-12">
                      Password Must be 6 or more character long
                    </div>
                  )}
                  {newPassword === oldPassword && (
                    <div className="text-danger fs-12">
                      You can't set same password again
                    </div>
                  )}
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Confirmez le mot de passe*</label>
                  <input
                    type="password"
                    name="oldPassword"
                    className="form-control"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    autoComplete="off"
                  />
                  {newPassword !== confirmPassword && (
                    <div className="text-danger fs-12">
                      Password not matched
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-info"
                    disabled={isLoading}
                  >
                    enregistrer le mot de passe
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.users?.user,
    loading: state?.users?.loading,
    loggerId: state?.auth?.auth.id,
    // drivingSchoolsOptions: state.drivingSchool.drivingschools,
  };
};

const mapDispatchToProps = {
  singleUser,
  passwordUpdate,
  // drivingSchoolsList,
  updateUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
