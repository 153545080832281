import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import  ThemeContext  from "./context/ThemeContext"; 
import "./css/custom.css";
import Error404 from './jsx/pages/Error404';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store = {store}>
    <BrowserRouter basename="/adminpll">
        <ThemeContext>
          <App />
        </ThemeContext>
      </BrowserRouter>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Error404 />}/>
        </Routes>
      </BrowserRouter>
      
    </Provider>
  </React.StrictMode>
);

