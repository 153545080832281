import { MONITORS,MONITORS_SUCCESS,MONITORS_FAIL, ADD_MONITORS, ADD_MONITORS_SUCCESS, ADD_MONITORS_FAIL } from "./actionTypes"
const initialState={
    monitors:[],
    addmonitor:{},
    success:false,
    loading:false,
    error:''
}

export const MonitorsReducer=(state=initialState,action)=>{
    switch(action.type){
        case MONITORS: return {
            ...state,
            monitors:[],
            loading:true,
            success:false,
            error:''
        }
        case MONITORS_SUCCESS: return {
            ...state,
            monitors:action.payload,
            loading:false,
            success:true,
            error:''
        }
        case MONITORS_FAIL : return {
            ...state,
            monitors:[],
            success:false,
            error:action.payload,
            loading:false
        }
        case ADD_MONITORS:return {
            ...state,
            addmonitor:{},
            success:false,
            loading:true,
            error:""
        }
        case ADD_MONITORS_SUCCESS:return {
            ...state,
            addmonitor:action.payload,
            loading:false,
            success:true,
            error:""
        }
        case ADD_MONITORS_FAIL:return {
            ...state,
            addmonitor:{},
            loading:false,
            success:false,
            error:action.payload
        }
        default:
            return state
    }
}