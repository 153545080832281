import { api } from "../../utils/constants"
import { licenece } from "../../utils/urls"
import { GET_LICENCES, GET_LICENCES_FAILURE, GET_LICENCES_SUCCESS } from "./actionTypes"

export const licenecesList = ()=> async dispatch=>{
    dispatch({
        type : GET_LICENCES
    })
    try {
        let response = await api(licenece(),"get",{});
        dispatch({
            type:GET_LICENCES_SUCCESS,
            payload : response?.data?.data
        })
        return response;
    } catch (error) {
        console.log("Error While Fetching Licences List",error);
        dispatch({
            type:GET_LICENCES_FAILURE,
            payload : error?.response?.data?.message || error?.message
        })
    }
}