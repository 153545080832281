
import { api } from "../../utils/constants";
import { debits } from "../../utils/urls";
import { DEBITS, DEBITS_FAIL, DEBITS_SUCCESS, SENDMAIL, SENDMAIL_FAIL, SENDMAIL_SUCCESS } from "./actionTypes";

export const debitsList=()=>async dispatch =>{
    dispatch({
        type:DEBITS
    })
    try {
        let response = await api(debits(),'get',{});
        dispatch({
            type: DEBITS_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("fetching Debits error is ===>", error);
        return Promise.reject(dispatch({
            type: DEBITS_FAIL,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}

export const sendEmailNotification= (data) => async dispatch => {
    dispatch({
        type: SENDMAIL,
    })
    try {
        let response = await api(debits('mail'), 'post', data);
        dispatch({
            type: SENDMAIL_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("Error: while sending email ===>", error);
        return Promise.reject(dispatch({
            type: SENDMAIL_FAIL,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}