import { GET_LICENCES, GET_LICENCES_FAILURE, GET_LICENCES_SUCCESS } from "./actionTypes"

let initalState = {
    licences : [],
    success : false,
    loading : false,
    error : ''
}

export const licencesReducer = (state=initalState,action)=>{
    switch(action.type){
        case GET_LICENCES: return {
            licences:[],
            loading : true,
            success :false,
            error : ''
        }
        case GET_LICENCES_SUCCESS : return {
            licences : action.payload,
            loading:false,
            success : true,
            error : false
        }
        case GET_LICENCES_FAILURE : return {
            licences : [],
            loading:false,
            success : false,
            error : action.payload
        }
        default : 
        return state
    }
}