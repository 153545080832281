import { api } from "../../utils/constants";
import { addStudentsInOneExam, deleteStudentsInOneExam, exams } from "../../utils/urls";
import { ADD_STUDENTS, ADD_STUDENTS_FAIL, ADD_STUDENTS_SUCCESS, CREATE_EXAM, CREATE_EXAM_FAIL, CREATE_EXAM_SUCCESS, DELETE_EXAM, DELETE_EXAM_FAIL, DELETE_EXAM_SUCCESS, DELETE_STUDENTS, DELETE_STUDENTS_FAIL, DELETE_STUDENTS_SUCCESS, GET_EXAMS, GET_EXAMS_FAIL, GET_EXAMS_SUCCESS, UPDATE_EXAM, UPDATE_EXAM_FAIL, UPDATE_EXAM_SUCCESS } from "./actionType";


export const getExamsList = () => async (dispatch) => {
    dispatch({
        type: GET_EXAMS
    });
    try{
        let response = await api(exams(), 'get')
        dispatch({
            type: GET_EXAMS_SUCCESS,
            payload: response?.data?.data
        })
        return response
    }catch(error){
    console.log("Error While Fetching Exams Data==>", error);
    return Promise.reject(
        dispatch({
            type: GET_EXAMS_FAIL,
            payload: error?.response?.error?.message || error?.message
        })
    )
    }
}

export const updateExam = (id, data) => async (dispatch) => {
    dispatch({
      type: UPDATE_EXAM,
    });
    try {
      let response = await api(exams(id), "put", data);
      dispatch({
        type: UPDATE_EXAM_SUCCESS,
        payload: response?.data?.data,
      });
      return response;
    } catch (error) {
      console.log("Error While Updating Exam =>>", error);
      return Promise.reject(
        dispatch({
          type: UPDATE_EXAM_FAIL,
          payload: error?.response?.error?.message || error?.message,
        })
      );
    }
  };

export const deleteExam = (id) => async (dispatch) => {
    dispatch({
      type: DELETE_EXAM,
    });
    try {

      let response = await api(exams(id), "delete");
      console.log(response, 'delete API')

      dispatch({
        type: DELETE_EXAM_SUCCESS,
        payload: response?.data?.data,
      });
      return response;
    } catch (error) {
      console.log("Error While Updating Exam =>>", error);
      return Promise.reject(
        dispatch({
          type: DELETE_EXAM_FAIL,
          payload: error?.response?.error?.message || error?.message,
        })
      );
    }
  };

export const createExam = (data) => async (dispatch) => {
    dispatch({
      type: CREATE_EXAM,
    });
    try {
      let response = await api(exams(), "post", data);
      dispatch({
        type: CREATE_EXAM_SUCCESS,
        payload: response?.data?.data,
      });
      return response;
    } catch (error) {
      console.log("Error While Updating Exam =>>", error);
      return Promise.reject(
        dispatch({
          type: CREATE_EXAM_FAIL,
          payload: error?.response?.error?.message || error?.message,
        })
      );
    }
  };

export const addStudentsInExam = (data) => async (dispatch) => {
    dispatch({
      type: ADD_STUDENTS,
    });
    try {
      let response = await api(addStudentsInOneExam(), "post", data);
      dispatch({
        type: ADD_STUDENTS_SUCCESS,
        payload: response?.data?.data,
      });
      return response;
    } catch (error) {
      console.log("Error While Updating students in exam =>>", error);
      return Promise.reject(
        dispatch({
          type: ADD_STUDENTS_FAIL,
          payload: error?.response?.error?.message || error?.message,
        })
      );
    }
  };

export const deleteStudentsInExam = (data) => async (dispatch) => {
    dispatch({
      type: DELETE_STUDENTS,
    });
    try {
      let response = await api(deleteStudentsInOneExam(), "post", data);
      dispatch({
        type: DELETE_STUDENTS_SUCCESS,
        payload: response?.data?.data,
      });
      return response;
    } catch (error) {
      console.log("Error While deleting students in exam =>>", error);
      return Promise.reject(
        dispatch({
          type: DELETE_STUDENTS_FAIL,
          payload: error?.response?.error?.message || error?.message,
        })
      );
    }
  };

