import { CANCLEDHOURS, CANCLEDHOURS_FAIL, CANCLEDHOURS_SUCCESS, ERROREDHOURS, ERROREDHOURS_FAIL, ERROREDHOURS_SUCCESS, HOURS, HOURS_FAIL, HOURS_SUCCESS, INSTRUCTORABSENTHOURS, INSTRUCTORABSENTHOURS_FAIL, INSTRUCTORABSENTHOURS_SUCCESS, INSTRUCTORAVERAGEHOURS, INSTRUCTORAVERAGEHOURS_FAIL, INSTRUCTORAVERAGEHOURS_SUCCESS, INSTRUCTORHOURS, INSTRUCTORHOURS_FAIL, INSTRUCTORHOURS_SUCCESS, INSTRUCTORVEHICLESTATS, INSTRUCTORVEHICLESTATS_FAIL, INSTRUCTORVEHICLESTATS_SUCCESS, INSTRUCTORWISHLISTCOUNT, INSTRUCTORWISHLISTCOUNT_FAIL, INSTRUCTORWISHLISTCOUNT_SUCCESS, MODIFIEDHOURS, MODIFIEDHOURS_FAIL, MODIFIEDHOURS_SUCCESS, PACKAGES, PACKAGES_FAIL, PACKAGES_SUCCESS, PLANNINGHOURS, PLANNINGHOURS_FAIL, PLANNINGHOURS_SUCCESS } from "./actionTypes";


const initialState = {
    totalPackages: [],
    totalHours: [],
    cancledHours: [],
    modifiedHours: [],
    planningHours: [],
    erroredHours: [],
    instructorHours: [],
    instructorAbsentHours: [],
    instructorAverageHours: [],
    instructorWishlistCount: {},
    instructorVehicleStats: [],
    loading: false,
    success: false,
    error: ''
}

export const statisticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PACKAGES: return {
            ...state,
            totalPackages: [],
            loading: true,
            success: false,
            error: ''
        }
        case PACKAGES_SUCCESS: return {
            ...state,
            totalPackages: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case PACKAGES_FAIL: return {
            ...state,
            totalPackages: [],
            success: false,
            error: action.payload,
            loading: false
        }
        case HOURS: return {
            ...state,
            totalHours: [],
            loading: true,
            success: false,
            error: ''
        }
        case HOURS_SUCCESS: return {
            ...state,
            totalHours: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case HOURS_FAIL: return {
            ...state,
            totalHours: [],
            success: false,
            error: action.payload,
            loading: false
        }
        case PLANNINGHOURS: return {
            ...state,
            planningHours: [],
            loading: true,
            success: false,
            error: ''
        }
        case PLANNINGHOURS_SUCCESS: return {
            ...state,
            planningHours: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case PLANNINGHOURS_FAIL: return {
            ...state,
            planningHours: [],
            success: false,
            error: action.payload,
            loading: false
        }
        case MODIFIEDHOURS: return {
            ...state,
            modifiedHours: [],
            loading: true,
            success: false,
            error: ''
        }
        case MODIFIEDHOURS_SUCCESS: return {
            ...state,
            modifiedHours: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case MODIFIEDHOURS_FAIL: return {
            ...state,
            modifiedHours: [],
            success: false,
            error: action.payload,
            loading: false
        }
        case CANCLEDHOURS: return {
            ...state,
            cancledHours: [],
            loading: true,
            success: false,
            error: ''
        }
        case CANCLEDHOURS_SUCCESS: return {
            ...state,
            cancledHours: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case CANCLEDHOURS_FAIL: return {
            ...state,
            cancledHours: [],
            success: false,
            error: action.payload,
            loading: false
        }
        case ERROREDHOURS: return {
            ...state,
            erroredHours: [],
            loading: true,
            success: false,
            error: ''
        }
        case ERROREDHOURS_SUCCESS: return {
            ...state,
            erroredHours: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case ERROREDHOURS_FAIL: return {
            ...state,
            erroredHours: [],
            success: false,
            error: action.payload,
            loading: false
        }
        case INSTRUCTORHOURS: return {
            ...state,
            instructorHours: [],
            loading: true,
            success: false,
            error: ''
        }
        case INSTRUCTORHOURS_SUCCESS: return {
            ...state,
            instructorHours: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case INSTRUCTORHOURS_FAIL: return {
            ...state,
            instructorHours: [],
            success: false,
            error: action.payload,
            loading: false
        }
        case INSTRUCTORABSENTHOURS: return {
            ...state,
            instructorAbsentHours: [],
            loading: true,
            success: false,
            error: ''
        }
        case INSTRUCTORABSENTHOURS_SUCCESS: return {
            ...state,
            instructorAbsentHours: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case INSTRUCTORABSENTHOURS_FAIL: return {
            ...state,
            instructorAbsentHours: [],
            success: false,
            error: action.payload,
            loading: false
        }
        case INSTRUCTORAVERAGEHOURS: return {
            ...state,
            instructorAverageHours: [],
            loading: true,
            success: false,
            error: ''
        }
        case INSTRUCTORAVERAGEHOURS_SUCCESS: return {
            ...state,
            instructorAverageHours: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case INSTRUCTORAVERAGEHOURS_FAIL: return {
            ...state,
            instructorAverageHours: [],
            success: false,
            error: action.payload,
            loading: false
        }
        case INSTRUCTORWISHLISTCOUNT: return {
            ...state,
            instructorWishlistCount: {},
            loading: true,
            success: false,
            error: ''
        }
        case INSTRUCTORWISHLISTCOUNT_SUCCESS: return {
            ...state,
            instructorWishlistCount: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case INSTRUCTORWISHLISTCOUNT_FAIL: return {
            ...state,
            instructorWishlistCount: {},
            success: false,
            error: action.payload,
            loading: false
        }
        case INSTRUCTORVEHICLESTATS: return {
            ...state,
            instructorVehicleStats: [],
            loading: true,
            success: false,
            error: ''
        }
        case INSTRUCTORVEHICLESTATS_SUCCESS: return {
            ...state,
            instructorVehicleStats: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case INSTRUCTORVEHICLESTATS_FAIL: return {
            ...state,
            instructorVehicleStats: [],
            success: false,
            error: action.payload,
            loading: false
        }
        default:
            return state
    }
}