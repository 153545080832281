import React from "react";
import ReactApexChart from "react-apexcharts";

const GraphPage1 = ({ data }) => {

  const options = {
    chart: {
      type: "donut",
      height: 230,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#496ECC", "#FF9938", "#68E365"],
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: "12px",
      }
    },
    stroke: {
      show: false,
    }
  };

  return (
    <div id="chart" className="line-chart-style bar-chart">
      <ReactApexChart options={{ ...options, labels: data?.labels}} series={data.values} type="donut" height={400} />
    </div>
  );

}

export default GraphPage1;
