import React, { useState, useRef, useEffect } from "react";
import {
  BryntumCalendar,
  BryntumProjectModel,
} from "@bryntum/calendar-react";
import { DateHelper, StringHelper } from "@bryntum/calendar";
import { Modal, Button } from "react-bootstrap";
import { calendarConfig } from "./CalenderConfig";
import { connect } from "react-redux";
import { usersList } from "../../../../store/users/action";
import { getExamsList, updateExam, createExam, deleteExam, addStudentsInExam, deleteStudentsInExam } from "../../../../store/exams/action";
import { toast } from "react-toastify";
import Select from "react-select";
import { themeMultiSelectStyle, themeStyle } from "../../../../utils/style";
import moment from "moment";
import { studentList } from "../../../../store/students/action";
import ReactDatePicker from "react-datepicker";
import { utcToLocal } from "../../../../utils/constants";


function CalendarComponent({
  usersData,
  usersList,
  getExamsList,
  examsList,
  updateExam,
  createExam,
  deleteExam,
  studentData,
  studentList,
  addStudentsInExam,
  deleteStudentsInExam
}) {
  const calendar = useRef();
  const project = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedExamData, setSelectedExamData] = useState({})
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedInstructor, setSelectedInstructor] = useState({})
  const [instructorsList, setInstructorsList] = useState([])
  const [examStartDate, setExamStartDate] = useState('')
  const [examEndDate, setExamEndDate] = useState('')
  const [numberOfStudents, setNumberOfStudents] = useState(0)
  const [examName, setExamName] = useState('')
  const [examLocation, setExamLocation] = useState('')
  const [examMeetingPlace, setExamMeetingPlace] = useState('')
  const [examTypeOfPermission, setExamTypeOfPermission] = useState('')
  const [eventType, setEventType] = useState('saved')
  const [examStudents, setExamStudents] = useState([])
  const [examId, setExamId] = useState('')
  const [studentsOptions, setStudentsOptions] = useState({})
  const [selectedStudents, setSelectedStudents] = useState([])
  const [savedStudents, setSavedStudents] = useState([])


  // const [events] = useState(projectData.events);
  // const [resources] = useState(projectData.resources);

  const handleApproveClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setEventType('')
    setExamEndDate('')
    setExamLocation('')
    setExamMeetingPlace('')
    setExamStartDate('')
    setNumberOfStudents(0)
    setExamStudents('')
    setSelectedInstructor({})
    setExamTypeOfPermission('')
    setShowPopup(false);
  };

  const handleDeleteExam = async (data) => {
    try {
      if (data.eventRecords?.[0]?.data?.examId) {
        await deleteExam(data.eventRecords?.[0]?.data?.examId)
      }
      await getExamsList()
    } catch (error) {
      console.log(error)
      await getExamsList()
      notifyError('Error happened while deleting exam!')
      return false
    }
  }

  const handleEditExam = (data) => {
    setSelectedExamData(data);
    setShowPopup(true)
    setSelectedInstructor({
      label: data?.instructorName,
      value: data?.resourceId
    })
    setExamStartDate(data?.startDate)
    setExamEndDate(data?.endDate)
    setNumberOfStudents(data.numberOfStudents || 0)
    setExamName(data?.name)
    setExamLocation(data?.location || '')
    setExamMeetingPlace(data?.meetingPlace || '')
    setExamTypeOfPermission(data?.typeOfPermission || '')
    data?.examId ? setEventType('saved') : setEventType('create')
    setExamId(data?.examId || '')
    setSavedStudents(data.students?.map((studentData) => ({ label: studentData.studentExamPlanning.firstname, value: studentData?.studentExamPlanning?.id, data: studentData })) || [])
    setSelectedStudents(data.students?.map((studentData) => ({ label: studentData.studentExamPlanning.firstname, value: studentData?.studentExamPlanning?.id })) || [])
    data?.students?.length > 0 && setExamStudents(data.students.map((studentData) => studentData.studentExamPlanning.firstname).join(', '))
  }

  // Separate parameters for each feature
  calendarConfig.features.eventTooltip = {
    showOn: "click",
    align: "l-r",
    tools: {},

    minWidth: null,
    renderer: (data) => {
      return (
        <>
          <dl>
            <dt>Requested By:</dt>
            <dd>
              {data.eventRecord.resource.get("image") && (
                <img
                  className="resource-image"
                  src={`../_shared/images/users/${data.eventRecord.resource.get(
                    "image"
                  )}`}
                  alt=""
                />
              )}
              {StringHelper.encodeHtml(data.eventRecord.resource.name)}
            </dd>
            <dt>Time:</dt>
            <dd>
              {DateHelper.format(data.eventRecord.startDate, "LT")} -{" "}
              {DateHelper.format(data.eventRecord.endDate, "LT")}
            </dd>
            <dt>Students</dt>

            {
              (data.eventRecord?.students?.length > 0)
                ?
                data.eventRecord.students.map((studentData) => studentData.studentExamPlanning.firstname).join(', ')
                :
                'No students.'
              //   return <dd>{studentData.studentExamPlanning.firstname}</dd>
              // })
            }
          </dl>
          <button
            className="b-raised b-button b-blue"
            onClick={() => handleEditExam(data.eventRecord)}
          >
            Edit
          </button>
        </>
      );
    },
  };

  calendarConfig.features = {
    ...calendarConfig.features,

    eventEdit: {
      disabled: true,
    },
    eventMenu: {
      disabled: true
    },
    drag: {
      draggable: false,
      resizable: false
    }
  }

  // calendarConfig.listeners = {
  //   eventClick: ({eventRecord}) => {
  //     setSelectedExamData(eventRecord?.originalData)
  //     setShowPopup(true)
  //     // console.log(eventRecord)
  //   }
  // }

  useEffect(() => {
      usersList().catch(error => {
        notifyError(error?.payload);
      })
      getExamsList().catch(error => {
        notifyError(error?.payload);
      })
      studentList().catch(error => {
        notifyError(error?.payload);
      })
  }, [])

  useEffect(() => {
    const res = usersData.reduce((result, val) => {
      if (val?.userGroup === "Moniteurs" && val?.userDrivingschool) {
        result.push({
          id: val.id,
          name: val.firstname || val.lastname || val.email
        });
      }
      return result
    }, []);

    setInstructorsList(res.map((item) => {
      return { label: item.name, value: item.id }
    }))
    setResources(res);

    setEvents(
      examsList.map((val) => {
        return {
          id: val.id,
          startDate: utcToLocal(val.date_start),
          endDate: utcToLocal(val.date_end),
          // status: val.status,
          name: val.name,
          resourceId: val?.instructor_id,
          instructorName: val?.instructorExam?.firstname,
          students: val?.studentExam,
          numberOfStudents: val?.number_of_students,
          location: val?.location,
          meetingPlace: val?.meeting_place,
          typeOfPermission: val?.type_of_permission,
          examId: val.id
        }
      }) || []
    )
  }, [usersData, examsList])

  useEffect(() => {
    setStudentsOptions(studentData?.map((student) => ({ label: student.firstname + " " + student.lastname, value: student.id })))
  }, [studentData])


  // Notify messages
  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifySuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleCreateEvent = async (eventData) => {
    try {
      setEventType('create')
      setExamId('')
      setSelectedStudents([])
      setExamName(eventData.eventRecord?.data?.name || '')
      setExamStartDate(eventData.eventRecord?.data?.startDate)
      setExamEndDate(eventData.eventRecord?.data?.endDate)
      setShowPopup(true)
      setSelectedInstructor(instructorsList.find((instructorData) => {
        return instructorData?.value == eventData?.view?.initialConfig?.resourceId
      }) || {})
      setEvents([
        ...events,
        {
          id: eventData.eventRecord?.data?.id,
          startDate: eventData.eventRecord?.data?.startDate,
          endDate: eventData.eventRecord?.data?.endDate,
          name: eventData.eventRecord?.data?.name,
          resourceId: eventData.eventRecord?.data?.resourceId,
          instructorName: "",
          students: [],
          numberOfStudents: 0,
          location: "",
          meetingPlace: "",
          typeOfPermission: "",
          examId: eventData.eventRecord?.data?.examId
        }
      ])
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmitExam = async () => {
    try {

      let payload = {
        date_start: moment.utc(examStartDate).toDate(),
        date_end: moment.utc(examEndDate).toDate(),
        instructor_id: selectedInstructor?.value,
        number_of_students: numberOfStudents,
        meeting_place: examMeetingPlace,
        location: examLocation,
        name: examName,
        type_of_permission: examTypeOfPermission
      }

      if (eventType == 'saved') {
        // update exam with selected values
        await updateExam(examId, payload)

        let addStudentsIds = []
        let deleteStudentIds = []

        if (selectedStudents?.length > 0) {
          let selctedIds = selectedStudents.map((item) => item.value)
          let savedIds = savedStudents.map((item) => item?.value)
          selctedIds.map((item) => {
            if (!savedIds.includes(item)) {
              addStudentsIds.push(item)
            }
          })

          savedIds.map((item) => {
            if (!selctedIds.includes(item)) {
              deleteStudentIds.push(item)
            }
          })

        } else if ((savedStudents?.length > 0) && (selectedStudents?.length == 0)) {

          deleteStudentIds = savedStudents?.map((item) => item?.value)

        }

        if (addStudentsIds?.length) {
          await addStudentsInExam({
            ids: addStudentsIds,
            exam_id: examId
          })
        }

        if (deleteStudentIds?.length) {

          let deleteIds = []

          deleteStudentIds.map((item) => {
            let studentData = savedStudents.find((student) => {
              return student?.value == item
            })

            if (studentData?.data?.id) {
              deleteIds.push(studentData?.data?.id)
            }

          })
          await deleteStudentsInExam({
            ids: deleteIds
          })

        }




        setSavedStudents([])
        setShowPopup(false)
      } else if (eventType == 'create') {
        // create new exam if not exists
        let { data } = await createExam(payload)



        setExamId(data?.data?.id)
        setEventType('saved')

        if (selectedStudents?.length) {
          let addStudentsIds = selectedStudents?.map((item) => item.value)
          await addStudentsInExam({
            ids: addStudentsIds,
            exam_id: data?.data?.id
          })
        }
        setShowPopup(!showPopup)
      }

      notifySuccess('Exam saved successfully!')
      await getExamsList()

    } catch (error) {
      console.log(error)
      notifyError('Error happened while saving exam details!')
    }
  }

  return (
    <>
      <BryntumProjectModel
        ref={project}
        events={events}
        resources={resources}
      />
      <BryntumCalendar ref={calendar} project={project} onDragCreateEnd={handleCreateEvent} onBeforeEventDelete={handleDeleteExam} {...calendarConfig} />

      {/* POPUP MODAL */}
      <Modal show={showPopup} onHide={handlePopupClose}>
        <Modal.Body>
          <div className="basic-form">
            <form>
              <div className="row">
                <div className="form-group mb-3 col-md-12">
                  <label>Elèves</label>
                  {/* <input
                    type="text"
                    name="students"
                    className="form-control"
                    defaultValue={examStudents}
                    required
                    autoComplete="off"
                    disabled
                  /> */}
                  <Select
                    styles={themeMultiSelectStyle}
                    options={studentsOptions}
                    isMulti
                    defaultValue={savedStudents}
                    value={selectedStudents}
                    onChange={setSelectedStudents}
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Utilisateur</label>
                  <Select
                    styles={themeStyle}
                    options={instructorsList}
                    value={selectedInstructor}
                    onChange={setSelectedInstructor}
                  />
                  {/* <input
                    type="text"
                    name="users"
                    className="form-control"
                    value={"Mark Russo"}
                    required
                    autoComplete="off"
                  /> */}
                </div>
                {/* <div className="form-group mb-3 col-md-12">
                  <label>Type</label>
                  <input
                    type="text"
                    name="type"
                    className="form-control"
                    value={"Conduit"}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>boîte</label>
                  <input
                    type="text"
                    name="box"
                    className="form-control"
                    value={"Boite renguella"}
                    required
                    autoComplete="off"
                  />
                </div> */}
                <div className="form-group d-flex flex-column mb-3 col-md-12">
                  <label>Start date</label>
                  {/* <input
                    type="datetime-local"
                    step={60 * 15}
                    name="type"
                    className="form-control"
                    value={examStartDate}
                    onChange={(event) => setExamStartDate(event.target.value)}
                    required
                    autoComplete="off"
                  /> */}

                  <ReactDatePicker
                    showTimeSelect
                    timeIntervals={15}
                    id='date-time-picker'
                    dateFormat='MM/dd/yyyy h:mm aa'
                    selected={ examStartDate ? moment(examStartDate, 'YYYY-MM-DDTHH:mm').toDate() : moment().toDate()}
                    onChange={(date) => {setExamStartDate(date)}}
                    className="form-control"
                  />
                </div>
                <div className="form-group d-flex flex-column mb-3 col-md-12">
                  <label>End date</label>
                  {/* <input
                    type="datetime-local"
                    name="type"
                    className="form-control"
                    value={examEndDate}
                    onChange={(event) => { setExamEndDate(event.target.value) }}
                    required
                    autoComplete="off"
                  /> */}
                  <ReactDatePicker
                    showTimeSelect
                    timeIntervals={15}
                    id='date-time-picker'
                    dateFormat='MM/dd/yyyy h:mm aa'
                    selected={examEndDate ? moment(examEndDate).toDate() : moment().toDate()}
                    onChange={(date) => {setExamEndDate(date)}}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Number of students</label>
                  <input
                    type="number"
                    name="box"
                    className="form-control"
                    value={numberOfStudents}
                    onChange={(event) => setNumberOfStudents(event.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Name</label>
                  <input
                    type="text"
                    name="box"
                    className="form-control"
                    value={examName}
                    onChange={(event) => setExamName(event.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Location</label>
                  <input
                    type="text"
                    name="box"
                    className="form-control"
                    value={examLocation}
                    onChange={(event) => setExamLocation(event.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Meeting place</label>
                  <input
                    type="text"
                    name="box"
                    className="form-control"
                    value={examMeetingPlace}
                    onChange={(event) => setExamMeetingPlace(event.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Type of permission</label>
                  <input
                    type="text"
                    name="box"
                    className="form-control"
                    value={examTypeOfPermission}
                    onChange={(event) => setExamTypeOfPermission(event.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                {/* <div className="form-group mb-3 col-md-12">
                  <label>Commnetaire</label>
                  <textarea
                    type="text"
                    name="comments"
                    className="form-control"
                    rows="3"
                    value={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eu sodales nibh. Nullam at vehicula nisi. Quisque iaculis, enim sed auctor hendrerit, metus augue dictum ligula, in lacinia urna libero ut nibh."}
                    required
                    autoComplete="off"
                    style={{ minHeight: "120px" }}
                  />
                </div> */}
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleSubmitExam}>
            Approuver
          </Button>
          <Button variant="danger" onClick={handlePopupClose}>
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    usersData: state.users.users,
    examsList: state.exams.examsList,
    studentData: state.student.students
  }
}

const mapDispatchToProps = {
  usersList,
  getExamsList,
  updateExam,
  createExam,
  deleteExam,
  studentList,
  addStudentsInExam,
  deleteStudentsInExam
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComponent);
