export const GET_EXAMS = 'GET_EXAMS' 
export const GET_EXAMS_SUCCESS = 'GET_EXAMS_SUCCESS' 
export const GET_EXAMS_FAIL = 'GET_EXAMS_FAIL'

export const UPDATE_EXAM = 'UPDATE_EXAMS'
export const UPDATE_EXAM_SUCCESS = 'UPDATE_EXAMS_SUCCESS'
export const UPDATE_EXAM_FAIL = 'UPDATE_EXAMS_FAIL'

export const CREATE_EXAM = 'CREATE_EXAMS'
export const CREATE_EXAM_SUCCESS = 'CREATE_EXAMS_SUCCESS'
export const CREATE_EXAM_FAIL = 'CREATE_EXAMS_FAIL'

export const DELETE_EXAM = 'DELETE_EXAMS'
export const DELETE_EXAM_SUCCESS = 'DELETE_EXAMS_SUCCESS'
export const DELETE_EXAM_FAIL = 'DELETE_EXAMS_FAIL'

export const ADD_STUDENTS = 'ADD_STUDENTS'
export const ADD_STUDENTS_SUCCESS = 'ADD_STUDENTS_SUCCESS'
export const ADD_STUDENTS_FAIL = 'ADD_STUDENTSS_FAIL'

export const DELETE_STUDENTS = 'DELETE_STUDENTS'
export const DELETE_STUDENTS_SUCCESS = 'DELETE_STUDENTS_SUCCESS'
export const DELETE_STUDENTS_FAIL = 'DELETE_STUDENTSS_FAIL'