import { RECEIPTS, RECEIPTS_SUCCESS, RECEIPTS_FAILED } from "./actionTypes";

const initialState = {
    receipts: [],
    loading: false,
    success: false,
    error: ''
}

export const receiptsReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIPTS: return {
            ...state,
            receipts: [],
            loading: true,
            success: false,
            error: ''
        }
        case RECEIPTS_SUCCESS: return {
            ...state,
            receipts: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case RECEIPTS_FAILED: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        default:
            return state
    }
}