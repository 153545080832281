import { DASHBOARD, DASHBOARD_FAILED, DASHBOARD_SUCCESS } from "./actionTypes"

let initValue={
    list:[],
    loading:false,
    success:false,
    error:''
}

export const dashboardReducer = (state=initValue,action) => {
    switch(action.type){
        case DASHBOARD:return {
            ...state,
            list:[],
            loading:true,
            success:false,
            error:''
        }
        case DASHBOARD_SUCCESS : return {
            ...state,
            list : action?.payload,
            loading:false,
            success:true,
            error:''
        }
        case DASHBOARD_FAILED : return {
            ...state,
            list:[],
            success:false,
            error:action?.payload
        }
        default : return state;
    }
}