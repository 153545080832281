import { ALERTS, ALERTS_FAILED, ALERTS_SUCCESS } from "./actionType";
import { alerts } from "../../utils/urls";
import { api } from "../../utils/constants";

export const alertsList=()=>async dispatch =>{
    dispatch({
        type:ALERTS
    })
    try {
        let response = await api(alerts(),'get',{});
        dispatch({
            type: ALERTS_SUCCESS,
            payload: response?.data?.data
        })
        return response
    } catch (error) {
        console.log("fetching ALERTS error is ===>", error);
        return Promise.reject(dispatch({
            type: ALERTS_FAILED,
            payload: error?.response?.data?.message || error?.message
        }))
    }
}