import React, { useEffect, useState } from "react";
import FilteringTable from "./component/FilteringTable/FilteringTable";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Card, Modal, Button } from "react-bootstrap";
import { vehicleList, deleteVehicle } from "../../../../store/vehicles/action";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Vehicles = ({ list, loading, listVehicles, deleteVehicle }) => {
  const [parsedVehicles, setParsedVehicles] = useState([]);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dataLoading();
  }, []);
  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifySuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;

    return (
      <div>
        <input
          className="form-control input-search"
          value={filterValue || ""}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
    );
  };

  const dataLoading = async () => {
    try {
      await listVehicles();
    } catch (error) {
      console.log("Error: for vehicles data laoding ==>", error);
      notifyError(
        `Vehicled data loading failed, Error: ${error?.payload || error}`
      );
    }
  };

  const handleDeleteVehicle = async (id) => {
    try {
      await deleteVehicle(id);
      notifySuccess("Vehicle deleted Successfully!");
      await listVehicles();
    } catch (error) {
      console.log(error, "<=== error for delete");
      notifyError(`Vehicle delete failed, Error: ${error?.payload}`);
    }
  };

  const confirmDelete = async () => {
    if (selectedVehicleId) {
      await handleDeleteVehicle(selectedVehicleId);
      closeDeleteModal();
    }
  };

  const openDeleteModal = (recordId) => {
    setSelectedVehicleId(recordId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedVehicleId(null);
  };

  const COLUMNS = [
    {
      Header: "Brand",
      Footer: "Brand",
      accessor: "name",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <Link
            className="d-flex align-items-center"
            to={`/View-Vehicle/${row.original.id}`}
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
          >
            {row.original.name}
          </Link>
        );
      },
    },
    {
      Header: "Registration",
      Footer: "Registration",
      accessor: "immatriculation",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <Link
            className="d-flex align-items-center"
            to={`/View-Vehicle/${row.original.id}`}
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
          >
            {row.original.immatriculation}
          </Link>
        );
      },
    },
    {
      Header: "Current User",
      Footer: "Current User",
      accessor: "instructor",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        return (
          <div className="d-flex align-items-center">
            <img src="" className="rounded-lg me-2" width="24" alt="" />{" "}
            <span className="w-space-no">{value}</span>
          </div>
        );
      },
    },
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }) => {
        return (
          <div className="d-flex">
            <Link
              className="btn btn-primary shadow btn-xs sharp me-1"
              to={`/Edit-Vehicle/${row.original.id}`}
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
            <button
              className="btn btn-danger shadow btn-xs sharp"
              onClick={(e) => {
                openDeleteModal(row.original.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    list && list?.length > 0 ? setParsedVehicles(list) : setParsedVehicles([]);
  }, [list]);

  return (
    <>
      <ToastContainer />
      <div id="VehiclePage">
        <Link className="me-2 mb-2 btn btn-primary" to="/Add-Vehicle">
          Ajouter un véhicule
        </Link>

        <Card className="mt-3">
          <FilteringTable
            vehicles={parsedVehicles}
            headerColumns={COLUMNS}
            isLoading={loading}
          />
        </Card>
      </div>
      <Modal
        show={showDeleteModal}
        onHide={closeDeleteModal}
        className="fade"
        style={{ position: "absolute", top: "25%" }}
      >
        <Modal.Header>
          <Modal.Title>Confirmation de la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer ce véhicule ?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closeDeleteModal}>
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.vehicle.vehicles,
    loading: state.vehicle.loading,
  };
};

const mapDispatchToProps = {
  listVehicles: vehicleList,
  deleteVehicle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);
