export const themeStyle = {
    control: (provided, state) => ({
        ...provided,
        lineHeight: 1.5,
        // color: "#fff",
        // backgroundColor: "#2a2a2a",
        borderColor: "#525558",
        boxShadow: "unset",
        height: "3rem",
        borderRadius: "0.625rem",
        "&:hover": {
            borderColor: "#525558",
        },
        "&:active": {
            borderColor: "#525558",
            boxShadow: "unset",
        },
    }),
    placeholder: (provided, state) => ({
        ...provided,
        // color: '#fff',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        // color: '#fff',
    }),
    indicatorSeparator: style => ({
        ...style,
        display: 'none'
    })
}

export const themeMultiSelectStyle = {
    control: (provided, state) => ({
        ...provided,
        lineHeight: 1.5,
        // color: "#fff",
        // backgroundColor: "#2a2a2a",
        borderColor: "#eee",
        boxShadow: "unset",
        minHeight: "3rem",
        borderRadius: "0.625rem",
        height: 'auto',
        overflow: 'overlay',
        "&:hover": {
            borderColor: "#525558",
        },
        "&:active": {
            borderColor: "#525558",
            boxShadow: "unset",
        },
    }),
    placeholder: (provided, state) => ({
        ...provided,
        // color: '#fff',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        // color: '#fff',
    }),
    multiValueLabel: style => ({
        ...style,
        transition: 'all 0.3s ease'
    }),
    multiValueRemove: style => ({
        ...style,
        color: '#ff0000',
        backgroundColor: '#e6e6e6',
        transition: 'all 0.3s ease',
        '&:hover': {
            backgroundColor: '#ff0000',
            color: '#e6e6e6'
        }
    }),
    indicatorSeparator: style => ({
        ...style,
        display: 'none'
    })
}
