import { ADDUSER, ADDUSER_FAILED, ADDUSER_SUCCESS, DELETEUSER, DELETEUSER_FAILED, DELETEUSER_SUCCESS, SINGLEUSER, SINGLEUSER_FAILED, SINGLEUSER_SUCCESS, USERS, USERS_FAILED, USERS_SUCCESS, UPDATEUSER, UPDATEUSER_FAILED, UPDATEUSER_SUCCESS, VERIFY_EMAIL, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAIL, VERIFY_OTP, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAIL, UPDATE_PASSWORD, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAIL, CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL } from "./actionType";

let initialState = {
    users: [],
    user: {},
    addUser: '',
    updateUser: '',
    loading: false,
    success: false,
    error: '',
}

export function UsersReducer (state=initialState, action) {
    switch (action.type) {
        case USERS: return {
            ...state,
            users: [],
            loading: true,
            success: false,
            error: '',
        }
        case USERS_SUCCESS: return {
            ...state,
            users: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case USERS_FAILED: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case ADDUSER: return {
            ...state,
            addUser: '',
            loading: true,
            success: false,
            error: '',
        }
        case ADDUSER_SUCCESS: return {
            ...state,
            addUser: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case ADDUSER_FAILED: return {
            ...state,
            addUser: '',
            loading: false,
            success: false,
            error: action.payload
        }
        case UPDATEUSER: return {
            ...state,
            updateUser: '',
            loading: true,
            success: false,
            error: '',
        }
        case UPDATEUSER_SUCCESS: return {
            ...state,
            updateUser: action.payload,
            loading: false,
            success: true,
            error: '',
        }
        case UPDATEUSER_FAILED: return {
            ...state,
            updateUser: '',
            loading: false,
            success: false,
            error: action.payload
        }
        case DELETEUSER : return {
            ...state,
            loading: true,
            success: false,
            error:''
        }
        case DELETEUSER_SUCCESS : return {
            ...state,
            loading: false,
            success: true,
            error: '',
        }
        case DELETEUSER_FAILED : return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case SINGLEUSER : return {
            ...state,
            user: {},
            loading: true,
            success: false,
            error: ''
        }
        case SINGLEUSER_SUCCESS : return {
            ...state,
            user: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case SINGLEUSER_FAILED : return {
            ...state,
            user: {},
            loading: false,
            success: false,
            error: action.payload
        }
        case VERIFY_EMAIL : return {
            ...state,
            loading: true,
            success: false,
            error: ''
        }
        case VERIFY_EMAIL_SUCCESS: return {
            ...state,
            loading: false,
            success: true,
            error: ''
        }
        case VERIFY_EMAIL_FAIL: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case VERIFY_OTP : return {
            ...state,
            verifyEmail: {},
            loading: true,
            success: false,
            error: ''
        }
        case VERIFY_OTP_SUCCESS: return {
            ...state,
            loading: false,
            success: true,
            error: ''
        }
        case VERIFY_OTP_FAIL: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case UPDATE_PASSWORD : return {
            ...state,
            loading: true,
            success: false,
            error: ''
        }
        case UPDATE_PASSWORD_SUCCESS: return {
            ...state,
            loading: false,
            success: true,
            error: ''
        }
        case UPDATE_PASSWORD_FAIL: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        case CHANGE_PASSWORD : return {
            ...state,
            loading: true,
            success: false,
            error: ''
        }
        case CHANGE_PASSWORD_SUCCESS: return {
            ...state,
            loading: false,
            success: true,
            error: ''
        }
        case CHANGE_PASSWORD_FAIL: return {
            ...state,
            loading: false,
            success: false,
            error: action.payload
        }
        default:
            return state
    }
}