// export const baseUrl = "/api";
export const baseUrl = process.env.NODE_ENV === 'development' ? "" : "/api";

export const dashboard=()=>`/dashboard/`
export const student = (id='') => `/students/${id}`;
export const vehicle = (id='') => `/vehicles/${id}`;
export const login = () => `/auth/login`;
export const drivingSchool = (id='') => `/drivingSchools/${id}`;
export const users = (id='') => `/users/${id}`; 
export const alerts=()=> `/alerts/`
export const debits=(id='')=> `/debits/${id}`;
export const monitors=()=> `/monitors/`
export const offers=(id='')=> `/offers/${id}`
export const receipts=(startDate, endDate)=> `/receipts?startDate=${startDate}&endDate=${endDate}`
export const permissions=()=> `/permissions/`
export const roles=()=> `/roles/`
export const planning = (id='')=> `/planning/${id}`
export const exams = (id='')=> `/exams/${id}`
export const addStudentsInOneExam = ()=> `/exams/addstudents`
export const deleteStudentsInOneExam = ()=> `/exams/deletestudents`
export const examWishlists = (id='')=> `/examWishlists/${id}`
export const typesVehicle=()=>`/vehicles/types`;
export const formula = (id='') => `/formula/${id}`
export const licenece = () => `/licences/`
export const verify = (id='') => `/auth/verify/${id}`
export const savePassword = () => `/auth/save/password`
export const passwordChange = () => `/auth/change/password`
export const statistics = (id="") => `/statistics/${id}`