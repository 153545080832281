import React, { useEffect, useState } from "react";
import GraphPage1 from "./GraphPage1";
import GraphPage2 from "./GraphPage2";
import { connect } from "react-redux";
import { getCancledHours } from "../../../../../store/statistics/action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Nav, Spinner, Tab } from "react-bootstrap";

const Tab5 = ({ cancledHours, getCancledHours, loading }) => {
  const [montlyTotalHours, setMonthlyTotalHours] = useState([]);
  const [yearlyTotalHours, setYearlyTotalHours] = useState([]);

  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    getCancledHours().catch((error) => {
      notifyError(`While Fetching Total Hours Error is: => ${error}`);
    });
  }, []);

  useEffect(() => {
    if (cancledHours.monthly && cancledHours.yearly && !loading) {
      var monthData = [];
      var yearData = [];

      const monthNames = {
        1: "Janvier",
        2: "Février",
        3: "Mars",
        4: "Avril",
        5: "Mai",
        6: "Juin",
        7: "Juillet",
        8: "Août",
        9: "Septembre",
        10: "Octobre",
        11: "Novembre",
        12: "Décembre",
      };
      for (let i = 1; i <= 12; i++) {
        const monthName = monthNames[i];
        monthData.push({
          month: monthName,
          total_hours: 0,
          monthIndex: i,
        });
      }
      monthData.forEach((item) => {
        cancledHours?.monthly?.forEach((month) => {
          if (month.month === item.monthIndex) {
            item.total_hours = month.total_hours;
          }
        });
      });

      cancledHours?.yearly?.forEach((item) => {
        yearData.push({
          year: item.year,
          total_hours: item.total_hours,
        });
      });
    }
    setMonthlyTotalHours(monthData);
    setYearlyTotalHours(yearData);
  }, [cancledHours, loading]);

  const tabData = [
    {
      name: "month",
      content: <GraphPage1 data={montlyTotalHours} />,
    },
    {
      name: "year",
      content: <GraphPage2 data={yearlyTotalHours} />,
    },
  ];

  return (
    <>
      <ToastContainer />
      <div>
        <div className="col-12">
          <Card>
            <Card.Body>
              {loading && (
                <div
                  className="text-center"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ borderRadius: "50%" }}
                  ></Spinner>
                  <span>Loading...</span>
                </div>
              )}
              {!loading && (
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav
                    as="ul"
                    className="nav-pills mb-4 justify-content-between"
                  >
                    <h5>In interdum mauris sit amet porttitor pretium</h5>
                    <div className="d-flex">
                      {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                          <Nav.Link className="nav-link-tab" eventKey={data.name.toLowerCase()}>
                            {i === 0 ? "Mensuel" : i === 1 ? "Annuel" : ""}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </div>
                  </Nav>
                  <Tab.Content>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cancledHours: state?.statistics?.cancledHours,
    loading: state?.statistics?.loading,
  };
};
const mapDispatchToProps = {
  getCancledHours,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab5);
