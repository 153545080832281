import React from 'react'
import CalendarComponent from './components/Calender'

const Planning = () => {
  return (
    <div>
        <h2>Examens</h2>
        <CalendarComponent />
    </div>
  )
}

export default Planning
