import React, { useState, useRef } from "react";
import {
  BryntumCalendar,
  BryntumDemoHeader,
  BryntumThemeCombo,
  BryntumProjectModel,
} from "@bryntum/calendar-react";
import { DateHelper, StringHelper } from "@bryntum/calendar";
import { Modal , Button} from "react-bootstrap";
import { calendarConfig } from "./CalenderConfig";
import { projectData } from "./CalenderData";

function CalendarComponent() {
  const calendar = useRef();
  const project = useRef();
  const [showPopup, setShowPopup] = useState(false);

  const [events] = useState(projectData.events);
  const [resources] = useState(projectData.resources);

  return (
    <>
      <BryntumProjectModel
        ref={project}
        events={events}
        resources={resources}
      />
      <BryntumCalendar ref={calendar} project={project} {...calendarConfig} />

    </>
  );
}

export default CalendarComponent;
