import {
  MONITORS,
  MONITORS_SUCCESS,
  MONITORS_FAIL,
  ADD_MONITORS,
  ADD_MONITORS_SUCCESS,
  ADD_MONITORS_FAIL,
} from "./actionTypes";
import { monitors } from "../../utils/urls";
import { api } from "../../utils/constants";

export const monitorsList = () => async (dispatch) => {
  dispatch({
    type: MONITORS,
  });
  try {
    let response = await api(monitors(), "get", {});
    dispatch({
      type: MONITORS_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    console.log("fetching Monitors error is ===>", error);
    return Promise.reject(
      dispatch({
        type: MONITORS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};

export const addMonitor = (data,headers) => async (dispatch) => {
  dispatch({
    type: ADD_MONITORS,
  });
  try {
    let response = await api(monitors(), 'post', data,headers);
    dispatch({
      type: ADD_MONITORS_SUCCESS,
      payload: response?.data?.data,
    });
    return response;
  } catch (error) {
    console.log("Adding Monitors error is ===>", error);
    return Promise.reject(
      dispatch({
        type: ADD_MONITORS_FAIL,
        payload: error?.response?.data?.message || error?.message,
      })
    );
  }
};
