import { api } from '../../utils/constants';
import { roles } from '../../utils/urls';
import {ROLES, ROLES_SUCCESS, ROLES_FAILED } from './actionTypes'


export const rolesList = () => async (dispatch) => {
    dispatch({
        type: ROLES,
    });
    try {
        let response = await api(roles(), 'get', {});
        dispatch({
            type: ROLES_SUCCESS,
            payload:response?.data?.data,
        });
        return response;
    } catch (error) {
        console.log("fetching roles error is ===>", error);
        return Promise.reject(
            dispatch({
                type: ROLES_FAILED,
                payload: error?.response?.data?.message || error?.message,
            })
        )
    }
};