import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import { StudentReducer } from './students/studentReducer';
import { VehicleReducer } from './vehicles/vehicleReducer';
import { DrivingSchoolsReducer } from './drivingSchools/drivingSchoolsReducer';
import { UsersReducer } from './users/usersReducer';
import { AlertReducer } from './alerts/alertsReducer';
import { DebitReducer } from './debits/debitReducers';
import { MonitorsReducer } from './monitors/monitorsReducer';
import { offersReducer } from './offers/offersReducer';
import { receiptsReducer } from './receipts/receiptsReducer';
import { rolesReducer } from './roles/rolesReducer';
import { permissionsReducer } from './permissions/permissionsReducer';
import { planningReducer } from './planning/planningReducers';
import { dashboardReducer } from './dashboard/dashboardReducer';
import { formulaReducer } from './formula/formulaReducer';
import { examsReducer } from './exams/examsReducer';
import { examWishlistReducer } from './examWishlists/examWishlistReducer';
import { licencesReducer } from './licences/licencesReducer';
import { statisticsReducer } from './statistics/statisticsReducer';

const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    auth: AuthReducer,
    student: StudentReducer,
    drivingSchool: DrivingSchoolsReducer,
    users: UsersReducer	,
    alerts: AlertReducer,
    debits:DebitReducer,
    vehicle: VehicleReducer,
    monitors: MonitorsReducer,
    offers:offersReducer,
    receipts: receiptsReducer,
    role: rolesReducer,
    permission: permissionsReducer,
    planning : planningReducer,
    dashboard: dashboardReducer,
    formulas: formulaReducer,
    exams: examsReducer,
    examWishlist: examWishlistReducer,
    licences : licencesReducer,
    statistics: statisticsReducer
});

export const store = createStore(reducers,  composeEnhancers(middleware));
