import React from 'react'
import { useTable, useFilters, useExpanded } from 'react-table';
import "../filtering.css";
import { Spinner } from 'react-bootstrap'; 

const ExpandebleTable = ({
    datas,
    headerColumn,
    innerHeaderColumn,
    isLoading,
    expandedRows,
    studentFormulaTotalAmount,
    studentFormulaTotalHours,
    handleTranscationPrint
}) => {
    const columns = headerColumn;
    const data = datas;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { expanded },
    } = useTable(
        {
            columns,
            data,
        },
        useExpanded,
        useFilters
    )

    return (
        <>
            <div className="table-responsive">
                <table {...getTableProps()} className="table display">
                    <thead>
                        {headerGroups?.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers?.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                        {column.canFilter ? column.render('Filter') : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="" >
                        {isLoading ? (
                            <tr>
                                <td colSpan={headerColumn?.length + 5}>
                                    <div className="text-center" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '15px' }}>
                                        <Spinner animation="border" role="status" style={{ borderRadius: '50%' }}>
                                        </Spinner>
                                        <span>Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        ) :
                            !rows?.length > 0 ? (
                                <tr>
                                    <td colSpan={headerColumn?.length + 1} style={{ textAlign: 'center' }}>No Records</td>
                                </tr>
                            ) :
                                (
                                    <>
                                        {rows?.map((row) => {
                                            prepareRow(row);
                                            const totalAmount = row.original.amount
                                            const isExpanded = expandedRows.includes(row.id);
                                            const innerData = row.original.formulaPayment.data;
                                            const innerTotal = row.original.formulaPayment.total;
                                            const type = row.original.type;
                                            return (
                                                <React.Fragment key={row.id}>
                                                    <tr {...row.getRowProps()} style={type === "Forfait" ? {backgroundColor: "#ADD8E6"} : type === "Hors Forfait" ? {backgroundColor: "#FFB6C1"} : {backgroundColor: "#fff"}}>
                                                        {row.cells?.map((cell, index) => {
                                                            if (index == 0) {
                                                                return (
                                                                    <td {...cell.getCellProps()}> { totalAmount != innerTotal ? <i className='fa fa-warning text-danger' ></i> : null }</td>
                                                                )
                                                            } else {
                                                                return (
                                                                    <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
                                                                )
                                                            }
                                                        })}
                                                    </tr>
                                                    {isExpanded && (
                                                        <tr>
                                                            <td colSpan={headerColumn?.length}>
                                                                <div className="table-responsive" style={{ paddingLeft: '4rem' }}>
                                                                    <table className='table display rounded' style={{ backgroundColor: '' }} >
                                                                        <thead>
                                                                            <tr>
                                                                                {innerHeaderColumn?.map((column, index) => (
                                                                                    <th key={index}>{column.Header}</th>
                                                                                ))}
                                                                                <th>Imprimer</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {innerData?.length > 0 && (
                                                                                <>
                                                                                    {innerData.map((innerRow, index) => (
                                                                                        <tr key={index}>
                                                                                            {innerHeaderColumn.map((column, innerIndex) => (
                                                                                                <td key={innerIndex}>{innerRow[column.accessor]}</td>
                                                                                            ))}
                                                                                            <td>
                                                                                                <div className='d-flex'>
                                                                                                    <button
                                                                                                        className='btn btn-transparent sharp'
                                                                                                        onClick={() => handleTranscationPrint(innerRow)}
                                                                                                        style={{ color: 'white', padding: 0, fontSize: '16px' }}
                                                                                                    >
                                                                                                        <i className="fa-solid fa-print"></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                    <tr style={{ background: '#ff5c23', width: '100%' }}>
                                                                                        <td colSpan={innerHeaderColumn?.length}>
                                                                                            <div className="d-flex b-text-align-left text-white">Total</div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="d-flex text-white">
                                                                                                {innerTotal}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                        <tr style={{ background: '#ff5c23' }}>
                                            <td colSpan={headerColumn?.length - 1}>
                                                <div className="d-flex b-text-align-left text-white">Total</div>
                                            </td>
                                            <td>
                                                <div className="d-flex w-100 justify-content-center align-items-center text-white">
                                                    {studentFormulaTotalAmount}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ background: '#475d72' }}>
                                            <td colSpan={headerColumn?.length - 1}>
                                                <div className="d-flex b-text-align-left text-white">Total heures forfait</div>
                                            </td>
                                            <td>
                                                <div className="d-flex w-100 justify-content-center align-items-center text-white">
                                                    {studentFormulaTotalHours}
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )}
                    </tbody>
                </table>
            </div >
        </>
    )
}

export default ExpandebleTable
