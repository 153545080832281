import { RECEIPTS, RECEIPTS_SUCCESS, RECEIPTS_FAILED } from "./actionTypes";
import { api } from "../../utils/constants";
import { receipts } from "../../utils/urls";

export const receiptList = (startDate, endDate) => async (dispatch) => {
    dispatch({
        type: RECEIPTS,
    });
    try {
        let response = await api(receipts(startDate, endDate), "get", {});
        dispatch({
            type: RECEIPTS_SUCCESS,
            payload: response?.data?.data,
        })
        return response;
    } catch (error) {
        console.log("Error: while fetching receipt ==>", error);
        return Promise.reject(
            dispatch({
                type: RECEIPTS_FAILED,
                payload: error?.response?.data?.message || error?.message,
            })
        );
    }
};