import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import FilteringTable from "./components/FilteringTable";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import { usersList } from "../../../store/users/action";
import { studentList } from "../../../store/students/action";
import {
  getExamWishlists,
  updateExamWishlist,
} from "../../../store/examWishlists/action";

const Planning = ({
  usersData,
  usersList,
  getExamWishlists,
  examWishlist,
  updateExamWishlist,
  loading,
  studentList,
  list,
}) => {
  let [examWishlistData, setExamWishlistData] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);

  // Data Fetching
  useEffect(() => {
    if (!usersData.length) {
      usersList().catch((error) => {
        notifyError(error?.payload);
      });
    }
    getExamWishlists().catch((error) => {
      notifyError(error?.payload);
    });
    if (!list.length) {
      studentList().catch((error) => {
        notifyError(error?.payload);
      });
    }
  }, []);

  // Data loading and Formatting
  useEffect(() => {
    let wishlistData =
      examWishlist
        .filter((item) => item.status === "pending")
        .map((val) => {
          console.log(val, "<<<<<Value");
          console.log(list, "<<<<<list");
          const instructorName = `${val?.instructorExamWishlist?.firstname} ${val?.instructorExamWishlist?.lastname}`;
          const studentName = `${val?.studentExamWishlist?.firstname} ${val?.studentExamWishlist?.lastname}`;
          const examType = list.map((item) => {
            if (item?.id === val?.student_id) {
              return item?.licenceStudents?.name;
            }
          });
          return {
            id: val.id,
            startDate: moment.utc(val.date_start).toDate(),
            endDate: moment.utc(val.date_end).toDate(),
            instructorName: instructorName,
            student: studentName,
            examType,
            name: "Request",
          };
        }) || [];
    setExamWishlistData(wishlistData);
  }, [loading, list]);

  // Notify messages
  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifySuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Handle updation
  const handleApprove = async (data) => {
    try {
      setActionLoading(true);
      let result = await updateExamWishlist(data, { status: "approved" });
      notifySuccess("Approved");
      await getExamWishlists();
    } catch (error) {
      notifyError("Can't Approve");
      console.log(error, "<<<Error While Approving");
    } finally {
      setActionLoading(false);
    }
  };
  const handleReject = async (data) => {
    try {
      setActionLoading(true);
      let result = await updateExamWishlist(data, { status: "rejected" });
      notifySuccess("Rejected");
      await getExamWishlists();
    } catch (error) {
      notifyError("Can't Reject");
      console.log(error, "<<<Error While Rejecting");
    } finally {
      setActionLoading(false);
    }
  };

  // Table Column
  const COLUMNS = [
    {
      Header: "Instructor",
      Footer: "Instructor",
      accessor: "instructorName",
    },
    {
      Header: "Student",
      Footer: "Student",
      accessor: "student",
    },
    {
      Header: "Start Date",
      Footer: "Start Date",
      accessor: "startDate",
      Cell: ({ value }) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      Header: "End Date",
      Footer: "End Date",
      accessor: "endDate",
      Cell: ({ value }) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      Header: "Exam Type",
      Footer: "Exam Type",
      accessor: "examType",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "name",
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: ({ row }) => {
        const recordId = row.original.id;
        return (
          <div className="d-flex">
            <div
              className="btn-sm btn-info me-2"
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => handleApprove(recordId)}
            >
              Accept
            </div>
            <div
              className="btn-sm btn-danger"
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => handleReject(recordId)}
            >
              Reject
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <ToastContainer />
      <div>
        <h2>Exam Wishlist</h2>
        <Card className="mt-3">
          <Card.Body>
            <FilteringTable
              datas={examWishlistData}
              headerColumn={COLUMNS}
              isLoading={loading}
            />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    usersData: state.users.users,
    examWishlist: state.examWishlist.examWishlist,
    loading: state.examWishlist.loading,
    list: state.student.students,
  };
};

const mapDispatchToProps = {
  usersList,
  getExamWishlists,
  updateExamWishlist,
  studentList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Planning);
