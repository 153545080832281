import React from "react";
import ReactApexChart from "react-apexcharts";

const GraphPage1 = ({ data }) => {
  if (!data?.length) {
    return (
      <>
        <p>No data to show graph</p>
      </>
    );
  }
  const series = [
    {
      name: "heures totales",
      data: data?.map((item) => item?.totalHours),
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 230,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#F44336"],
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: "12px",
      },
    },
    stroke: {
      show: false,
    },
    yaxis: {
      lines: {
        show: false,
      },
      title: {
        text: "Nombre d'heures vendues",
        style: {
          fontSize: "12px",
          fontWeight: 600,
          cssClass: "y-axis-title",
          color: "white",
        },
      },
    },
    xaxis: {
      show: false,
      categories: data?.map((item) => item?.year),
      title: {
        text: "Type de forfait",
        style: {
          fontSize: "14px",
          fontWeight: 600,
          cssClass: "x-axis-title",
        },
      },
    },
  };

  return (
    <div id="chart" className="line-chart-style bar-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={400}
      />
    </div>
  );
};

export default GraphPage1;
