import React, { useEffect, useState } from 'react'
import { Container, Navbar } from 'react-bootstrap'
import Select from 'react-select'
import { themeStyle } from '../../../utils/style'
import { connect, useDispatch } from 'react-redux'
import { Logout } from '../../../store/actions/AuthActions'
import { useNavigate } from 'react-router-dom'
import { studentList } from '../../../store/students/action'

const HeaderNew = (props) => {

    const { loading, list, listStudent } = props
    const navigate = useNavigate()


    const [studentOptions, setStudentOptions] = useState([])

    useEffect(() => {
        if (!list?.length) {
            listStudent()
        }
    }, []);

    useEffect(() => {
        if (list?.length) {
            setStudentOptions(list?.map(item => ({ label: `${item?.firstname + " " + item?.lastname}`, value: item?.id })))
        } else {
            setStudentOptions([])
        }
    }, [list])


    const handleSelectStudent = (option) => {
        navigate(`/View-Student/${option.value}`)
    }

    const dispatch = useDispatch();
    function onLogout() {
        dispatch(Logout(navigate));
    }

    return (
        <Navbar expand='lg' style={{ background: "linear-gradient(30deg, #d12924, #60090c)" }} >
            <Container className="" >
                <Navbar.Brand onClick={() =>  navigate('/dashboard')} className='cursor-pointer' ><h2 className="logoh2 text-white m-auto">DRIVINGACC</h2></Navbar.Brand>
                <Navbar.Toggle></Navbar.Toggle>
                <Navbar.Collapse style={{ marginLeft: "5%" }} >
                    <div style={{ minWidth: "50%" }} className="form-group navbar-form-group d-flex align-items-center gap-4 text-white">
                        {/* <label>Nom</label> */}
                        <i className="fa-solid fa-search fa-2xl"></i>
                        {/* <div style={{ minWidth: "25%" }} > */}
                        <Select
                            styles={{ ...themeStyle, option: (provided) => ({ ...provided, color: 'black' }) }}
                            className="w-100"
                            options={studentOptions}
                            onChange={handleSelectStudent}
                            placeholder="Search for a student..."
                        />
                    </div>
                    <button onClick={onLogout} className="btn btn-transparent text-white mx-auto" >
                        Disconnect
                    </button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

const mapStateToProps = (state) => {
    return {
        list: state.student.students,
        loading: state.student.loading,
    };
};

const mapDispatchToProps = {
    listStudent: studentList,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNew)
