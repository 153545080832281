import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { themeStyle } from '../../../../../utils/style';

const StudentSkills = ({ drivingSchoolSkills, studentSkills, isLoading, handleAddStudentSkills, success }) => {
    const [isEdit, setIsEdit] = useState(false);
    const options = [
        { value: 'Abordé', label: 'Abordé' },
        { value: 'Traité', label: 'Traité' },
        { value: 'Assimilé', label: 'Assimilé' },
    ];
    const [sortedSkills, setSortedSkills] = useState([[], [], [], []]);
    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
        const tempSortedSkills = [[], [], [], []];
        drivingSchoolSkills.forEach((skill) => {
            tempSortedSkills[skill.position - 1].push(skill);
        });
        setSortedSkills(tempSortedSkills);
    }, [drivingSchoolSkills]);

    useEffect(() => {
        if (!isEdit) {
            setSelectedValues([]);
        }
    }, [isEdit]);

    const handleSelectChange = (index, selectedOption, skill) => {
        setSelectedValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index] = { skill, value: selectedOption };
            return updatedValues;
        });
    };

    const handleValidSkills = async (e) => {
        e.preventDefault();
        if (isEdit) {
            const changedSkills = selectedValues.filter((item) => item !== undefined);
            await handleAddStudentSkills(changedSkills);
        }
        if(success) {
            setIsEdit(false);
        }
    }

    const renderSortedSkills = () => {
        return sortedSkills.map((positionSkills, index) => (
            <div className='d-flex flex-column mt-5' key={index}>
                {positionSkills.length > 0 && (
                    <h5 className='mb-0'>Compétence {positionSkills[0].position} : {positionSkills[0].name}</h5>
                )}
                {positionSkills.map((skill) => {
                    let defaultValue;
                    studentSkills.forEach((earnedSkill) => {
                        if (earnedSkill.skill_id === skill.id && earnedSkill.skill_level === skill.level) {
                            defaultValue = { value: earnedSkill.status, label: earnedSkill.status };
                        }
                    });

                    const selectedValue = selectedValues[skill.id]?.value || defaultValue || options[0];
                    return (
                        <div className='d-flex align-items-center justify-content-between w-100' style={{ padding: '35px 0', borderBottom: '1px solid #a3a2a2', gap: '20px' }} key={skill.id}>
                            <p style={{ width: '80%', margin: 0 }}>{skill.level}</p>
                            <div style={{ width: '20%' }}>
                                <Select
                                    value={selectedValue}
                                    onChange={(selectedOption) => handleSelectChange(skill.id, selectedOption, skill)}
                                    options={options}
                                    styles={themeStyle}
                                    isDisabled={!isEdit}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        ));
    };
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <h3 style={{ margin: 0 }}>Validation Entrainement</h3>
                <div className="d-flex align-items-center justify-content-end" style={{ gap: '20px' }}>
                    <button
                        className='btn btn-danger'
                        onClick={() => setIsEdit(!isEdit)}
                    > {isEdit ? "annuler la modification" : "ÉDITER"}</button>
                    {isEdit && <button className='btn btn-primary'
                        onClick={(e) => handleValidSkills(e)}
                    >{isLoading ? (
                        <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                        >
                            <span className="visually-hidden">Enregistrement...</span>
                        </div>
                    ) : ("VALIDER L'ENTRAINEMENT")}</button>}
                </div>
            </div>
            {sortedSkills.length > 0 && renderSortedSkills()}
        </div>
    );
};

export default StudentSkills;
