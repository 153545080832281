/**
 * Application configuration
 */
export const calendarConfig = {
    // Start life looking at this date
    date: new Date(2020, 9, 12),

    // CrudManager arranges loading and syncing of data in JSON form from/to a web service
    // crudManager: {
    //     transport: {
    //         load: {
    //             url: 'data/data.json'
    //         }
    //     },
    //     autoLoad: true
    // },

    // Features named by the properties are included.
    // An object is used to configure the feature.
    eventTooltipFeature: {
        // Configuration options are passed on to the tooltip instance
        // We want the tooltip's left edge aligned to the right edge of the event if possible.
        align: 'l-r'
    },

    modes: {
        // Let's not show the default views
        day: null,
        week: null,
        month: null,
        year: null,
        agenda: null,

        // Mode name can be anything if it contains a "type" property.
        dayResources: {
            // Type has the final say over which view type is created
            type: 'resource',
            title: 'Day',
            // Specify how wide each resource panel should be
            resourceWidth: '30em',
            hideNonWorkingDays: true,

            // This is a config object for the subviews
            view: {
                dayStartTime: 8
                // Dock an additional widget at the end of the header
            },

            // Info to display below a resource name
            meta: resource => resource.title
        },
        weekResources: {
            // Type has the final say over which view type is created
            type: 'resource',
            title: 'Week',
            // Specify how wide each resource panel should be
            resourceWidth: '30em',
            hideNonWorkingDays: true,

            // This is a config object for the subviews
            view: {
                dayStartTime: 8
                // Dock an additional widget at the end of the header
            },

            // Info to display below a resource name
            meta: resource => resource.title
        },
        monthResources: {
            type: 'resource',
            title: 'Month',
            resourceWidth: '30em',
            hideNonWorkingDays: true,

            view: {
                type: 'monthview'
            },

            meta: resource => resource.title
        }
    },
};
