import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

import ScrollToTop from "./layouts/ScrollToTop";

import Menu from "./pages/Menu";

import Alerts from "./pages/Alerts";

import Debits from "./pages/Debits";

import Exams from "./pages/Exams";
import ExamWishlist from "./pages/ExamWishlist";

import Planning from "./pages/Planning";
import Receipts from "./pages/Receipts";
import Statistics from "./pages/Statistics";

import Students from "./pages/Students";
import AddEditStudent from "./pages/Students/AddEditStudent";
import DrivingSchools from "./pages/Settings/DrivingSchools/index";
import Monitors from "./pages/Settings/Monitors";
import Offers from "./pages/Settings/Offers";
import Users from "./pages/Settings/Users";
import Vehicles from "./pages/Settings/Vehicles";
import AddEditVehicle from "./pages/Settings/Vehicles/AddEditVehicle";
import ViewVehicle from "./pages/Settings/Vehicles/ViewVehicle";
import ViewStudent from "./pages/Students/ViewStudent";
import Profile from "./pages/Profile";

import Error404 from "./pages/Error404";
import { ThemeContext } from "../context/ThemeContext";
import MenuNew from "./pages/MenuNew";

const Markup = () => {
  const allroutes = [
    // Routes For DrivingSchool
    // { url: "dashboard", component: <Menu /> },
    // { url: "dashboard", component: <MenuNew /> },
    { url: "eleves", component: <Students /> },
    { url: "planning", component: <Planning /> },
    { url: "examens", component: <Exams /> },
    { url: "exam-Wishlist", component: <ExamWishlist /> },
    { url: "alertes", component: <Alerts /> },
    { url: "debits", component: <Debits /> },
    { url: "statistiques", component: <Statistics /> },
    { url: "recettes", component: <Receipts /> },
    { url: "auto-ecoles", component: <DrivingSchools /> },
    { url: "utilisate", component: <Users /> },
    { url: "moniteurs", component: <Monitors /> },
    { url: "offres", component: <Offers /> },
    { url: "vehicules", component: <Vehicles /> },
    { url: "Add-Student", component: <AddEditStudent /> },
    { url: "Edit-Student/:id", component: <AddEditStudent /> },
    { url: "Add-Vehicle", component: <AddEditVehicle /> },
    { url: "Edit-Vehicle/:id", component: <AddEditVehicle /> },
    { url: "View-Vehicle/:id", component: <ViewVehicle /> },
    { url: "View-Student/:id", component: <ViewStudent /> },
    { url: "Profile", component: <Profile /> },
    { url: "*", component: <Error404 /> },
    { url: "Statistiques", component: <Statistics />}
  ];

  return (
    <>
      <Routes>
        <Route path="page-error-404" element={<Error404 />} />
        <Route path="dashboard" element={<MenuNew />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
