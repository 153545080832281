export const USERS= 'USERS';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_FAILED = 'USERS_FAILED';
export const ADDUSER = 'ADDUSER';
export const ADDUSER_SUCCESS = 'ADDUSER_SUCCESS';
export const ADDUSER_FAILED = 'ADDUSER_FAILED';
export const UPDATEUSER = 'UPDATEUSER';
export const UPDATEUSER_SUCCESS = 'UPDATEUSER_SUCCESS';
export const UPDATEUSER_FAILED = 'UPDATEUSER_FAILED'; 
export const DELETEUSER = 'DELETEUSER'
export const DELETEUSER_SUCCESS = "DELETEUSER_SUCCESS";
export const DELETEUSER_FAILED = 'DELETEUSER_FAILED';
export const SINGLEUSER = 'SINGLEUSER';
export const SINGLEUSER_SUCCESS = 'SINGLEUSER_SUCCESS';
export const SINGLEUSER_FAILED = 'SINGLEUSER_FAILED';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL';
export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';