import { PERMISSIONS, PERMISSIONS_FAILED, PERMISSIONS_SUCCESS } from "./actionTypes";


const initialState = {
    permissions: [],
    loading: false,
    success: false,
    error: ''
}

export const permissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PERMISSIONS: return {
            ...state,
            permissions: [],
            loading: true,
            success: false,
            error: ''
        }
        case PERMISSIONS_SUCCESS: return {
            ...state,
            permissions: action.payload,
            loading: false,
            success: true,
            error: ''
        }
        case PERMISSIONS_FAILED: return {
            ...state,
            offers: [],
            success: false,
            error: action.payload,
            loading: false
        }
        default:
            return state
    }
}