import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addVehicle,
  updateVehicle,
  singleVehicle,
  vehicleTypes,
} from "../../../../store/vehicles/action";
import { monitorsList } from "../../../../store/monitors/action";
import { drivingSchoolsList } from "../../../../store/drivingSchools/action";
import { Button, Card, Nav, Tab } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import product1 from "../../../../images/product/1.jpg";
import { themeStyle } from "../../../../utils/style";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
const initVehicle = {
  name: "",
  immatriculation: "",
};
let initVehicleImages = {
  deletedImages: [],
  newAddedImages: []
}
const AddEditVehicle = ({
  oneVehicle,
  loading,
  success,
  addVehicle,
  updateVehicle,
  singleVehicle,
  typesList,
  vehicleTypes,
  monitors,
  monitorsList,
  ListOfDrivingSchool,
  drivingSchoolsList,
}) => {
  const [selectedVehicleDocs, setSelectedVehicleDocs] = useState({});
  const [vehicle, setVehicle] = useState({ ...initVehicle });
  const navigation = useNavigate();
  const { id } = useParams();
  let vehicleId = id;

  const [selectedType, setSelectedType] = useState({});
  const [selectedInstructor, setSelectedInstructor] = useState({});
  const [selectedDrivingSchool, setSelectedDrivingSchool] = useState({});

  const [typesOptions, setTypesOptions] = useState([]);
  const [instuctorOptions, setInstructorOptions] = useState([]);
  const [drivingSchoolOptions, setDrivingSchoolOptions] = useState([]);


  const [selectedDate, setSelectedDate] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [modifiedVehicleDocs, setModifiedVehicleDocs] = useState({ ...initVehicleImages });

  let imageSrcRootPath = process.env.NODE_ENV === 'development' ? 'http://localhost:5001/' : '/api/';


  useEffect(() => {
    if (!typesList.length) {
      vehicleTypes().catch((error) => {
        notifyError(`While laoding Vehicle types List, Error: ${error?.payload || error}`)
      });
    }
    if (!monitors.length) {
      monitorsList().catch((error) => {
        notifyError(`While laoding Instructor List, Error: ${error?.payload || error}`)
      });
    }
    if (!ListOfDrivingSchool.length) {
      drivingSchoolsList().catch((error) => {
        notifyError(`While laoding drivingSchool List, Error: ${error?.payload || error}`)
      });
    }
  }, []);

  useEffect(() => {
    if (monitors.length) {
      const initMonitors =
        monitors?.map((monitor) => ({
          value: { id: monitor?.id, name: monitor?.firstname },
          label: monitor?.firstname + " " + monitor?.lastname,
        })) || [];
      setInstructorOptions(initMonitors);
    }
    if (typesList.length) {
      const initTypes =
        typesList?.map((type) => ({
          value: { id: type.id, type: type.type },
          label: type.type,
        })) || [];
      setTypesOptions(initTypes);
    }
    if (ListOfDrivingSchool.length) {
      const initDrivingSchools = ListOfDrivingSchool?.map((ds) => ({
        value: { id: ds.id, name: ds.name },
        label: ds.name
      }))
      setDrivingSchoolOptions(initDrivingSchools);
    }
  }, [monitors, typesList, ListOfDrivingSchool]);

  useEffect(() => {
    singleVehicle(vehicleId);
  }, [vehicleId]);

  useEffect(() => {
    if (oneVehicle && vehicleId) {
      setIsUpdate(true);
      setVehicle({
        ...initVehicle,
        name: oneVehicle?.name,
        immatriculation: oneVehicle?.immatriculation,
      });
      setSelectedDate(moment(oneVehicle?.date).format("YYYY-MM-DD"));
      setSelectedInstructor({
        value: {
          id: oneVehicle?.instructorVehicles?.id,
          name: oneVehicle?.instructorVehicles?.firstname,
        },
        label:
          oneVehicle?.instructorVehicles?.firstname +
          " " +
          oneVehicle?.instructorVehicles?.lastname,
      });
      setSelectedType({
        value: { id: oneVehicle?.VehicleTypes?.id, type: oneVehicle?.VehicleTypes?.type },
        label: oneVehicle?.VehicleTypes?.type
      });
      setSelectedDrivingSchool({
        value: { id: oneVehicle?.drivingSchoolVehicles?.id, name: oneVehicle?.drivingSchoolVehicles?.name },
        label: oneVehicle?.drivingSchoolVehicles?.name
      })
    }
    let vehicleDocs = {};
    oneVehicle?.vehicleImage?.forEach((image, index) => {
      if (image.path) {
        vehicleDocs[index] = image
      }
    })
    if (Object.keys(vehicleDocs).length > 0) {
      setSelectedVehicleDocs(vehicleDocs);
    }

    return () => {
      if (oneVehicle && !vehicleId) {
        setIsUpdate(false);
        setSelectedVehicleDocs({});
        setVehicle({ ...initVehicle });
        setModifiedVehicleDocs({ ...initVehicleImages });
        setSelectedType({});
        setSelectedInstructor({});
        setSelectedDrivingSchool({});
        setSelectedDate("");
      }
    }
  }, [vehicleId, oneVehicle]);


  const notifyError = (msg) => {
    toast.error(`❌ ${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifySuccess = (msg) => {
    console.log("Notified msg");
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const newFiles = { ...selectedVehicleDocs };

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileCount = Object.keys(newFiles).length;

      if (fileCount >= 4) {
        e.target.value = null;
        notifyError("You can't upload more than 4 images for a vehicle");
        return;
      }

      newFiles[fileCount] = file;
      setModifiedVehicleDocs((prevState) => ({
        ...prevState,
        newAddedImages: [...prevState.newAddedImages, file],
      }))
    }
    setSelectedVehicleDocs(newFiles);
  };

  const handleRemoveImage = (index) => {
    setModifiedVehicleDocs((prevState) => ({
      ...prevState,
      deletedImages: [...prevState.deletedImages, selectedVehicleDocs[index].id],
    }));
    const updatedVehicleDocs = { ...selectedVehicleDocs };
    delete updatedVehicleDocs[index];
    const reindexedDocs = {};
    Object.keys(updatedVehicleDocs).forEach((key, i) => {
      reindexedDocs[i] = updatedVehicleDocs[key];
    });

    setSelectedVehicleDocs(reindexedDocs);
  }

  // Submit Section Starts here *********
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        !vehicle.name ||
        !vehicle.immatriculation ||
        !selectedType ||
        !selectedInstructor ||
        !selectedDate ||
        !selectedDrivingSchool
      ) {
        notifyError("provide valid data");
        return "-";
      }

      let vehiclesData = {
        ...vehicle,
        date: moment(selectedDate).format('YYYY-MM-DD') || '',
        type_id: selectedType?.value?.id,
        instructor_id: selectedInstructor?.value?.id,
        drivingschool_id: selectedDrivingSchool?.value?.id
      };

      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const formData = new FormData();

      if (!isUpdate) {
        for (let image in selectedVehicleDocs) {
          formData.append(image?.name?.toString() || image?.toString(), selectedVehicleDocs[image]);
        }

        formData.append("vehicleData", JSON.stringify(vehiclesData));

        await addVehicle(formData, headers);
        notifySuccess("Vehicles Added SuccessFully");
      } else {
        if (modifiedVehicleDocs.newAddedImages.length) {
          modifiedVehicleDocs.newAddedImages.forEach((image, index) => {
            formData.append(image?.name?.toString() || index?.toString(), image);
          })
        }
        let updatedData = {
          ...vehiclesData,
          id: vehicleId,
          deletedImages: modifiedVehicleDocs?.deletedImages,
          newImages: modifiedVehicleDocs?.newAddedImages,
        }
        formData.append("vehicleData", JSON.stringify(updatedData));
        await updateVehicle(vehicleId, formData, headers);
        notifySuccess("Vehicle Updated SuccessFully");
      }
      if (success && !loading) {
        navigation("/vehicules");
      }
    } catch (error) {
      console.log(error);
      isUpdate ? notifyError(`vehicle Update Failed, Error: ${error?.payload}`) : notifyError(`vehicle add failed, Error: ${error?.payload}`);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Card>
        <Card.Body>
          <div className="row">
            <div className="col-lg-4 col-md-5 col-12">
              <Tab.Container defaultActiveKey="0">
                <Tab.Content>
                  {Array.from({ length: 4 }, (_, index) => (
                    <Tab.Pane eventKey={index} key={index}>
                      {selectedVehicleDocs[index] ? (
                        <img
                          src={(selectedVehicleDocs[index].path && selectedVehicleDocs[index].id) ? `${imageSrcRootPath}${selectedVehicleDocs[index].path}` : URL.createObjectURL(selectedVehicleDocs[index])}
                          alt=""
                          style={{
                            borderRadius: "12px",
                            width: "100%",
                            height: "300px",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: "300px",
                            backgroundColor: "black",
                            borderRadius: "12px",
                            display: "block",
                          }}
                        ></div>
                      )}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
                <div className="tab-slide-content new-arrival-product mb-4 mb-xl-0">
                  {/* Nav tabs */}
                  <Nav
                    as="ul"
                    className="nav slide-item-list mt-3"
                    role="tablist"
                  >
                    {Array.from({ length: 4 }, (_, index) => {
                      const file = selectedVehicleDocs[index];

                      return (
                        <Nav.Item as="li" key={index}>
                          <Nav.Link as="a" to={index} eventKey={index}>
                            {file ? (
                              <div style={{ position: 'relative' }}>
                                <img
                                  src={(file.path && file.id) ? `${imageSrcRootPath}${file.path}` : URL.createObjectURL(file)}
                                  alt=""
                                  style={{
                                    objectFit: "cover",
                                    objectPosition: "center",
                                    width: "70px",
                                    height: "65px",
                                  }}
                                />
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => handleRemoveImage(index)}
                                  style={{
                                    position: 'absolute',
                                    top: '-10px',
                                    right: '-10px',
                                    height: '16px',
                                    width: '16px',
                                    borderRadius: '50%',
                                    fontSize: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: "2px 0 0 0"
                                  }}
                                >X</button>
                              </div>

                            ) : (
                              <div
                                style={{
                                  width: "70px",
                                  height: "65px",
                                  backgroundColor: "black",
                                  borderRadius: "12px",
                                  display: "block",
                                }}
                              ></div>
                            )}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </div>
              </Tab.Container>
            </div>
            <div className="col-lg-8 col-md-7 col-12">
              <div className="detailsPage">
                <div className="mb-3">
                  <label className="col-form-label">Brand</label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={vehicle.name}
                      onChange={(e) => {
                        setVehicle({
                          ...vehicle,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="col-form-label">Registration</label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      name="immatriculation"
                      value={vehicle.immatriculation}
                      onChange={(e) => {
                        setVehicle({
                          ...vehicle,
                          immatriculation: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="col-form-label">Monitor</label>
                  <div className="">
                    <Select
                      value={selectedInstructor}
                      options={instuctorOptions}
                      onChange={setSelectedInstructor}
                      styles={themeStyle}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="col-form-label">Driving School</label>
                  <div className="">
                    <Select
                      value={selectedDrivingSchool}
                      options={drivingSchoolOptions}
                      onChange={setSelectedDrivingSchool}
                      styles={themeStyle}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="col-form-label">Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(moment(e.target.value).format('YYYY-MM-DD'))}
                  />
                </div>
                <div className="mb-3">
                  <label className="col-form-label">Type</label>
                  <div className="">
                    <Select
                      value={selectedType}
                      options={typesOptions}
                      onChange={setSelectedType}
                      styles={themeStyle}
                    />
                  </div>
                </div>
              </div>
              <div
                className="imageUploadPage"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label
                  htmlFor="vehicleDocs"
                  style={{ width: "70%", margin: "0 auto" }}
                >
                  <div
                    style={{
                      minHeight: 200,
                      maxHeight: 250,
                      width: "100%",
                      border: "3px solid #DDDFE155",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "20px",
                      margin: "15px auto",
                      borderRadius: "15px",
                      backgroundColor: "#2a2a2a66",
                    }}
                  >
                    <span className="btn btn-primary">
                      Télécharger une image
                    </span>
                    <p
                      style={{
                        fontSize: "16px",
                        color: "#fff",
                        marginBottom: "0",
                      }}
                    >
                      ou déposer un fichier
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  name="vehicleDocs"
                  id="vehicleDocs"
                  max="4"
                  onChange={(e) => handleFileChange(e)}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <Button
              className="mb-2"
              variant="info"
              width={30}
              onClick={handleSubmit}
            >
              Éditer un vehicule
            </Button>
          </div>
        </Card.Body>
      </Card>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    oneVehicle: state.vehicle.singlevehicle,
    loading: state.vehicle.loading,
    success: state.vehicle.success,
    typesList: state.vehicle.types,
    monitors: state.monitors.monitors,
    ListOfDrivingSchool: state.drivingSchool.drivingschools,
  };
};

const mapDispatchToProps = {
  addVehicle,
  updateVehicle,
  singleVehicle,
  vehicleTypes,
  monitorsList,
  drivingSchoolsList,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddEditVehicle);
