import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import {
  singleStudent,
  addStudentComment,
  addStudentFormula,
  addStudentSkills,
  addStudentPayment,
  deleteStudentFormula,
  studentList,
  updateStudentLicences,
  deleteStudentComment,
  updateStudent,
} from "../../../store/students/action";
import { offersList } from "../../../store/offers/action";
import { licenecesList } from "../../../store/licences/action";
import { Tab, Nav, Card, Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExpandebleTable from "./components/view-student/ExpandebleTable";
import { useNavigate, useParams } from "react-router-dom";
import CalendarComponent from "./components/view-student/Calender";
import PlanningCalendarComponent from "./components/Planning/index";
import StudentInfo from "./components/view-student/StudentInfo";
import StudentSkills from "./components/view-student/StudentSkills";
import Select from "react-select";
import { themeStyle } from "../../../utils/style";
import moment from "moment";
import jsPDF from "jspdf";
import ReportTemplate from "./components/view-student/ReportTemplate";
import HoursWorkedTable from "./components/view-student/HoursWorkedTable";

const initType = [
  { value: 1, label: "1er versement" },
  { value: 2, label: "2eme versement" },
  { value: 3, label: "3eme versement" },
  // { value: "versement", label: "versement" }
];
const initMode = [
  { value: "Virement", label: "Virement" },
  { value: "Chèque", label: "Chèque" },
  { value: "Espéces", label: "Espéces" },
  {
    value: "Chéque à encaissement programmé",
    label: "Chéque à encaissement programmé",
  },
  { value: "Chéque de caution", label: "Chéque de caution" },
];
const initPaymentData = {
  student_formula_id: 0,
  type: "",
  mode: "",
  amount: 0,
  numberbankcheck: "",
  secretary_id: 0,
};
const initFormulaData = {
  student_id: 0,
  formula_id: 0,
  quantity: 1,
  secretary_id: 0,
  date: "",
};
const initCommentData = {
  student_id: 0,
  comment: "",
};
const ViewStudent = ({
  studentData,
  loading,
  success,
  formulaList,
  secretory,
  singleStudent,
  addStudentComment,
  addStudentFormula,
  addStudentSkills,
  addStudentPayment,
  deleteStudentFormula,
  offersList,
  studentList,
  licencesData,
  licenecesList,
  updateStudentLicences,
  deleteStudentComment,
  updateStudent,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const studentId = id;
  const reportTemplateRef = useRef(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [studentInfo, setStudentInfo] = useState({});
  const [studentFormula, setStudentFormula] = useState([]);
  const [studentFormulaTotalHours, setStudentFormulaTotalHours] = useState(0);
  const [studentFormulaTotalAmount, setStudentFormulaTotalAmount] = useState(0);
  const [drivingSchoolSkills, setDrivingSchoolSkills] = useState([]);
  const [studentSkills, setStudentSkills] = useState([]);
  const [formulaModal, setFormulaModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [permitModal, setPermitModal] = useState(false);
  const [formulaOptions, setFormulaOptions] = useState([]);
  const [typeOptions, setTypesOptions] = useState([...initType]);
  const [modeOptions, setModeOptions] = useState([...initMode]);
  const [secretaryId, setSecretaryId] = useState(null);
  const [studentNewAddFormula, setStudentNewAddFormula] = useState({});
  const [studentPayment, setStudentPayment] = useState({ ...initPaymentData });
  const [newPlan, setNewPlan] = useState({ ...initFormulaData });
  const [newComment, setNewComment] = useState({ ...initCommentData });
  const [paymentMode, setPaymentMode] = useState({});
  const [paymentType, setPaymentType] = useState({});
  const [deleteFormulaId, setDeleteFormulaId] = useState(null);
  const [debitAmount, setDebitAmount] = useState(0);
  const [selectedPlanData, setSelectedPlanData] = useState({});
  const [hoursWorkedData, setHoursWorkedData] = useState([])
  const [studentFormulaTotalUsedHours, setStudentFormulaTotalUsedHours] = useState(0)

  const [licenceOptions, setLicenceOptions] = useState([]);
  const [selectedLicence, setSelectedLicence] = useState({});
  const [activeTab, setActiveTab] = useState("Informations")

  // data loading logic
  useEffect(() => {
    if (studentId) {
      singleStudent(studentId).catch((error) => {
        notifyError(`Student data Loading Failed,Error: ${error?.payload}`);
        setTimeout(function () {
          navigate("/eleves");
        }, 7000);
      });
    }
    if (!formulaList.length) {
      offersList().catch((error) => {
        notifyError(`formula not loading, Error: ${error?.payload}`);
      });
    }
    if (!licencesData.length) {
      licenecesList().catch((error) => {
        notifyError(`Licences not loading , Error : ${error?.payload}`);
      });
    }
  }, []);

  //data formating and setting into local state logic
  useEffect(() => {
    if (studentId === studentData?.id?.toString()) {
      let document =
        studentData?.studentDocument?.map((doc) => ({
          id: doc?.id,
          student_id: doc?.student_id,
          name: doc?.type,
          file: doc?.documentStudent?.path,
        })) || [];
      let comments =
        studentData?.studentComments?.map((comment) => ({
          id: comment?.id,
          student_id: comment?.student_id,
          comment: comment?.comment,
          date: moment(comment?.created_at).format("YYYY/MM/DD"),
        })) || [];
      setStudentInfo({
        id: studentData?.id,
        gender: studentData?.gender,
        email: studentData?.email,
        firstname: studentData?.firstname,
        lastname: studentData?.lastname,
        mobile: studentData?.mobile,
        address: studentData?.address,
        birthday: moment(studentData?.birthday).format("YYYY/MM/DD"),
        department: studentData?.department,
        place_meet: studentData?.place_meet,
        place_birthday: studentData?.place_birthday,
        drivingSchool: studentData?.drivingSchoolStudents?.name,
        date_code: studentData?.date_code
          ? moment(studentData?.date_code).format("YYYY/MM/DD")
          : "",
        neph: studentData?.neph,
        status: studentData?.status,
        documents: document,
        comments: comments,
        licences: studentData?.licenceStudents,
      });
      let drivingSkill =
        studentData?.drivingSchoolStudents?.drivingSchoolSkills.map(
          (skill) => ({ ...skill })
        ) || [];
      setDrivingSchoolSkills(drivingSkill);
      let studentDrivingSkills =
        studentData?.studentSkills?.map((skill) => ({
          id: skill.id,
          status: skill.status,
          skill_id: skill.skill_id,
          skill_name: skill.skillId.name,
          skill_level: skill.skillId.level,
          skill_position: skill.skillId.position,
        })) || [];
      setStudentSkills(studentDrivingSkills);
      let totalHours = 0;
      let totalAmount = 0;
      let totalPaidAmount = 0;
      let stuFormula =
        studentData?.studentFormula?.map((plan) => {
          let paymentTotal = 0;
          let formulaPayment = plan?.studentFormulaPayment?.map((payment) => {
            const formatedPayment = {
              name: plan?.formulaId?.name,
              date: moment(payment?.created_at).format("YYYY/MM/DD"),
              mode: payment?.mode,
              tarif: payment?.amount,
              bankCheck: payment?.numberbankcheck,
            };
            paymentTotal += payment?.amount;
            totalPaidAmount += payment?.amount;
            return formatedPayment;
          });

          let newFormulaPaymentObj = {
            data: formulaPayment,
            total: paymentTotal,
          };
          const hours = plan?.formulaId?.hour * plan?.quantity;
          const amount = plan?.formulaId?.price * plan?.quantity;
          let disable = false;
          if (amount === paymentTotal) {
            disable = true;
          }
          totalHours += hours;
          totalAmount += amount;

          return {
            id: plan?.id,
            student_id: plan?.student_id,
            formula_id: plan?.formula_id,
            quantity: plan?.quantity,
            date: moment(plan?.created_at).format("YYYY/MM/DD"),
            price: plan?.formulaId?.price,
            name: plan?.formulaId?.name,
            hour: hours,
            amount: amount,
            type: plan?.formulaId?.type,
            fees: plan?.formulaId?.fees,
            formulaPayment: newFormulaPaymentObj,
            isDisabled: disable,
          };
        }) || [];
      setStudentFormulaTotalAmount(totalAmount);
      setStudentFormulaTotalHours(totalHours);
      setStudentFormula(stuFormula);
      let totalUsedHours = 0
      setHoursWorkedData(studentData?.studentGenerals?.map((item) => {

        let startDate = moment(item?.start_horary)
        let endDate = moment(item?.end_horary)

        totalUsedHours += endDate.diff(startDate, 'hours')

        return {
          startDate: startDate.format('DD/MM/YYYY HH:mm'),
          endDate: endDate.format('DD/MM/YYYY HH:mm'),
          monitor_name: (item?.instructorGenerals?.firstname || '') + " " + (item?.instructorGenerals?.lastname || ''),
          hours: endDate.diff(startDate, 'hours')
        }
      }))
      setStudentFormulaTotalUsedHours(totalUsedHours)
      setDebitAmount(totalAmount - totalPaidAmount);
      setSelectedLicence(
        licenceOptions?.find(
          (val) => val.value == studentData?.licenceStudents?.id
        )
      );
    }
  }, [studentData, studentId]);

  useEffect(() => {
    let formulas =
      formulaList
        ?.filter((formula) => {
          return formula.status === 1;
        })
        .map((formula) => ({
          value: { ...formula },
          label: formula?.name,
        })) || [];
    setFormulaOptions(formulas);
    if (secretory) {
      setSecretaryId(secretory?.id || null);
    }
  }, [formulaList, secretory]);

  useEffect(() => {
    setLicenceOptions(
      licencesData?.map((val) => ({ value: val.id, label: val.name }))
    );
  }, [licencesData]);

  // notification block
  const notifyError = (msg) => {
    toast.error(`❌ ${msg} `, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifySuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // student modification logic
  const openDeleteModal = (id) => {
    if (id) {
      setDeleteModal(true);
      setDeleteFormulaId(id);
    }
  };
  const handleFormulaModalCLick = () => {
    setFormulaModal(true);
  };
  const handleAddComment = () => {
    setCommentModal(true);
  };
  const handlePermit = () => {
    setPermitModal(true);
    setSelectedLicence(
      licenceOptions?.find(
        (val) => val.value == studentData?.licenceStudents?.id
      )
    );
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
    setDeleteFormulaId(null);
  };
  const handleStudentPlanEdit = (row) => {
    if (row?.id) {
      setStudentPayment({ ...studentPayment, student_formula_id: row?.id });
      setSelectedPlanData(row);
      setPaymentModal(true);
    }
  };
  const confirmDelete = (e) => {
    if (deleteFormulaId) {
      handleDeleteFormula(e);
    }
  };
  const createComment = async (e) => {
    e.preventDefault();
    if (!newComment.comment) {
      notifyError(
        `Oops: you can't able to create student formula, please provide valid data`
      );
      return 0;
    }
    let data = {
      ...newComment,
      student_id: studentId,
    };

    try {
      await addStudentComment(data);
      if (!loading && success) {
        setCommentModal(false);
        await singleStudent(studentId);
        setNewComment({ ...initCommentData });
        notifySuccess(`Success: student new comment added...`);
      }
    } catch (error) {
      notifyError(`Oops, Error: ${error?.payload || error}`);
    }
  };

  const handleCommentDelete = async (item) => {
    if (item.id) {
      try {
        await deleteStudentComment(item?.id);
        await singleStudent(studentId);
        notifySuccess(`Success: comment deleted successfully`);
        return Promise.resolve();
      } catch (error) {
        notifyError(`Error: error while deleting comment`);
        return Promise.reject();
      }
    }
  };
  const createPermit = async (e) => {
    e.preventDefault();
    let id = studentId;
    let data = [
      {
        licence_id: selectedLicence.value,
      },
    ];
    try {
      await updateStudentLicences(id, data);
      if (!loading && success) {
        setPermitModal(false);
        await singleStudent(id);
        await studentList();
        notifySuccess("Licence Permit Applied");
      }
    } catch (error) {
      notifyError(`Oops, Error: ${error?.payload || error}`);
    }
  };

  const handleAddFormula = async (e) => {
    e.preventDefault();
    if (!newPlan.date || !newPlan.quantity || !studentNewAddFormula) {
      notifyError(
        `Oops: you can't able to create student formula, please provide valid data`
      );
      return 0;
    }

    let data = {
      ...newPlan,
      student_id: studentId,
      formula_id: studentNewAddFormula?.value?.id,
      secretary_id: secretaryId,
    };

    try {
      await addStudentFormula(data);
      if (!loading && success) {
        setFormulaModal(false);
        await singleStudent(studentId);
        setNewPlan({ ...initFormulaData });
        setStudentNewAddFormula({});
        notifySuccess(`Success: student new formula added...`);
      }
    } catch (error) {
      notifyError(`Oops, Error: ${error?.payload || error}`);
    }
  };
  const handleDeleteFormula = async (e) => {
    e.preventDefault();
    try {
      await deleteStudentFormula(deleteFormulaId);
      if (!loading && success) {
        setDeleteModal(false);
        notifySuccess(`Success: student formula deleted...`);
        setDeleteFormulaId(null);
        await singleStudent(studentId);
        await studentList();
      }
    } catch (error) {
      notifyError(
        `Oops: somethings want wrong, error: ${error?.payload || error}`
      );
    }
  };
  const handleAddPayment = async (e) => {
    e.preventDefault();
    if (!studentPayment.amount || !paymentMode || !paymentType) {
      notifyError(
        `Oops: you can't able to add new Payment, please provide valid data`
      );
      return 0;
    }
    let newPay = {
      amount: studentPayment?.amount,
      student_formula_id: parseInt(studentPayment?.student_formula_id),
      type: paymentType?.value,
      mode: paymentMode?.value,
      secretary_id: secretaryId,
      numberbankcheck: studentPayment?.numberbankcheck || null,
    };

    try {
      await addStudentPayment(newPay);
      if (!studentData?.date_code) {
        await updateStudent(studentId, {
          studentData: JSON.stringify({
            date_code:
              studentData?.studentFormula?.[0]?.studentFormulaPayment?.[0]
                ?.created_at || moment().format("YYYY-MM-DD HH:mm:ss"),
          }),
        });
      }
      if (!loading && success) {
        setPaymentModal(false);
        await singleStudent(studentId);
        await studentList();
        setStudentPayment({ ...initPaymentData });
        setPaymentMode({});
        setPaymentType({});
        notifySuccess(`Success: student new payment added...`);
      }
    } catch (error) {
      notifyError(`Oops, Error: ${error?.payload || error}`);
    }
  };

  const handleAddStudentSkills = async (data) => {
    if (data.length) {
      let newSkills = data?.map((skill) => ({
        status: skill?.value?.value,
        student_id: studentId,
        skill_id: skill?.skill?.id,
      }));
      let oldSkills = studentData?.studentSkills || [];
      let updateOldSkill = newSkills.map((skill) => {
        const exist = oldSkills.find(
          (oldSk) =>
            oldSk.student_id == skill.student_id &&
            oldSk.skill_id == skill.skill_id
        );
        if (exist) {
          skill.id = exist.id;
        }
        return skill;
      });

      try {
        await addStudentSkills(updateOldSkill);
        if (!loading && success) {
          await singleStudent(studentId);
          notifySuccess(`Success: student new skills added...`);
        }
      } catch (error) {
        notifyError(
          `Oops: something want wrong, student skills not updated, ${error?.payload || error
          }`
        );
      }
    }
  };


  useEffect(() => {
    if (selectedPlanData?.formulaPayment?.total > 0) {
      setStudentPayment({
        ...studentPayment,
        amount:
          selectedPlanData?.amount - selectedPlanData?.formulaPayment?.total,
      });
    } else {
      setStudentPayment({
        ...studentPayment,
        amount: selectedPlanData?.amount,
      });
    }
  }, [selectedPlanData]);

  // for pdf save and print logic
  const handleTranscationPrint = (data) => {
    const doc = new jsPDF();

    let formatedData = {
      ...studentData,
      reciptData: data,
    };
    const htmlContent = <ReportTemplate downloadData={formatedData} />;
    const pdfContent = ReactDOMServer.renderToString(htmlContent);
    doc.html(pdfContent, {
      callback: (doc) => {
        doc.save("receipt.pdf");
      },
    });
  };

  // for table logic
  const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;

    return (
      <div>
        <input
          className="form-control input-search"
          value={filterValue || ""}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
    );
  };

  const handleRowClick = (row) => {
    const rowId = row.id;
    setExpandedRows((prevExpandedRows) => {
      const isRowExpanded = prevExpandedRows.includes(rowId);
      return isRowExpanded
        ? prevExpandedRows.filter((id) => id !== rowId)
        : [...prevExpandedRows, rowId];
    });
  };

  const HOURS_WORKED_TABLE_COLUMNS = [
    {
      Header: "Date début",
      Footer: "Date début",
      accessor: "startDate",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <button
            className="d-flex align-items-center"
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleRowClick(row)}
          >
            {row.original.startDate}
          </button>
        );
      },
    },
    {
      Header: "Date fin",
      Footer: "Date fin",
      accessor: "endDate",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <button
            className="d-flex align-items-center"
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleRowClick(row)}
          >
            {row.original.endDate}
          </button>
        );
      },
    },
    {
      Header: "Moniteur",
      Footer: "Moniteur",
      accessor: "monitor_name",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <button
            className="d-flex align-items-center"
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleRowClick(row)}
          >
            {row.original.monitor_name}
          </button>
        );
      },
    },
    {
      Header: "Heures effectués",
      Footer: "Heures effectués",
      accessor: "hours",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <button
            className="d-flex align-items-center"
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleRowClick(row)}
          >
            {row.original.hours}
          </button>
        );
      },
    },
  ];

  const COLUMNS = [
    {
      Header: "",
      Footer: " ",
      accessor: "warning",
      Filter: ColumnFilter,
      disableFilters: true,
      Cell: ({ row }) => {
        return null;
      },
    },
    {
      Header: "Date",
      Footer: "Date",
      accessor: "date",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <button
            className="d-flex align-items-center"
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleRowClick(row)}
          >
            {row.original.date}
          </button>
        );
      },
    },
    {
      Header: "Quantité",
      Footer: "Quantité",
      accessor: "quantity",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <button
            className="d-flex align-items-center"
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleRowClick(row)}
          >
            {row.original.quantity}
          </button>
        );
      },
    },
    {
      Header: "Nom prestation",
      Footer: "Nom prestation",
      accessor: "name",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <button
            className="d-flex align-items-center"
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleRowClick(row)}
          >
            {row.original.name}
          </button>
        );
      },
    },
    {
      Header: "Tarif",
      Footer: "Tarif",
      accessor: "price",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <button
            className="d-flex align-items-center"
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleRowClick(row)}
          >
            {row.original.price}
          </button>
        );
      },
    },
    {
      Header: "Montant",
      Footer: "Montant",
      accessor: "amount",
      Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <button
            className="d-flex align-items-center"
            style={{ all: "unset", cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleRowClick(row)}
          >
            {row.original.amount}
          </button>
        );
      },
    },
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }) => {
        return (
          <div className="d-flex">
            <button
              className="btn btn-primary btn-xs sharp me-1"
              onClick={() => handleStudentPlanEdit({ ...row.original })}
              style={{ color: "white" }}
              disabled={row.original.isDisabled}
            >
              <i className="fas fa-pencil-alt"></i>
            </button>
            <button
              className="btn btn-danger btn-xs sharp"
              onClick={() => {
                openDeleteModal(row.original.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        );
      },
    },
  ];

  const EXPANDEDCOLUMNS = [
    {
      Header: "Nom",
      Footer: "Nom",
      accessor: "name",
    },
    {
      Header: "Date",
      Footer: "Date",
      accessor: "date",
    },
    {
      Header: "Type",
      Footer: "Type",
      accessor: "type",
    },
    {
      Header: "Mode",
      Footer: "Mode",
      accessor: "mode",
    },
    {
      Header: "Tarif",
      Footer: "Tarif",
      accessor: "tarif",
    },
  ];

  const handleTabSelect = (value) => {
    console.log(value)
    setActiveTab(value)
  }

  return (
    <>
      <ToastContainer />
      <div>
        <Tab.Container defaultActiveKey={"Informations"} onSelect={handleTabSelect} >
          <div className="d-flex align-items-center justify-content-between">
            <Card style={{ width: "50%" }} className="mb-0">
              <Card.Body style={{ width: "100%" }}>
                <Nav
                  as="ul"
                  className="nav-pills light w-100 justify-content-between"
                >
                  <Nav.Item as="li">
                    <Nav.Link eventKey="Informations">Informations</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="Comptabilité">Comptabilité</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="Suivi pédagogique">
                      Suivi pédagogique
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="Planning">Planning</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item as="li">
                    <Nav.Link eventKey="Disponibilités">
                      Disponibilités
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </Card.Body>
            </Card>
            <Button
              variant="outline-primary"
              onClick={() => handleFormulaModalCLick()}
            >
              Ajouter une prestation
            </Button>
          </div>
          <Tab.Content className="">
            <Tab.Pane eventKey="Informations">
              <Card className="mt-4">
                <Card.Body>
                  <StudentInfo
                    studentInfo={studentInfo}
                    handleAddComment={handleAddComment}
                    handlePermit={handlePermit}
                    handleCommentDelete={handleCommentDelete}
                    isLoading={loading}
                  />
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="Planning">

            </Tab.Pane>
            <Tab.Pane eventKey="Comptabilité">
              <Card className="mt-4" style={{ background: "#ff5c23" }}>
                <Card.Body style={{ padding: "1rem 1.875rem" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      textAlign: "left",
                      paddingLeft: "12px",
                      margin: 0,
                      color: "white",
                    }}
                  >
                    L'éléve doit encore {debitAmount}
                  </p>
                </Card.Body>
              </Card>
              <Card className="mt-4">
                <Card.Body>
                  <ExpandebleTable
                    datas={studentFormula}
                    headerColumn={COLUMNS}
                    isLoading={loading}
                    expandedRows={expandedRows}
                    innerHeaderColumn={EXPANDEDCOLUMNS}
                    studentFormulaTotalAmount={studentFormulaTotalAmount}
                    studentFormulaTotalHours={studentFormulaTotalHours}
                    handleTranscationPrint={handleTranscationPrint}
                    />
                  <HoursWorkedTable
                    studentFormulaTotalHours={studentFormulaTotalHours}
                    datas={hoursWorkedData}
                    headerColumn={HOURS_WORKED_TABLE_COLUMNS}
                    studentFormulaTotalUsedHours={studentFormulaTotalUsedHours}
                  />
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="Suivi pédagogique">
              <Card className="mt-4">
                <Card.Body>
                  <StudentSkills
                    isLoading={loading}
                    drivingSchoolSkills={drivingSchoolSkills}
                    studentSkills={studentSkills}
                    handleAddStudentSkills={handleAddStudentSkills}
                    success={success}
                  />
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="Disponibilités">
              <Card className="mt-4">
                <Card.Body>
                  <CalendarComponent isLoading={loading} />
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        {
          activeTab == "Planning" &&
        <Card className="mt-4">
          <Card.Body>
            <PlanningCalendarComponent studentId={studentId} studentInfo={studentInfo} />
          </Card.Body>
        </Card>
        }
      </div>
      {/* add student formula model code */}
      <Modal
        className="fade"
        show={formulaModal}
        onHide={() => {
          setFormulaModal(false);
          setStudentNewAddFormula({});
          setNewPlan({ ...initFormulaData });
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="basic-form">
            <form onSubmit={(e) => handleAddFormula(e)}>
              <div className="row">
                <div className="form-group mb-3 col-md-12">
                  <label htmlFor="name">Tarif</label>
                  <Select
                    styles={themeStyle}
                    options={formulaOptions}
                    value={studentNewAddFormula}
                    onChange={setStudentNewAddFormula}
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={newPlan.date}
                    onChange={(e) =>
                      setNewPlan({
                        ...newPlan,
                        date: moment(e.target.value).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Quantité</label>
                  <input
                    type="number"
                    className="form-control"
                    value={newPlan.quantity}
                    onChange={(e) =>
                      setNewPlan({
                        ...newPlan,
                        quantity: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => handleAddFormula(e)}
                  >
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">
                          Enregistrement...
                        </span>
                      </div>
                    ) : (
                      "Créer une prestation"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/* add student payment model code */}
      <Modal
        className="fade"
        show={paymentModal}
        onHide={() => {
          setPaymentModal(false);
          setPaymentMode({});
          setPaymentType({});
          setStudentPayment({ ...initPaymentData });
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="basic-form">
            <form onSubmit={(e) => handleAddPayment(e)}>
              <div className="row">
                <div className="form-group mb-3 col-md-12">
                  <label>Mode</label>
                  <Select
                    styles={themeStyle}
                    options={modeOptions}
                    value={paymentMode}
                    onChange={setPaymentMode}
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Montant</label>
                  <input
                    type="number"
                    className="form-control"
                    value={studentPayment.amount}
                    min={1}
                    max={parseInt(selectedPlanData?.amount - selectedPlanData?.formulaPayment?.total)}
                    onChange={(e) => {
                      const newAmount = parseInt(e.target.value);
                      const duePayment = parseInt(selectedPlanData?.amount - selectedPlanData?.formulaPayment?.total)
                      if (newAmount <= duePayment) {
                        setStudentPayment({
                          ...studentPayment,
                          amount: newAmount,
                        });
                      }
                    }}
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Numéro de chèque</label>
                  <input
                    type="number"
                    className="form-control"
                    value={studentPayment.numberbankcheck}
                    onChange={(e) =>
                      setStudentPayment({
                        ...studentPayment,
                        numberbankcheck: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-primary" type="submit">
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">
                          Enregistrement...
                        </span>
                      </div>
                    ) : (
                      "Créer un encaissement"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/* formula delete confirmation model code */}
      <Modal
        show={deleteModal}
        onHide={closeDeleteModal}
        className="fade"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Confirmation de la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Voulez-vous vraiment supprimer un avantage élevé pour éleve ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closeDeleteModal}>
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
      {/* comment model code */}
      <Modal
        className="fade"
        show={commentModal}
        onHide={() => {
          setCommentModal(false);
          setNewComment({ ...initCommentData });
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="basic-form">
            <form onSubmit={(e) => createComment(e)}>
              <div className="row">
                <div className="form-group mb-3 col-md-12">
                  <label>Commentaire</label>
                  <input
                    type="text"
                    className="form-control"
                    value={newComment.comment}
                    onChange={(e) =>
                      setNewComment({ ...newComment, comment: e.target.value })
                    }
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-primary" type="submit">
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">
                          Enregistrement...
                        </span>
                      </div>
                    ) : (
                      "CRÉER UN COMMENTAIRE"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/* Permit model code */}
      <Modal
        className="fade"
        show={permitModal}
        onHide={() => {
          setPermitModal(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="basic-form">
            <form onSubmit={(e) => createPermit(e)}>
              <div className="row">
                <label>Type de licence :</label>
                <div className="form-group mb-3 col-md-12">
                  <Select
                    defaultValue={licenceOptions[0]}
                    value={selectedLicence}
                    onChange={setSelectedLicence}
                    options={licenceOptions}
                    styles={themeStyle}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-primary" type="submit">
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">
                          Enregistrement...
                        </span>
                      </div>
                    ) : (
                      "CRÉER UN PERMIS"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    studentData: state.student.student,
    loading: state.student.loading,
    success: state.student.success,
    formulaList: state.offers.offers,
    secretory: state.auth.auth,
    licencesData: state.licences.licences,
  };
};
const mapDispatchToProps = {
  singleStudent,
  studentList,
  addStudentComment,
  addStudentFormula,
  addStudentSkills,
  addStudentPayment,
  deleteStudentFormula,
  offersList,
  licenecesList,
  updateStudentLicences,
  deleteStudentComment,
  updateStudent,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewStudent);
