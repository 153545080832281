import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import moment from 'moment';
import { api } from '../utils/constants';
import * as urls from '../utils/urls';
export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password
    };
    return api(urls.login(), 'post', postData);
}

export function formatError(errorResponse) {
    if (errorResponse?.data?.data?.errors) {
        swal("Oops", `${errorResponse?.data?.data?.errors?.[0]?.message}`);
    } else if (errorResponse?.data?.message) {
        swal("Oops", `${errorResponse?.data?.message}`)
    } else {
        swal("Oops", `${errorResponse?.statusText}`)
    }
    return ''
}

export function saveTokenInLocalStorage(user) {
    localStorage.setItem('userDetails', JSON.stringify(user));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        dispatch(Logout(navigate));
        console.log("auto logout running");
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString || tokenDetailsString === 'undefined') {
        dispatch(Logout(navigate));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireMoment = moment.unix(tokenDetails.exp);
    let nowMoment = moment(new Date())
    const result = nowMoment.isBefore(expireMoment);

    if (!result) {
        dispatch(Logout(navigate));
        return;
    }

    dispatch(loginConfirmedAction(tokenDetails));
    const duration = moment.duration(expireMoment.diff(nowMoment));
    const timer = duration.asMilliseconds();
    runLogoutTimer(dispatch, timer, navigate);
}
